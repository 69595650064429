import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  getCollections,
  iCollection,
  loadCollectionProps,
} from "../../../services/collection";
import { GROUP_PERMISSIONS, iGroup } from "../../../services/groups";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { PAGE_DIM, scrollToTopList } from "../../../utils/generic";
import { openCreateCollection } from "../../../components/createcollection/CreateCollection";
import CollectionCard from "../../../components/collectioncard/CollectionCard";
import Loader from "../../../components/dom/Loader";
import Pagination from "../../../components/dom/Pagination";
import Button from "../../../components/dom/Button";

export default function GroupCollections() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);
  const group = useSelector(selectGroup);

  const [collections, setCollections] = useState<Array<iCollection>>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);
  const [isGold] = useState<boolean | undefined>(undefined);

  const elementRef = useRef<HTMLDivElement>(null);

  const loadCollections = async ({
    groupId,
    page,
    gold,
    size,
  }: loadCollectionProps) => {
    setIsLoading(true);
    try {
      const data = await getCollections({
        page,
        groupId,
        gold,
        size,
      });

      setTotal(data.total || 0);

      setCollections(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  useEffect(() => {
    loadCollections({ groupId: group._id, page, gold: isGold, size });
    // eslint-disable-next-line
  }, [page, size, group, isGold]);

  const canCreate = (group: iGroup): boolean => {
    if (user.isAdmin) return true;

    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (
      myPerms.includes(GROUP_PERMISSIONS.admin) ||
      myPerms.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    return false;
  };

  return (
    <section className="group-sub-page">
      <div className="group-collection-header">
        {/* <span className="title">{t("group.collection")}</span> */}
        <span></span>
        {canCreate(group) ? (
          <Button
            onClick={() => openCreateCollection()}
            text={String(t("group.create_collection"))}
          />
        ) : null}
      </div>
      {/* <div className="buttons d-flex gap-2 mt-2">
        <Button
          small
          text={String(t("group.collection_all"))}
          onClick={() => setIsGold(undefined)}
          disabled={typeof isGold === "undefined"}
          light={typeof isGold === "undefined"}
        />
        <Button
          small
          text={String(t("group.collection_classic"))}
          onClick={() => setIsGold(false)}
          disabled={typeof isGold === "boolean" && !isGold}
          light={typeof isGold === "boolean" && !isGold}
        />
        <Button
          small
          text={String(t("group.collection_gold"))}
          onClick={() => setIsGold(true)}
          disabled={typeof isGold === "boolean" && isGold}
          light={typeof isGold === "boolean" && isGold}
        />
      </div> */}
      {isLoading ? (
        <div className="mt-5">
          <Loader />
        </div>
      ) : (
        <div>
          {collections.length === 0 ? (
            <span className="mt-5">{t("group.no_collections")}</span>
          ) : (
            <div ref={elementRef} className="collection-list">
              {collections.map((collection, key) => {
                return (
                  <CollectionCard
                    key={key}
                    collection={collection}
                    onUpdate={loadCollections}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}

      <Pagination
        page={page}
        size={size}
        total={total}
        onSizeChange={onSizeChange}
        onPageChange={onPageChange}
      />
    </section>
  );
}
