import { useTranslation } from "react-i18next";
import {
  LANG,
  getLocaleLanguage,
  setLocaleLanguage,
} from "../../services/i18next";
import Select from "../dom/Select";

export default function Footer() {
  const languageOptions = Object.keys(LANG);
  const lang = getLocaleLanguage();
  const { t } = useTranslation();

  const changeLocaleLanguageSelect = (e: any) => {
    const newLang = e.target.value;
    setLocaleLanguage(newLang);
  };

  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-content">
          <div className="lang-selection">
            <span>{t("settings.choose_language")}</span>
            <Select
              onChange={changeLocaleLanguageSelect}
              defaultValue={lang}
              className="text-uppercase"
              small
              options={languageOptions.map((l) => {
                return {
                  value: l,
                  text: l,
                };
              })}
            />
          </div>
          <div className="info-section">
            <span>{t("info.made_by_takyon")}</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
