import { FormEvent, useEffect, useState } from "react";
import Button from "../../components/dom/Button";
import { datetimeToString, formToObject } from "../../utils/generic";
import {
  createPromoCode,
  deletePromoCode,
  getCodesPaginated,
  iPromoCode,
} from "../../services/auth";
import CustomDatePicker from "../../components/dom/CustomDatePicker";
import { closeModal, openModal } from "../../components/modal/Modal";
import Loader from "../../components/dom/Loader";

interface DeletePromoCodeProps {
  onSuccess: Function;
  id: string;
}

function DeletePromoCode({ id, onSuccess }: DeletePromoCodeProps) {
  const [isLoading, setIsLoading] = useState(false);

  const deleteClick = async () => {
    setIsLoading(true);
    try {
      await deletePromoCode(id);

      onSuccess();
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <p className="text-center">Are you sure to delete?</p>

      <div className="d-flex gap-3 justify-content-center">
        <Button light text="cancel" onClick={closeModal} />
        <Button error loading={isLoading} onClick={deleteClick} text="Delete" />
      </div>
    </div>
  );
}

interface CreatePromoCodeProps {
  onSuccess: Function;
}

function CreatePromoCode({ onSuccess }: CreatePromoCodeProps) {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);

      await createPromoCode(data);

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={submit} className="p-3 d-flex flex-column gap-2">
      <div className="d-flex flex-column">
        <label htmlFor="">Code</label>
        <input required type="text" name="code" />
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="">Amount</label>
        <input required type="number" name="amount" />
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="">Expires At</label>
        <CustomDatePicker required name="expiresAt" />
      </div>

      <Button loading={isLoading} type="submit" text="Create" />
    </form>
  );
}

export default function PromoCodes() {
  const [isLoading, setIsLoading] = useState(false);
  const [promoCodes, setPromoCodes] = useState<iPromoCode[]>([]);

  const loadPromoCodes = async () => {
    setIsLoading(true);
    try {
      const data = await getCodesPaginated();
      setPromoCodes(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPromoCodes();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <main>
      <div className="container">
        <div className="content">
          <br />
          <Button
            onClick={() =>
              openModal(<CreatePromoCode onSuccess={loadPromoCodes} />)
            }
            text={"Create code"}
          />
          <hr />

          <table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Amount</th>
                <th>Expires At</th>
                <th>Auth link</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {promoCodes.map((code, key) => {
                return (
                  <tr key={"promocode_" + key}>
                    <td>{code.code}</td>
                    <td>{code.amount} €</td>
                    <td>
                      {new Date(code.expiresAt) > new Date() ? "🟢" : "🔴"}{" "}
                      {datetimeToString(code.expiresAt)}
                    </td>
                    <td>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://takyon.io/?promocode=${code.code}`}
                      >{`takyon.io/?promocode=${code.code}`}</a>
                    </td>
                    <td>
                      <Button
                        error
                        small
                        text="delete"
                        onClick={() =>
                          openModal(
                            <DeletePromoCode
                              id={code._id}
                              onSuccess={loadPromoCodes}
                            />
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}
