import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PAGE_DIM } from "../../utils/generic";
import { getDocumentsPaginated, iDocument } from "../../services/documents";
import { openModal } from "../../components/modal/Modal";
import Button from "../../components/dom/Button";
import CreateDocModal from "./Sub/CreateDocModal";
import DeleteDocModal from "./Sub/DeletDocModal";
import Document from "./Sub/Document";

export default function Documents() {
  const { t } = useTranslation();
  const [, setIsLoading] = useState(false);

  const [, setTotal] = useState<number>(0);
  const [page] = useState(1);
  const [size] = useState<number>(PAGE_DIM);

  const [documents, setDocuments] = useState<iDocument[]>([]);
  const [isDetailsShowed, setIsDetailsShowed] = useState<
    iDocument | undefined
  >();
  const [isEditShowed, setIsEditShowed] = useState<iDocument | undefined>();

  const loadDocs = async () => {
    setIsLoading(true);
    try {
      const data = await getDocumentsPaginated({ page, size });
      setDocuments(data.data.reverse());

      setTotal(data.total || 0);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadDocs();
    // eslint-disable-next-line
  }, []);

  const docsByLanguage = useMemo(() => {
    const groupedDocs: { [key: string]: iDocument[] } = {};
    documents.forEach((doc) => {
      const language = doc.lang;
      if (!groupedDocs[language]) {
        groupedDocs[language] = [];
      }
      groupedDocs[language].push(doc);
    });

    return groupedDocs;
  }, [documents]);

  if (isDetailsShowed)
    return (
      <div className="container">
        <div className="content">
          <Document
            onExit={() => setIsDetailsShowed(undefined)}
            doc={isDetailsShowed}
          />
        </div>
      </div>
    );

  if (isEditShowed)
    return (
      <div className="container">
        <div className="content">
          <CreateDocModal
            onSuccess={() => {
              setIsEditShowed(undefined);
              loadDocs();
            }}
            doc={isEditShowed}
            onExit={() => setIsEditShowed(undefined)}
          />
        </div>
      </div>
    );

  return (
    <main>
      <div className="container">
        <div className="content">
          <section id="support-section">
            <br />
            <Button
              onClick={() =>
                openModal(<CreateDocModal onSuccess={() => loadDocs()} />)
              }
              text={String(t("documents.create_doc"))}
              className="create-btn"
            />
            <hr />

            <span className="title">{String(t("documents.doc_title"))}</span>

            {Object.keys(docsByLanguage).map((language) => (
              <div key={Math.random()} className="group-lang">
                <h4>{language}</h4>
                <div className="support-table">
                  {docsByLanguage[language].map((doc: iDocument) => (
                    <div
                      key={Math.random()}
                      onClick={() => {
                        setIsDetailsShowed(doc);
                      }}
                      className="support-item documents-item"
                    >
                      <div className="doc-name">{doc.name}</div>

                      <div className="cat-type">{doc.type}</div>

                      <div
                        className="doc-name documents-item"
                        dangerouslySetInnerHTML={{ __html: doc.text }}
                      ></div>

                      <div className="btns-container">
                        <Button
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setIsEditShowed(doc);
                          }}
                          text={String(t("documents.edit_doc"))}
                          small
                        />

                        <Button
                          onClick={(e: any) => {
                            e.stopPropagation();
                            openModal(
                              <DeleteDocModal doc={doc} onSuccess={loadDocs} />
                            );
                          }}
                          text={String(t("documents.delete_doc"))}
                          error
                          small
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {documents.length === 0 && (
              <div>{String(t("support.no_docs"))}</div>
            )}
          </section>
        </div>
      </div>
    </main>
  );
}
