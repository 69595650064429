import { iUser } from "./../services/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../services/redux";

interface UserState {
  data: any;
}

const initialState: UserState = {
  data: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.data as iUser;

export default userSlice.reducer;
