import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { deleteCategory, iSupportFaq } from "../../../services/supportfaqs";
import { closeModal } from "../../../components/modal/Modal";
import Button from "../../../components/dom/Button";
import Loader from "../../../components/dom/Loader";

interface CategoryModalDeleteProps {
  category: iSupportFaq;
  onSuccess: Function;
}

export default function CategoryModalDelete({
  category,
  onSuccess,
}: CategoryModalDeleteProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteCategorySubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await deleteCategory({ _id: category._id });

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <div id="faq-modal">
      <form onSubmit={deleteCategorySubmit} className="modal-form">
        <span className="message">{t("support.delete_cat_confirm_1")}</span>
        <span className="message bold">{t("support.delete_cat_confirm_2")}</span>

        <Button
          className="btn btn-primary"
          text={String(t("support.delete"))}
          type="submit"
          loading={isLoading}
        />
      </form>
    </div>
  );
}
