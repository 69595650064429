import { iGroup } from "./../services/groups";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../services/redux";

interface GroupState {
  data: any;
}

const initialState: GroupState = {
  data: {},
};

export const groupSlice = createSlice({
  name: "group",
  initialState: initialState,
  reducers: {
    setGroup: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { setGroup } = groupSlice.actions;
export const selectGroup = (state: RootState) => state.group.data as iGroup;

export default groupSlice.reducer;
