import { FormEvent, useEffect, useRef, useState } from "react";
import { DropshipProps } from "../Dropship";
import {
  NFT_OWNER_TYPE,
  NFT_SELL_STATUS,
  dropshipBuy,
  getNftsPaginated,
  iNft,
  payloadHotelType,
} from "../../../services/nft";
import { selectUser } from "../../../store/userSlice";
import { useSelector } from "react-redux";
import {
  PAGE_DIM,
  datetimeToString,
  formToObject,
  scrollToTopList,
} from "../../../utils/generic";
import { debounce } from "lodash";
import searchSvg from "../../../assets/icons/search.svg";
import Loader from "../../../components/dom/Loader";
import { useTranslation } from "react-i18next";
import { closeModal, openModal } from "../../../components/modal/Modal";
import NftModal from "../../Collection/NftModal";
import viewSvg from "../../../assets/icons/view.svg";
import DropdownMenu from "../../../components/dom/DropdownMenu";
import Pagination from "../../../components/dom/Pagination";
import Button from "../../../components/dom/Button";
import { DEFAULT_OVERPRICE } from "../../../services/fees";
import CustomDatePicker from "../../../components/dom/CustomDatePicker";
import { useNavigate } from "react-router-dom";
import { selectGroup } from "../../../store/groupSlice";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import { DROPSHIP_PERMISSIONS } from "../../../services/dropship";
import { selectCollection } from "../../../store/collectionSlice";

interface iBuyDropshipModal {
  nft: iNft<payloadHotelType>;
  callback?: Function;
}

function BuyDropshipModal({ nft, callback }: iBuyDropshipModal) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState<"buy" | "offer">("buy");
  const [offerValue, setOfferValue] = useState(
    Number((nft.currentPrice ?? nft.originalPrice)?.toFixed(2))
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const stripeFees = (nft.currentPrice ?? 0) * DEFAULT_OVERPRICE;
  const price = nft.currentPrice ?? 0;
  const paymentAmount = price + stripeFees;

  const dropshipSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const payload = formToObject(e.target);
      await dropshipBuy(payload);

      closeModal();
      callback?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const dropshipOfferSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // logic here
      const payload = formToObject(e.target);
      // await dropshipOffer(payload);

      closeModal();
      // callback?.();
    } catch (error: any) {
      const e = String(error?.response?.data?.message);

      if (e.includes("already exists"))
        setError(String(t("nft.dropship_offer_already_exists")));
      else if (e) setError(e);
      else setError(String(t("nft.dropship_generic_error")));

      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <div className="d-flex gap-2 mb-3">
        <Button
          small
          text={String(t("nft.dropship_buy"))}
          disabled={mode === "buy"}
          onClick={() => setMode("buy")}
          light={mode !== "buy"}
        />
        <Button
          small
          text={String(t("nft.dropship_offer"))}
          // disabled={mode === "offer"}
          disabled
          onClick={() => setMode("offer")}
          light={mode !== "offer"}
        />
      </div>
      <hr />
      <p className="m-0">{t("nft.dropship_text")}</p>
      <hr />
      {mode === "buy" ? (
        <>
          <form onSubmit={dropshipSubmit}>
            <input name="nftId" type="text" hidden defaultValue={nft._id} />
            <div className="d-flex justify-content-between">
              <small>{t("nft.dropship_price")}</small>
              <small>{nft.currentPrice?.toFixed(2)} €</small>
            </div>
            <div className="d-flex justify-content-between">
              <small>{t("nft.dropship_fees")}</small>
              <small>{stripeFees?.toFixed(2)} €</small>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <span>{t("nft.dropship_payment_amount")}</span>
              <span className="h4">{paymentAmount?.toFixed(2)} €</span>
            </div>

            <div className="d-flex flex-column">
              <label htmlFor="">{t("nft.dropship_owner")}</label>
              <input type="email" name="newOwner" />
            </div>
            {nft.sellStatus !== NFT_SELL_STATUS.FOR_SALE ? (
              <p className="text-center border py-2">
                <i>{t("nft.dropship_not_for_sale")}</i>
              </p>
            ) : null}
            <div className="d-flex justify-content-end mt-2 gap-2">
              <Button
                onClick={() => closeModal()}
                light
                disabled={isLoading}
                text={String(t("nft.dropship_cancel"))}
              />
              <Button
                type="submit"
                loading={isLoading}
                disabled={nft.sellStatus !== NFT_SELL_STATUS.FOR_SALE}
                text={`${t("nft.dropship_confirm")}*`}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <small>*{t("nft.dropship_tip")}</small>
            </div>
          </form>
        </>
      ) : null}

      {mode === "offer" ? (
        <>
          <form onSubmit={dropshipOfferSubmit}>
            <input name="nftId" type="text" hidden defaultValue={nft._id} />
            <label htmlFor="">{t("nft.dropship_offer_date")}</label>
            <CustomDatePicker
              name="expireDate"
              defaultValue={new Date().setDate(new Date().getDate() + 1)}
            />
            <label htmlFor="">{t("nft.dropship_offer_value")}</label>
            <input
              type="number"
              name="amount"
              min={0}
              step={0.01}
              value={offerValue}
              onChange={(e) => setOfferValue(Number(e.target.value))}
            />
            <div className="d-flex justify-content-between">
              <small>{t("nft.dropship_fees")}</small>
              <small>
                {((offerValue ?? 0) * DEFAULT_OVERPRICE).toFixed(2)} €
              </small>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <span>{t("nft.dropship_payment_amount")}</span>
              <span className="h4">
                {((offerValue ?? 0) * (1 + DEFAULT_OVERPRICE)).toFixed(2)} €
              </span>
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              <input
                className="m-0"
                type="checkbox"
                name="delete"
                id="dropship-form-delete"
              />
              <label className="m-0" htmlFor="dropship-form-delete">
                {t("nft.dropship_delete_label")}
              </label>
            </div>
            <div className="d-flex justify-content-end mt-2 gap-2">
              <Button
                onClick={() => closeModal()}
                light
                disabled={isLoading}
                text={String(t("nft.dropship_cancel"))}
              />
              <Button
                type="submit"
                loading={isLoading}
                text={`${t("nft.dropship_offer_confirm")}*`}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <small>*{t("nft.dropship_tip")}</small>
            </div>
          </form>
        </>
      ) : null}

      <p className="text-center text-danger pt-2">{error}</p>
    </div>
  );
}

export interface loadNftsProps {
  page?: number;
  size?: number;
  textQuery?: any;
}

export default function DropshipNfts({ dropship }: DropshipProps) {
  const [nfts, setNfts] = useState<iNft<payloadHotelType>[]>([]);
  const user = useSelector(selectUser);
  const elementRef = useRef<HTMLTableElement>(null);

  const { t } = useTranslation();

  const [textQuery, setTextQuery] = useState<any>({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const group = useSelector(selectGroup);
  const collection = useSelector(selectCollection); // ?????

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  const loadNfts = async ({ page = 1, size, textQuery }: loadNftsProps) => {
    setIsLoading(true);
    try {
      const data = await getNftsPaginated({
        page,
        size,
        query: { ...textQuery, sellStatus: NFT_SELL_STATUS.FOR_SALE },
      });

      setNfts(data.data);
      setTotal(Number(data.total));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const searchCallbackDebounced = useRef(debounce(loadNfts, 1000));

  const searchInput = (text: string) => {
    const textQ = {
      $or: [
        { _id: { $regex: text, $options: "i" } },
        { owner: { $regex: text, $options: "i" } },
        { "payload.reference": { $regex: text, $options: "i" } },
        { "payload.room": { $regex: text, $options: "i" } },
      ],
    };

    setTextQuery(textQ);

    searchCallbackDebounced.current({
      page,
      textQuery: textQ,
    });
  };

  useEffect(() => {
    loadNfts({ page, size, textQuery });
    // eslint-disable-next-line
  }, [page, size, dropship]);

  const canSaleNft = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.dropship)
    )
      return true;

    const myCollectionPerms = dropship.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(DROPSHIP_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(DROPSHIP_PERMISSIONS.sale)
    )
      return true;

    return false;
  };

  return (
    <section>
      <div className="d-flex justify-content-end gap-2"></div>
      {/* <div className="d-flex gap-2 mt-2"></div> */}
      <div className="search-nft">
        <input
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            searchInput(e.target.value);
          }}
          className="w100"
          type="text"
          placeholder={String(t("nft.search_placeholder"))}
        />
        <img src={searchSvg} alt="search icon" className="icon" />
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : nfts.length ? (
        <>
          <table className="mt-3" ref={elementRef}>
            <thead>
              <tr>
                <th>{t("nft.details")}</th>
                <th>#</th>
                <th>{t("nft.booking_ref")}</th>
                <th>{t("nft.hotel")}</th>
                <th>{t("nft.owner")}</th>
                <th>{t("nft.guests")}</th>
                <th>
                  {t("nft.checkin")} - {t("nft.checkout")}
                </th>
                <th>{t("nft.original_price")}</th>
                <th>{t("nft.current_price")}</th>
                <th>{t("nft.sale")}</th>
                <th>{t("nft.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {nfts.map((nft) => (
                <tr key={"nft_" + nft._id}>
                  <td>
                    <img
                      className="view-icon"
                      onClick={() => {
                        openModal(<NftModal nftId={nft._id!} />);
                      }}
                      src={viewSvg}
                      alt="eye icon"
                    />
                  </td>
                  <td>
                    {String(nft._id).substring(String(nft._id).length - 6)}
                  </td>
                  <td>{nft.payload?.reference}</td>
                  <td>{nft.payload?.hotel}</td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      maxWidth: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {nft.ownerType === NFT_OWNER_TYPE.RESOLD ? "🟡" : ""}{" "}
                    <a href={`mailto:${nft.owner}`}>{nft.owner}</a>
                  </td>
                  <td>
                    {nft.payload?.rooms?.reduce((total, room) => {
                      return (
                        total +
                        (room.guestsAdults ?? 0) +
                        (room.guestsKids ?? 0)
                      );
                    }, 0)}
                  </td>
                  <td>
                    {datetimeToString(
                      nft.payload?.checkin,
                      collection.census?.location?.gmt,
                      true
                    )}{" "}
                    -{" "}
                    {datetimeToString(
                      nft.payload?.checkout,
                      collection.census?.location?.gmt,
                      true
                    )}
                  </td>
                  <td>{Number(nft.originalPrice).toFixed(2)}€</td>
                  <td>
                    {nft.currentPrice
                      ? `${Number(nft.currentPrice).toFixed(2)}€`
                      : null}
                  </td>
                  <td>
                    {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? "🟢" : ""}
                  </td>

                  <td>
                    <DropdownMenu
                      position="down-left"
                      small
                      hovered
                      options={[
                        {
                          disabled:
                            new Date(nft.lockDate as any) < new Date() ||
                            !canSaleNft(),
                          text: t("nft.dropship_buy"),
                          action: () =>
                            openModal(
                              <BuyDropshipModal callback={loadNfts} nft={nft} />
                            ),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            page={page}
            size={size}
            total={total}
            onSizeChange={onSizeChange}
            onPageChange={onPageChange}
          />
        </>
      ) : (
        <p>{t("pagination.no_show")}</p>
      )}
    </section>
  );
}
