import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  COLLECTION_PERMISSIONS,
  autofillCollectionCensusInfo,
  hotelCensus,
  hotelCensusInfo,
  hotelStats,
  iCollection,
  patchCollection,
} from "../../services/collection";
import { GROUP_PERMISSIONS } from "../../services/groups";
import { selectUser } from "../../store/userSlice";

import Select from "../dom/Select";
import { CollectionRouterProps } from "../../pages/Collection/Collection";
import Button from "../dom/Button";
import {
  formToObject,
  hotelCensusLocationTypeOptions,
  sleep,
  starsOptions,
} from "../../utils/generic";
import MapsAutocomplete from "../mapsautocomplete/MapsAutocomplete";
import { LANG } from "../../services/i18next";
import closeSvg from "../../assets/icons/close.svg";
import Upload from "../Upload/Upload";

interface CollectionCensusProps {
  collection: iCollection;
  onRefresh: Function;
  canEdit: boolean;
}

function CollectionCensusStats({ collection, canEdit }: CollectionCensusProps) {
  const [stats, setStats] = useState<hotelStats>({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (collection.censusStats) setStats({ ...collection.censusStats });
  }, [collection]);

  const save = async () => {
    setIsLoading(true);
    try {
      await patchCollection({
        ...collection,
        censusStats: stats,
        collectionId: collection._id,
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <form>
        <div className="hr"></div>

        <strong>{t("collection.hotel_stats_basics")}</strong>
        <div className="d-flex gap-3 mt-3">
          <div className="d-flex flex-column w100">
            <label htmlFor="census-form-averageBookingPrice">
              {t("collection.census_averageBookingPrice")}
            </label>
            <input
              value={stats.averageBookingPrice}
              onChange={(e) => {
                setStats((stats) => {
                  stats.averageBookingPrice = Number(e.target.value);
                  return { ...stats };
                });
              }}
              type="number"
              min={0}
              step={0.01}
              id="census-form-averageBookingPrice"
              disabled={isLoading || !canEdit}
            />
          </div>

          <div className="d-flex flex-column w100">
            <label htmlFor="census-form-averageNumberOfDaysPerStay">
              {t("collection.census_averageNumberOfDaysPerStay")}
            </label>
            <input
              disabled={isLoading || !canEdit}
              value={stats.averageNumberOfDaysPerStay}
              onChange={(e) => {
                setStats((stats) => {
                  stats.averageNumberOfDaysPerStay = Number(e.target.value);
                  return { ...stats };
                });
              }}
              type="number"
              min={0}
              step={0.01}
              id="census-form-averageNumberOfDaysPerStay"
            />
          </div>
        </div>

        <div className="d-flex gap-3">
          <div className="d-flex flex-column w100">
            <label htmlFor="census-form-averageOccupancyPercentage">
              {t("collection.census_averageOccupancyPercentage")}
            </label>
            <input
              disabled={isLoading || !canEdit}
              value={stats.averageOccupancyPercentage}
              onChange={(e) => {
                setStats((stats) => {
                  stats.averageOccupancyPercentage = Number(e.target.value);
                  return { ...stats };
                });
              }}
              type="number"
              min={0}
              max={100}
              step={0.01}
              id="census-form-averageOccupancyPercentage"
            />
          </div>

          <div className="d-flex flex-column w100">
            <label htmlFor="census-form-directBookingPercentage">
              {t("collection.census_directBookingPercentage")}
            </label>
            <input
              disabled={isLoading || !canEdit}
              value={stats.directBookingPercentage}
              onChange={(e) => {
                setStats((stats) => {
                  stats.directBookingPercentage = Number(e.target.value);
                  return { ...stats };
                });
              }}
              type="number"
              min={0}
              max={100}
              step={0.01}
              id="census-form-directBookingPercentage"
            />
          </div>
        </div>

        <div className="d-flex gap-3">
          <div className="d-flex flex-column w100">
            <label htmlFor="census-form-nonRefoundableSalesPercentage">
              {t("collection.census_nonRefundableSalesPercentage")}
            </label>
            <input
              disabled={isLoading || !canEdit}
              value={stats.nonRefoundableSalesPercentage}
              onChange={(e) => {
                setStats((stats) => {
                  stats.nonRefoundableSalesPercentage = Number(e.target.value);
                  return { ...stats };
                });
              }}
              type="number"
              min={0}
              max={100}
              step={0.01}
              id="census-form-nonRefoundableSalesPercentage"
            />
          </div>
        </div>

        {canEdit ? (
          <div className="d-flex">
            <Button
              text={String(t("collection.census_save"))}
              onClick={() => save()}
              loading={isLoading}
            />
          </div>
        ) : null}
      </form>
    </>
  );
}

function CollectionCensusGeneric({
  collection,
  canEdit,
}: CollectionCensusProps) {
  const [census, setCensus] = useState<hotelCensus>({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (collection.census) setCensus({ ...collection.census });
  }, [collection]);

  const save = async () => {
    setIsLoading(true);
    try {
      await patchCollection({
        ...collection,
        census: census,
        collectionId: collection._id,
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <form>
      <strong>{t("collection.hotel_basics")}</strong>

      <div className="d-flex gap-3 mt-3">
        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-name">
            {t("collection.census_name")}
          </label>
          <input
            disabled={isLoading || !canEdit}
            value={census.name}
            onChange={(e) => {
              setCensus((census) => {
                census.name = e.target.value;
                return { ...census };
              });
            }}
            type="text"
            id="census-form-name"
          />
        </div>
      </div>

      <div className="d-flex gap-3">
        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-stars">
            {t("collection.census_stars")}
          </label>
          <Select
            disabled={isLoading || !canEdit}
            value={String(census.stars)}
            defaultValue={""}
            onChange={(e) => {
              setCensus((census) => {
                census.stars = e.target.value;
                return { ...census };
              });
            }}
            id="census-form-stars"
            options={starsOptions.map((s) => {
              return {
                value: s.value,
                text: s.text,
                key: s.value,
              };
            })}
          />
        </div>

        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-locationType">
            {t("collection.census_location_type")}
          </label>
          <Select
            disabled={isLoading || !canEdit}
            value={String(census.locationType)}
            defaultValue={""}
            onChange={(e) => {
              setCensus((census) => {
                census.locationType = e.target.value as any;
                return { ...census };
              });
            }}
            id="census-form-locationType"
            options={hotelCensusLocationTypeOptions.map((s) => {
              return {
                value: s.value ?? "",
                text: s.text,
                key: s.value,
              };
            })}
          />
        </div>
      </div>
      <br />

      <span>{t("collection.extra")}</span>

      <div className="d-flex flex-column w100">
        <label htmlFor="census-form-note">{t("collection.note")}</label>
        <div>
          <textarea
            disabled={isLoading || !canEdit}
            value={census.note}
            onChange={(e) => {
              setCensus((census) => {
                census.note = e.target.value;
                return { ...census };
              });
            }}
            id="census-form-note"
            cols={50}
            className="w100"
          ></textarea>
        </div>
      </div>

      <div>
        <label htmlFor="">{t("collection.census_location")}</label>
        <MapsAutocomplete
          onSelect={(e: string) => {
            setCensus((census) => {
              census.location = e;
              return { ...census };
            });
          }}
          defaultValue={census.location}
        />
      </div>

      <div className="d-flex gap-3">
        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-email">
            {t("collection.census_email")}
          </label>
          <input
            disabled={isLoading || !canEdit}
            value={census.email}
            onChange={(e) => {
              setCensus((census) => {
                census.email = e.target.value;
                return { ...census };
              });
            }}
            type="text"
            id="census-form-email"
          />
        </div>

        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-website">
            {t("collection.census_website")}
          </label>
          <input
            disabled={isLoading || !canEdit}
            value={census.website}
            onChange={(e) => {
              setCensus((census) => {
                census.website = e.target.value;
                return { ...census };
              });
            }}
            type="text"
            id="census-form-website"
          />
        </div>
      </div>

      <span>{t("collection.responsable_basics")}</span>
      <div className="d-flex gap-3">
        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-firstName">
            {t("collection.census_firstName")}
          </label>
          <input
            disabled={isLoading || !canEdit}
            value={census.firstName}
            onChange={(e) => {
              setCensus((census) => {
                census.firstName = e.target.value;
                return { ...census };
              });
            }}
            type="text"
            id="census-form-firstName"
          />
        </div>

        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-secondName">
            {t("collection.census_secondName")}
          </label>
          <input
            disabled={isLoading || !canEdit}
            value={census.secondName}
            onChange={(e) => {
              setCensus((census) => {
                census.secondName = e.target.value;
                return { ...census };
              });
            }}
            type="text"
            id="census-form-secondName"
          />
        </div>
      </div>

      {user.isAdmin ? (
        <>
          <div className={`d-flex gap-3`}>
            <div className="d-flex flex-column w100">
              <label htmlFor="census-form-hotelsComSlug">
                {t("collection.census_hotelsComSlug")}
              </label>
              <input
                disabled={isLoading || !canEdit}
                value={census.hotelsComSlug}
                onChange={(e) => {
                  setCensus((census) => {
                    census.hotelsComSlug = e.target.value;
                    return { ...census };
                  });
                }}
                type="text"
                id="census-form-hotelsComSlug"
              />
            </div>

            <div className="d-flex flex-column w100">
              <label htmlFor="census-form-hotelsComId">
                {t("collection.census_hotelsComId")}
              </label>
              <input
                disabled={isLoading || !canEdit}
                value={census.hotelsComId}
                onChange={(e) => {
                  setCensus((census) => {
                    census.hotelsComId = e.target.value;
                    return { ...census };
                  });
                }}
                type="text"
                id="census-form-hotelsComId"
              />
            </div>
          </div>
        </>
      ) : null}

      {canEdit ? (
        <div>
          <Button
            loading={isLoading}
            text={String(t("collection.census_save"))}
            onClick={() => save()}
            // light={!isEditButtonEnabled}
            // disabled={!isEditButtonEnabled}
          />
        </div>
      ) : null}
    </form>
  );
}

function CollectionCensusInfo({ collection, canEdit }: CollectionCensusProps) {
  const [info, setInfo] = useState<hotelCensusInfo>({});
  // const [lang, setLang] = useState<LANG>(DEFAULT_LANGUAGE);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setInfo((info) => {
      if (collection.census?.info)
        info = JSON.parse(JSON.stringify(collection.census.info));

      Object.values(LANG).forEach((lang) => {
        if (!info[lang]) info[lang] = {};

        if (!info[lang]?.services) info[lang]!.services = [];
        if (!info[lang]?.rooms) info[lang]!.rooms = [];
        if (!info[lang]?.poi) info[lang]!.poi = [];
        if (!info[lang]?.policies) info[lang]!.policies = [];
        if (!info[lang]?.reviews) info[lang]!.reviews = [];
        if (!info[lang]?.reviewSummary) info[lang]!.reviewSummary = {};
      });

      return { ...info };
    });
  }, [collection]);

  const save = async () => {
    setIsLoading(true);
    try {
      await patchCollection({
        ...collection,
        census: { ...collection.census, info },
        collectionId: collection._id,
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const autofill = async () => {
    setIsLoading(true);
    try {
      const langs = Object.values(LANG);

      let info = {};

      for (let i = 0; i < langs.length; i++) {
        const lang = langs[i];
        const filledInfo = await autofillCollectionCensusInfo(collection, lang);

        info = { ...info, [lang]: filledInfo[lang] };

        await sleep(5000);
      }

      setInfo(info);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <form>
      <fieldset disabled={isLoading || !canEdit}>
        <div className="w100 d-flex flex-row justify-content-between">
          <strong>{t("collection.hotel_census_info")}</strong>
          <Button
            loading={isLoading}
            text={`${t("collection.hotel_census_info_autofill")}`}
            small
            onClick={() => {
              autofill();
            }}
            id="autofill-btn"
          />
        </div>
        <br />
        <div className="d-flex justify-content-between">
          <Button
            onClick={() => {
              document
                .getElementById("translations-scroll")
                ?.scrollBy({ left: -500, behavior: "smooth" });
            }}
            light
            small
            text="<"
          />
          <Button
            onClick={() => {
              document
                .getElementById("translations-scroll")
                ?.scrollBy({ left: 500, behavior: "smooth" });
            }}
            light
            small
            text=">"
          />
        </div>
        <br />
        <div
          id="translations-scroll"
          className="d-flex"
          style={{ overflowX: "auto" }}
        >
          {Object.values(LANG).map((lang) => {
            return (
              <div style={{ minWidth: "500px" }} className="d-inline mx-3">
                {/* description */}
                <h5 style={{ maxWidth: "max-content" }} className="border p-1">
                  {lang}
                </h5>
                <span>{t("collection.info_description")}</span>

                <div className="d-flex flex-column w100">
                  {/* <label htmlFor="census-form-note">
            {t("collection.info_description")}
          </label> */}
                  <div>
                    <textarea
                      disabled={isLoading || !canEdit}
                      value={info[lang]?.description ?? ""}
                      onChange={(e) => {
                        setInfo((info) => {
                          info = JSON.parse(JSON.stringify(info));
                          info[lang]!.description = e.target.value;
                          return { ...info };
                        });
                      }}
                      id="census-form-note"
                      cols={50}
                      className="w100"
                    ></textarea>
                  </div>
                </div>

                <br />

                {/* services */}

                <div className="w100 d-flex flex-row justify-content-between">
                  <span>{t("collection.info_services")}</span>

                  <Button
                    loading={isLoading}
                    small
                    text={String(t("collection.info_add_service"))}
                    onClick={() => {
                      setInfo((info) => {
                        info = JSON.parse(JSON.stringify(info));
                        info[lang]!.services = [
                          { icon: "", value: "" },
                          ...info[lang]!.services!,
                        ];
                        return { ...info };
                      });
                    }}
                  />
                </div>

                <br />

                <table>
                  <thead>
                    <tr>
                      <th>{t("collection.info_service_icon_name")}</th>
                      <th>{t("collection.info_service_value")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {info[lang]?.services?.map((service, key) => (
                      <tr key={"service_" + key}>
                        <td>
                          <input
                            className="w100 m-0"
                            type="text"
                            value={service.icon}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.services![key]!.icon =
                                  e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className="w100 m-0"
                            type="text"
                            value={service.value}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.services![key]!.value =
                                  e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                        <td>
                          <img
                            src={closeSvg}
                            alt="delete icon"
                            className="cursor-pointer"
                            width="20px"
                            onClick={() => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.services?.splice(key, 1);
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <br />
                <br />

                {/* poi */}

                <div className="w100 d-flex flex-row justify-content-between">
                  <span>{t("collection.info_poi")}</span>

                  <Button
                    loading={isLoading}
                    small
                    text={String(t("collection.info_add_poi"))}
                    onClick={() => {
                      setInfo((info) => {
                        info = JSON.parse(JSON.stringify(info));
                        info[lang]!.poi = [
                          { icon: "", value: "", text: "" },
                          ...info[lang]!.poi!,
                        ];
                        return { ...info };
                      });
                    }}
                  />
                </div>

                <br />

                <table>
                  <thead>
                    <tr>
                      <th>{t("collection.info_poi_icon_name")}</th>
                      <th>{t("collection.info_poi_value")}</th>
                      <th>{t("collection.info_poi_text")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {info[lang]?.poi?.map((poi, key) => (
                      <tr key={"poi_" + key}>
                        <td>
                          <input
                            className="w100 m-0"
                            type="text"
                            value={poi.icon}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.poi![key]!.icon = e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className="w100 m-0"
                            type="text"
                            value={poi.value}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.poi![key]!.value = e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className="w100 m-0"
                            type="text"
                            value={poi.text}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.poi![key]!.text = e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                        <td>
                          <img
                            src={closeSvg}
                            alt="delete icon"
                            className="cursor-pointer"
                            width="20px"
                            onClick={() => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.poi?.splice(key, 1);
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <br />
                <br />

                {/* policies */}

                <div className="w100 d-flex flex-row justify-content-between">
                  <span>{t("collection.info_policies")}</span>

                  <Button
                    loading={isLoading}
                    small
                    text={String(t("collection.info_add_policy"))}
                    onClick={() => {
                      setInfo((info) => {
                        info = JSON.parse(JSON.stringify(info));
                        info[lang]!.policies = [
                          { title: "", text: "" },
                          ...info[lang]!.policies!,
                        ];
                        return { ...info };
                      });
                    }}
                  />
                </div>

                <br />

                <table>
                  <thead>
                    <tr>
                      <th>{t("collection.info_policy_title")}</th>
                      <th>{t("collection.info_policy_text")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {info[lang]?.policies?.map((policy, key) => (
                      <tr key={"policy_" + key}>
                        <td>
                          <input
                            className="w100 m-0"
                            type="text"
                            value={policy.title}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.policies![key]!.title =
                                  e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className="w100 m-0"
                            type="text"
                            value={policy.text}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.policies![key]!.text =
                                  e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                        <td>
                          <img
                            src={closeSvg}
                            alt="delete icon"
                            className="cursor-pointer"
                            width="20px"
                            onClick={() => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.services?.splice(key, 1);
                                return { ...info };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <br />
                <br />

                {/* rooms */}

                <div className="w100 d-flex flex-row justify-content-between">
                  <span>{t("collection.info_rooms")}</span>

                  <Button
                    loading={isLoading}
                    small
                    text={String(t("collection.info_add_room"))}
                    onClick={() => {
                      setInfo((info) => {
                        info = JSON.parse(JSON.stringify(info));
                        info[lang]!.rooms = [
                          { name: "", services: [], traits: [], images: [] },
                          ...info[lang]!.rooms!,
                        ];
                        return { ...info };
                      });
                    }}
                  />
                </div>

                <br />

                {info[lang]?.rooms?.map((room, key) => {
                  return (
                    <div
                      key={"room_" + key}
                      className="mb-3"
                      style={{
                        padding: "20px",
                        border: "1px solid gray",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <input
                          className="w100 m-0"
                          type="text"
                          value={room.name}
                          onChange={(e) => {
                            setInfo((info) => {
                              info = JSON.parse(JSON.stringify(info));
                              info[lang]!.rooms![key]!.name = e.target.value;
                              return { ...info };
                            });
                          }}
                        />

                        <img
                          src={closeSvg}
                          alt="delete icon"
                          className="cursor-pointer"
                          width="20px"
                          onClick={() => {
                            setInfo((info) => {
                              info = JSON.parse(JSON.stringify(info));
                              info[lang]!.rooms?.splice(key, 1);
                              return { ...info };
                            });
                          }}
                        />
                      </div>

                      <br />

                      {/* room services */}
                      <hr />

                      <div className="w100 d-flex flex-row justify-content-between">
                        <span>{t("collection.info_room_services")}</span>

                        <Button
                          loading={isLoading}
                          small
                          text={String(t("collection.info_add_room_service"))}
                          onClick={() => {
                            setInfo((info) => {
                              info = JSON.parse(JSON.stringify(info));
                              info[lang]!.rooms![key].services = [
                                { icon: "", value: "", data: "" },
                                ...info[lang]!.rooms![key].services!,
                              ];
                              return { ...info };
                            });
                          }}
                        />
                      </div>

                      <br />

                      <table>
                        <thead>
                          <tr>
                            <th>{t("collection.info_service_icon")}</th>
                            <th>{t("collection.info_service_value")}</th>
                            <th>{t("collection.info_service_data")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {info[lang]!.rooms![key].services?.map(
                            (service, service_key) => (
                              <tr key={"service_" + service_key}>
                                <td>
                                  <input
                                    className="w100 m-0"
                                    type="text"
                                    value={service.icon}
                                    onChange={(e) => {
                                      setInfo((info) => {
                                        info = JSON.parse(JSON.stringify(info));
                                        info[lang]!.rooms![key].services![
                                          service_key
                                        ].icon = e.target.value;
                                        return { ...info };
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="w100 m-0"
                                    type="text"
                                    value={service.value}
                                    onChange={(e) => {
                                      setInfo((info) => {
                                        info = JSON.parse(JSON.stringify(info));
                                        info[lang]!.rooms![key].services![
                                          service_key
                                        ].value = e.target.value;
                                        return { ...info };
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="w100 m-0"
                                    type="text"
                                    value={service.data}
                                    onChange={(e) => {
                                      setInfo((info) => {
                                        info = JSON.parse(JSON.stringify(info));
                                        info[lang]!.rooms![key].services![
                                          service_key
                                        ].data = e.target.value;
                                        return { ...info };
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={closeSvg}
                                    alt="delete icon"
                                    className="cursor-pointer"
                                    width="20px"
                                    onClick={() => {
                                      setInfo((info) => {
                                        info = JSON.parse(JSON.stringify(info));
                                        info[lang]!.rooms![
                                          key
                                        ].services!.splice(service_key, 1);
                                        return { ...info };
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>

                      <br />

                      {/* room traits */}
                      <hr />

                      <div className="w100 d-flex flex-row justify-content-between">
                        <span>{t("collection.info_room_traits")}</span>

                        <Button
                          loading={isLoading}
                          small
                          text={String(t("collection.info_add_room_trait"))}
                          onClick={() => {
                            setInfo((info) => {
                              info = JSON.parse(JSON.stringify(info));
                              info[lang]!.rooms![key].traits = [
                                { icon: "", value: "" },
                                ...info[lang]!.rooms![key].traits!,
                              ];
                              return { ...info };
                            });
                          }}
                        />
                      </div>

                      <br />

                      <table>
                        <thead>
                          <tr>
                            <th>{t("collection.info_trait_icon")}</th>
                            <th>{t("collection.info_trait_value")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {info[lang]!.rooms![key].traits?.map(
                            (trait, trait_key) => (
                              <tr key={"trait_" + trait_key}>
                                <td>
                                  <input
                                    className="w100 m-0"
                                    type="text"
                                    value={trait.icon}
                                    onChange={(e) => {
                                      setInfo((info) => {
                                        info = JSON.parse(JSON.stringify(info));
                                        info[lang]!.rooms![key].traits![
                                          trait_key
                                        ].icon = e.target.value;
                                        return { ...info };
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="w100 m-0"
                                    type="text"
                                    value={trait.value}
                                    onChange={(e) => {
                                      setInfo((info) => {
                                        info = JSON.parse(JSON.stringify(info));
                                        info[lang]!.rooms![key].traits![
                                          trait_key
                                        ].value = e.target.value;
                                        return { ...info };
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={closeSvg}
                                    alt="delete icon"
                                    className="cursor-pointer"
                                    width="20px"
                                    onClick={() => {
                                      setInfo((info) => {
                                        info = JSON.parse(JSON.stringify(info));
                                        info[lang]!.rooms![key].traits!.splice(
                                          trait_key,
                                          1
                                        );
                                        return { ...info };
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>

                      {/* room images */}
                      <hr />

                      <br />

                      <div className="w100 d-flex flex-row justify-content-between">
                        <span>{t("collection.info_room_images")}</span>

                        <div>
                          <Upload
                            onStartLoading={() => setIsLoading(true)}
                            onEndLoading={() => setIsLoading(false)}
                            accept="image/png, image/jpeg"
                            text={String(
                              t("collection.info_room_images_upload")
                            )}
                            multiple
                            small
                            onUpload={(urls) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.rooms![key].images = [
                                  ...(info[lang]!.rooms![key].images ?? []),
                                  ...urls,
                                ];
                                return { ...info };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-wrap">
                        {info[lang]!.rooms![key].images?.map(
                          (image, image_key) => (
                            <div
                              key={"image_" + image_key}
                              className="position-relative"
                            >
                              <img
                                width={"130px"}
                                key={"image_" + Math.random()}
                                src={image}
                                alt="takyon hotel"
                                className="m-1"
                              />

                              <img
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  backgroundColor: "white",
                                  borderRadius: "100%",
                                }}
                                src={closeSvg}
                                alt="delete icon"
                                className="cursor-pointer"
                                width="20px"
                                onClick={() => {
                                  setInfo((info) => {
                                    info = JSON.parse(JSON.stringify(info));
                                    info[lang]!.rooms![key].images!.splice(
                                      image_key,
                                      1
                                    );
                                    return { ...info };
                                  });
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                })}

                <br />
                <br />

                {/* reviews summary */}

                <span>{t("collection.info_review_summary")}</span>

                <br />
                <br />

                <div className="d-flex gap-2">
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">
                      {t("collection.info_review_summary_vote")}
                    </label>
                    <input
                      type="number"
                      value={info[lang]?.reviewSummary?.vote}
                      onChange={(e) => {
                        setInfo((info) => {
                          info = JSON.parse(JSON.stringify(info));
                          info[lang]!.reviewSummary!.vote = Number(
                            e.target.value
                          );
                          return { ...info };
                        });
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">
                      {t("collection.info_review_summary_vote_text")}
                    </label>
                    <input
                      type="text"
                      value={info[lang]?.reviewSummary?.voteText}
                      onChange={(e) => {
                        setInfo((info) => {
                          info = JSON.parse(JSON.stringify(info));
                          info[lang]!.reviewSummary!.voteText = e.target.value;
                          return { ...info };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">
                      {t("collection.info_review_clean")}
                    </label>
                    <input
                      type="number"
                      value={info[lang]?.reviewSummary?.cleanlinessVote}
                      onChange={(e) => {
                        setInfo((info) => {
                          info = JSON.parse(JSON.stringify(info));
                          info[lang]!.reviewSummary!.cleanlinessVote = Number(
                            e.target.value
                          );
                          return { ...info };
                        });
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">
                      {t("collection.info_review_summary_condition")}
                    </label>
                    <input
                      type="text"
                      value={info[lang]?.reviewSummary?.hotelConditionVote}
                      onChange={(e) => {
                        setInfo((info) => {
                          info = JSON.parse(JSON.stringify(info));
                          info[lang]!.reviewSummary!.hotelConditionVote =
                            Number(e.target.value);
                          return { ...info };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">
                      {t("collection.info_review_summary_confort")}
                    </label>
                    <input
                      type="number"
                      value={info[lang]?.reviewSummary?.roomConfortVote}
                      onChange={(e) => {
                        setInfo((info) => {
                          info = JSON.parse(JSON.stringify(info));
                          info[lang]!.reviewSummary!.roomConfortVote = Number(
                            e.target.value
                          );
                          return { ...info };
                        });
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">
                      {t("collection.info_review_summary_staff")}
                    </label>
                    <input
                      type="text"
                      value={info[lang]?.reviewSummary?.serviceStaffVote}
                      onChange={(e) => {
                        setInfo((info) => {
                          info = JSON.parse(JSON.stringify(info));
                          info[lang]!.reviewSummary!.serviceStaffVote = Number(
                            e.target.value
                          );
                          return { ...info };
                        });
                      }}
                    />
                  </div>
                </div>
                <br />

                {/* reviews */}

                <div className="w100 d-flex flex-row justify-content-between">
                  <span>{t("collection.info_reviews")}</span>

                  <Button
                    loading={isLoading}
                    small
                    text={String(t("collection.info_add_review"))}
                    onClick={() => {
                      setInfo((info) => {
                        info = JSON.parse(JSON.stringify(info));
                        info[lang]!.reviews! = [
                          { title: "", text: "" },
                          ...info[lang]!.reviews!,
                        ];
                        return { ...info };
                      });
                    }}
                  />
                </div>

                <br />

                {info[lang]?.reviews?.map((review, key) => {
                  return (
                    <div
                      key={"review_" + key}
                      style={{
                        padding: "20px",
                        border: "1px solid gray",
                        borderRadius: "10px",
                      }}
                      className="d-flex flex-column my-3 position-relative"
                    >
                      <img
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                        src={closeSvg}
                        alt="delete icon"
                        className="cursor-pointer"
                        width="20px"
                        onClick={() => {
                          setInfo((info) => {
                            info = JSON.parse(JSON.stringify(info));
                            info[lang]!.reviews!.splice(key, 1);
                            return { ...info };
                          });
                        }}
                      />

                      <label htmlFor="">
                        {t("collection.info_reviews_title")}
                      </label>
                      <input
                        type="text"
                        value={review.title}
                        onChange={(e) => {
                          setInfo((info) => {
                            info = JSON.parse(JSON.stringify(info));
                            info[lang]!.reviews![key]!.title = e.target.value;
                            return { ...info };
                          });
                        }}
                      />

                      <div className="d-flex gap-2">
                        <div className="d-flex flex-column w100">
                          <label htmlFor="">
                            {t("collection.info_reviews_vote")}
                          </label>
                          <input
                            type="number"
                            value={review.vote}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.reviews![key]!.vote = Number(
                                  e.target.value
                                );
                                return { ...info };
                              });
                            }}
                          />
                        </div>
                        <div className="d-flex flex-column w100">
                          <label htmlFor="">
                            {t("collection.info_reviews_vote_text")}
                          </label>
                          <input
                            type="text"
                            value={review.voteText}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.reviews![key]!.voteText =
                                  e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <label htmlFor="">
                        {t("collection.info_reviews_text")}
                      </label>
                      <textarea
                        value={review.text}
                        onChange={(e) => {
                          setInfo((info) => {
                            info = JSON.parse(JSON.stringify(info));
                            info[lang]!.reviews![key]!.text = e.target.value;
                            return { ...info };
                          });
                        }}
                      ></textarea>

                      <div className="d-flex gap-2">
                        <div className="d-flex flex-column w100">
                          <label htmlFor="">
                            {t("collection.info_reviews_date")}
                          </label>
                          <input
                            type="text"
                            value={review.date}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.reviews![key]!.date =
                                  e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </div>
                        <div className="d-flex flex-column w100">
                          <label htmlFor="">
                            {t("collection.info_reviews_context")}
                          </label>
                          <input
                            type="text"
                            value={review.context}
                            onChange={(e) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.reviews![key]!.context =
                                  e.target.value;
                                return { ...info };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <br />
        <br />

        {canEdit ? (
          <div>
            <Button
              loading={isLoading}
              text={String(t("collection.census_save"))}
              onClick={() => save()}
              // light={!isEditButtonEnabled}
              // disabled={!isEditButtonEnabled}
              id="save-info-btn"
            />
          </div>
        ) : null}
      </fieldset>
    </form>
  );
}

export default function CensusHotel({
  collection,
  group,
  onRefresh,
}: CollectionRouterProps) {
  const user = useSelector(selectUser);

  const canEdit = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.census)
    )
      return true;

    return false;
  };

  return (
    <section className="collection-sub-page collection-census">
      <>
        {/* stats */}
        <CollectionCensusStats
          canEdit={canEdit()}
          onRefresh={onRefresh}
          collection={collection}
        />
        <hr />
        {/* generic details */}
        <CollectionCensusGeneric
          canEdit={canEdit()}
          onRefresh={onRefresh}
          collection={collection}
        />
        <hr />
        {/* hotel info */}
        {user.isAdmin ||
        user.email === "dataentry@takyon.io" ||
        user.email === "dataentry2@takyon.io" ? (
          <CollectionCensusInfo
            canEdit={canEdit()}
            onRefresh={onRefresh}
            collection={collection}
          />
        ) : null}
      </>
    </section>
  );
}
