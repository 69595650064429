import { useEffect, useRef, useState } from "react";
import {
  getCollections,
  hotelCensusLocationType,
  hotelStats,
  iCollection,
  patchCollection,
} from "../../services/collection";
import { loadCollectionProps } from "../../services/collection";
import Loader from "../../components/dom/Loader";
import Pagination from "../../components/dom/Pagination";
import Select from "../../components/dom/Select";
import Button from "../../components/dom/Button";
import { closeModal, openModal } from "../../components/modal/Modal";
import {
  PAGE_DIM,
  datetimeToString,
  hotelCensusLocationTypeOptions,
  starsOptions,
  scrollToTopList,
} from "../../utils/generic";
import { AnyAaaaRecord } from "dns";
import { format } from "date-fns";
import ExcelJS from "exceljs";

interface HotelDetailsProps {
  collection: iCollection;
  callback: () => void;
}

interface iHotelDetails {
  name: string;
  location: string;
  locationType: hotelCensusLocationType;
  stars: string;
  website: string;
  email: string;
  firstName: string;
  secondName: string;
  hotelsComSlug: string;
  hotelsComId: string;
}

function HotelDetails({ collection, callback }: HotelDetailsProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [stats, setStats] = useState<hotelStats>({});
  const defaultLocationType: hotelCensusLocationType = "sea";

  const [hotelDetails, setHotelDetails] = useState<iHotelDetails>({
    name: collection.name || "",
    location: collection.census?.location?.label || "",
    locationType: collection.census?.locationType || defaultLocationType,
    stars: collection.census?.stars || "",
    website: collection.census?.website || "",
    email: collection.census?.email || "",
    firstName: collection.census?.firstName || "",
    secondName: collection.census?.secondName || "",
    hotelsComSlug: collection.census?.hotelsComSlug || "",
    hotelsComId: collection.census?.hotelsComId || "",
  });

  useEffect(() => {
    if (collection.censusStats) setStats({ ...collection.censusStats });
  }, [collection]);

  async function SaveHoteDetails() {
    collection.name = hotelDetails.name;

    if (!collection.census) {
      collection.census = {};
    }
    collection.census.location = collection.census.location || { label: "" };
    collection.census.location.label = hotelDetails.location;
    collection.census.locationType = hotelDetails.locationType;
    collection.census.stars = hotelDetails.stars;
    collection.census.website = hotelDetails.website;
    collection.census.email = hotelDetails.email;
    collection.census.firstName = hotelDetails.firstName;
    collection.census.secondName = hotelDetails.secondName;
    collection.census.hotelsComSlug = hotelDetails.hotelsComSlug;
    collection.census.hotelsComId = hotelDetails.hotelsComId;

    setIsSaving(true);
    try {
      const save: any = await patchCollection({
        ...collection,
        censusStats: stats,
        collectionId: collection._id,
      });
      setIsSaving(false);
      closeModal();
      callback();
      //loadCollections({ page, size, sort });
    } catch (error: any) {
      setIsSaving(false);
      alert(error.message);
    }
  }

  function capFirstLetter(text: any) {
    return text.toString().charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <div className="p-3">
      <h2>Hotel Details</h2>
      <form>
        {Object.entries(hotelDetails).map(
          ([key, value], index) =>
            index % 2 === 0 && (
              <div key={index} className="d-flex row gx-2">
                <div className="d-flex flex-column col-6">
                  <label>
                    {capFirstLetter(
                      key
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .toString()
                    )}
                  </label>
                  {key === "locationType" ? (
                    <Select
                      value={value}
                      defaultValue={""}
                      onChange={(e) => {
                        setHotelDetails((prevDetails) => ({
                          ...prevDetails,
                          [key]: e.target.value as any,
                        }));
                      }}
                      options={hotelCensusLocationTypeOptions.map((s) => {
                        return {
                          value: s.value ?? "",
                          text: s.text,
                          key: s.value,
                        };
                      })}
                    ></Select>
                  ) : (
                    <input
                      type="text"
                      value={value}
                      onChange={(e) => {
                        setHotelDetails((prevDetails) => ({
                          ...prevDetails,
                          [key]: e.target.value,
                        }));
                      }}
                    />
                  )}
                </div>
                <div className="d-flex flex-column col-6">
                  <label>
                    {Object.entries(hotelDetails)[index + 1]?.[1] !== undefined
                      ? capFirstLetter(
                          Object.entries(hotelDetails)
                            [index + 1]?.[0].replace(/([A-Z])/g, " $1")
                            .trim()
                        )
                      : ""}
                  </label>
                  {key === "locationType" ? (
                    <Select
                      className="mb-3"
                      value={Object.entries(hotelDetails)[index + 1]?.[1]}
                      defaultValue={""}
                      onChange={(e) => {
                        setHotelDetails((prevDetails) => ({
                          ...prevDetails,
                          [Object.entries(hotelDetails)[index + 1]?.[0]]:
                            e.target.value,
                        }));
                      }}
                      options={starsOptions.map((s) => {
                        return {
                          value: s.value,
                          text: s.text,
                          key: s.value,
                        };
                      })}
                    ></Select>
                  ) : (
                    <input
                      type="text"
                      value={Object.entries(hotelDetails)[index + 1]?.[1]}
                      onChange={(e) => {
                        setHotelDetails((prevDetails) => ({
                          ...prevDetails,
                          [Object.entries(hotelDetails)[index + 1]?.[0]]:
                            e.target.value,
                        }));
                      }}
                    />
                  )}
                </div>
              </div>
            )
        )}
        <Button
          text="Salva"
          loading={isSaving}
          onClick={() => SaveHoteDetails()}
        />
      </form>
    </div>
  );
}

export default function CollectionManager() {
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState<Array<iCollection>>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(1000);
  const [sort, setSort] = useState<any>({ nftCount: -1 });
  const elementRef = useRef<HTMLDivElement>(null);
  const loadCollections = async ({
    page = 1,
    query,
    size,
    sort,
  }: loadCollectionProps) => {
    setIsLoading(true);
    try {
      const data = await getCollections({
        page,
        query,
        size,
        sort,
      });

      setTotal(data.total || 0);

      setCollections(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (collectionPage: number) => {
    setPage(collectionPage);
    scrollToTopList(elementRef);
  };

  const refreshPage = () => {
    loadCollections({ page, size, sort });
  };

  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Collections");

    const headerRow = [
      "Name",
      "Location",
      "Location Type",
      "Stars",
      "Prezzo Medio",
      "Attivo",
      "TrialendDate",
      "First Name",
      "Second Name",
      "Hotels Slug",
      "Hotels Id",
      "Email",
      "Website",
    ];
    worksheet.addRow(headerRow);

    collections.map((collection, key) => {
      const row = [
        collection.name,
        collection.census?.location?.label,
        collection.census?.locationType,
        parseInt(collection.census?.stars || "0"),
        collection.censusStats?.averageBookingPrice,
        collection.isActive,
        collection?.trialEndDate
          ? format(new Date(collection.trialEndDate), "dd-MM-yyyy")
          : "",
        collection.census?.firstName,
        collection.census?.secondName,
        collection.census?.hotelsComSlug,
        collection.census?.hotelsComId,
        collection.census?.email,
        collection.census?.website,
      ];
      worksheet.addRow(row);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Collections.xlsx";
      link.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    loadCollections({ page, size, sort });
  }, [page, size, sort]);

  return (
    <main id="collectionManager">
      <div className="_container" style={{ padding: "0 10px" }}>
        {isLoading && (
          <div className="loaderFull">
            <div className="d-flex h-100 justify-content-center align-items-center">
              <Loader />
            </div>
          </div>
        )}

        <div className="mt-3">
          <Button
            className="float-end m-2"
            text="Esporta Excel"
            onClick={() => exportExcel()}
          />
        </div>
        <div className="mt-3" ref={elementRef}>
          <table
            className="table noHover table-striped"
            style={{ border: "2px solid black" }}
          >
            <tbody>
              {collections.map((collection, key) => {
                return (
                  <tr
                    key={"collection_" + key}
                    style={{ borderBottom: "1px solid" }}
                  >
                    <td colSpan={10}>
                      <tr style={{ width: "100%", display: "table" }}>
                        <tr>
                          <th style={{ width: "14%" }}>Name</th>
                          <th style={{ width: "14%" }}>Location</th>
                          <th style={{ width: "14%" }}>Location Type</th>
                          <th style={{ width: "14%" }}>Stars</th>
                          <th style={{ width: "14%" }}>Prezzo Medio</th>
                          <th style={{ width: "14%" }}>Attivo</th>
                          <th style={{ width: "14%" }}>Trial end Date</th>
                        </tr>
                        <tr>
                          <td className="pt-0">
                            <p
                              style={{
                                backgroundColor: "#8ce786",
                                borderRadius: "5px",
                                padding: "3px",
                              }}
                            >
                              {collection.name}
                            </p>
                          </td>
                          <td
                            className="pt-0"
                            style={{
                              maxWidth: "250px",
                              wordWrap: "break-word",
                            }}
                          >
                            {collection.census?.location?.label}
                          </td>
                          <td className="pt-0">
                            {collection.census?.locationType}
                          </td>
                          <td className="pt-0">
                            {Number(collection.census?.stars)}
                          </td>
                          <td className="pt-0">
                            {collection.censusStats?.averageBookingPrice}
                          </td>
                          <td className="pt-0">{collection.isActive}</td>
                          <td className="pt-0">
                            {collection?.trialEndDate
                              ? format(
                                  new Date(collection.trialEndDate),
                                  "dd-MM-yyyy"
                                )
                              : ""}
                          </td>
                        </tr>
                      </tr>
                      <tr
                        style={{
                          width: "100%",
                          display: "table",
                          borderTop: "1px solid lightgray",
                        }}
                      >
                        <tr>
                          <th style={{ width: "14%" }}>First Name</th>
                          <th style={{ width: "14%" }}>Second Name</th>
                          <th style={{ width: "14%" }}>Hotels Slug</th>
                          <th style={{ width: "14%" }}>Hotels Id</th>
                          <th style={{ width: "14%" }}>Email</th>
                          <th style={{ width: "14%" }}>Website</th>
                          <th style={{ width: "14%" }}></th>
                        </tr>
                        <tr>
                          <td className="pt-0">
                            {collection.census?.firstName}
                          </td>
                          <td className="pt-0">
                            {collection.census?.secondName}
                          </td>
                          <td className="pt-0">
                            {collection.census?.hotelsComSlug}
                          </td>
                          <td className="pt-0">
                            {collection.census?.hotelsComId}
                          </td>
                          <td className="pt-0">{collection.census?.email}</td>
                          <td
                            className="pt-0"
                            style={{
                              maxWidth: "250px",
                              wordWrap: "break-word",
                            }}
                          >
                            {collection.census?.website}
                          </td>
                        </tr>
                      </tr>
                    </td>
                    <td style={{ width: "30px" }}>
                      <Button
                        className="w-100"
                        text="Modifica"
                        onClick={() =>
                          openModal(
                            <HotelDetails
                              collection={collection}
                              callback={refreshPage}
                            />
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          size={size}
          total={total}
          onSizeChange={onSizeChange}
          onPageChange={onPageChange}
        />
      </div>
    </main>
  );
}
