import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  createUser,
  getUsersPaginated,
  giveCreditsToUser,
  iUser,
  loginAs,
} from "../../services/auth";
import { selectUser } from "../../store/userSlice";
import {
  PAGE_DIM,
  formToObject,
  makeid,
  scrollToTopList,
} from "../../utils/generic";
import { LANG } from "../../services/i18next";
import { closeModal, openModal } from "../../components/modal/Modal";
import Checkbox from "../../components/dom/Checkbox";
import Select from "../../components/dom/Select";
import Button from "../../components/dom/Button";
import Loader from "../../components/dom/Loader";
import Pagination from "../../components/dom/Pagination";

interface CreateUserFormProps {
  onSuccess: Function;
  user?: iUser;
}

function CreateUserForm({ onSuccess, user }: CreateUserFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [pass, setPass] = useState("");

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = formToObject(e.target);
      await createUser(data);
      onSuccess();
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <form onSubmit={submit} autoComplete="new-password" className="p-3">
      <label htmlFor="email">Email*</label>
      <input
        readOnly={user?.email ? true : false}
        defaultValue={user?.email}
        required
        type="email"
        name="email"
        id="email"
      />
      <label htmlFor="password">Password*</label>
      <div className="d-flex w100 gap-2">
        <input
          className="w100"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          required={user ? false : true}
          minLength={6}
          type="text"
          name="password"
          id="password"
        />
        <Button
          onClick={() => {
            setPass(makeid(8));
          }}
          text="Random Pass"
          small
        />
      </div>

      <label htmlFor="">First Name</label>
      <input defaultValue={user?.firstName} type="text" name="firstName" />
      <label htmlFor="">Second Name</label>
      <input defaultValue={user?.secondName} type="text" name="secondName" />

      <div className="d-flex justify-content-between">
        <div>
          <label htmlFor="">Email Verified</label>
          <Checkbox checked={user?.emailVerified} name="emailVerified" />
        </div>
        <div>
          <label htmlFor="">Marketing Emails</label>
          <Checkbox checked={user?.marketingEmails} name="marketingEmails" />
        </div>
        <div>
          <label htmlFor="">ADMIN</label>
          <Checkbox checked={user?.isAdmin} name="isAdmin" />
        </div>
        <div>
          <label htmlFor="">STEALTH</label>
          <Checkbox checked={user?.isStealth} name="isStealth" />
        </div>
      </div>

      <label htmlFor="">Lang</label>
      <Select
        defaultValue={user?.lang}
        options={Object.values(LANG).map((lang) => {
          return {
            text: lang,
            value: lang,
          };
        })}
        name="lang"
      />
      <br />

      <div className="d-flex gap-3">
        <div className="d-flex flex-column w100">
          <label htmlFor="">Stripe ID</label>
          <input className="w100" type="text" defaultValue={user?.stripeId} />
        </div>
        <div>
          <label htmlFor="">Verified</label>
          <Checkbox
            checked={user?.stripeAccountDetailSubmitted}
            name="stripeAccountDetailSubmitted"
          />
        </div>
      </div>

      <br />
      <Button type="submit" text={"save"} />
    </form>
  );
}

interface CreditsFormProps {
  userId: string;
}
function GiveCreditsForm({ userId }: CreditsFormProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await giveCreditsToUser(data);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={submit} className="p-3">
      <label htmlFor="">{t("dev.credits_amount")}</label>
      <input type="number" step={0.01} name="amount" />
      <input type="text" name="userId" defaultValue={userId} hidden />
      <Button
        loading={isLoading}
        type="submit"
        text={String(t("dev.give_credits"))}
      />
    </form>
  );
}

export default function Users() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [users, setUsers] = useState<iUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const elementRef = useRef<HTMLTableElement>(null);

  const loadUsers = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    setIsLoading(true);

    try {
      const data = await getUsersPaginated({ page, query, size });

      setTotal(data.total || 0);

      setUsers(data.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  useEffect(() => {
    if (!user.isAdmin) navigate("/");

    loadUsers();
    // eslint-disable-next-line
  }, [page, size]);

  if (isLoading) return <Loader />;

  return (
    <main>
      <div className="container">
        <div className="content">
          <br />
          <Button
            onClick={() => openModal(<CreateUserForm onSuccess={loadUsers} />)}
            text={"Create User"}
          />
          <hr />
          <form onSubmit={loadUsers}>
            <div className="d-flex gap-2">
              <input
                type="text"
                className="w100 m-0"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <div>
                <Button text="search" type="submit" />
              </div>
            </div>
          </form>
          <hr />
          {users.length ? (
            <>
              <table ref={elementRef}>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>EmailVerified</th>
                    <th>FirstName</th>
                    <th>SecondName</th>
                    <th>Phone</th>
                    <th>StripeId</th>
                    <th>StripeFlag</th>
                    <th>Lang</th>
                    <th>MarketingEmails</th>
                    {/* <th></th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, key) => {
                    return (
                      <tr key={"user_" + key}>
                        <td>{user.email}</td>
                        <td>{user.emailVerified ? "YES" : ""}</td>
                        <td>{user.firstName}</td>
                        <td>{user.secondName}</td>
                        <td>{user.phonePrefix + " " + user.phone}</td>
                        <td>{user.stripeId}</td>
                        <td>
                          {user.stripeAccountDetailSubmitted ? "YES" : ""}
                        </td>
                        <td>{user.lang}</td>
                        <td>{user.marketingEmails ? "YES" : ""}</td>
                        {/* <td>
                <Button
                  small
                  error
                  text={String(t("dev.delete"))}
                  onClick={() => {}}
                />
              </td> */}
                        <td>
                          <Button
                            onClick={() =>
                              openModal(
                                <CreateUserForm
                                  onSuccess={loadUsers}
                                  user={user}
                                />
                              )
                            }
                            small
                            text={String(t("dev.edit"))}
                          />
                          <Button
                            onClick={() =>
                              openModal(<GiveCreditsForm userId={user._id} />)
                            }
                            small
                            text={String(t("dev.give_credits"))}
                          />
                          <Button
                            onClick={async () => {
                              navigate("");
                              await loginAs({ email: user.email });
                              window.location.reload();
                            }}
                            small
                            text={String(t("dev.login_as"))}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <br />
              <hr />
              <br />
              <Pagination
                page={page}
                size={size}
                total={total}
                onSizeChange={onSizeChange}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <p>{t("pagination.no_show")}</p>
          )}
        </div>
      </div>
    </main>
  );
}
