import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { formToObject } from "../../../utils/generic";
import { editReport, iReport } from "../../../services/reports";
import { closeModal } from "../../../components/modal/Modal";
import Button from "../../../components/dom/Button";

interface Props {
  report: iReport;
  onSuccess?: Function;
}

export default function ReportEditModal({ report, onSuccess }: Props) {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const editSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      data.collectionreportId = report._id;

      await editReport(data);
      closeModal();
      onSuccess?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <span>{t("reports.edit_report")}</span>
      <form onSubmit={editSubmit} className="modal-form mt-3">
        <label htmlFor="nftsAmount">{t("reports.nfts_amount")}</label>
        <input
          type="number"
          name="nftsAmount"
          id="nftsAmount"
          required
          min="0"
          defaultValue={report.nftsAmount ?? 0}
        />

        <label htmlFor="nftsAmountOld">{t("reports.nfts_amount_old")}</label>
        <input
          type="number"
          name="nftsAmountOld"
          id="nftsAmountOld"
          required
          min="0"
          defaultValue={report.nftsAmountOld ?? 0}
        />

        <label htmlFor="volume">{t("reports.volume")}</label>
        <input
          type="number"
          name="volume"
          id="volume"
          required
          min="0"
          defaultValue={report.volume ?? 0}
        />

        <label htmlFor="volumeOld">{t("reports.volume_old")}</label>
        <input
          type="number"
          name="volumeOld"
          id="volumeOld"
          required
          min="0"
          defaultValue={report.volumeOld ?? 0}
        />

        <label htmlFor="numberOfResale">{t("reports.number_of_resale")}</label>
        <input
          type="number"
          name="numberOfResale"
          id="numberOfResale"
          required
          min="0"
          defaultValue={report.numberOfResale ?? 0}
        />

        <label htmlFor="numberOfResaleOld">
          {t("reports.number_of_resale_old")}
        </label>
        <input
          type="number"
          name="numberOfResaleOld"
          id="numberOfResaleOld"
          required
          min="0"
          defaultValue={report.numberOfResaleOld ?? 0}
        />

        <label htmlFor="numberOfSale">{t("reports.number_of_sale")}</label>
        <input
          type="number"
          name="numberOfSale"
          id="numberOfSale"
          required
          min="0"
          defaultValue={report.numberOfSale ?? 0}
        />

        <label htmlFor="numberOfSaleOld">
          {t("reports.number_of_sale_old")}
        </label>
        <input
          type="number"
          name="numberOfSaleOld"
          id="numberOfSaleOld"
          required
          min="0"
          defaultValue={report.numberOfSaleOld ?? 0}
        />

        <label htmlFor="traffic">{t("reports.traffic")}</label>
        <input
          type="number"
          name="traffic"
          id="traffic"
          required
          min="0"
          defaultValue={report.traffic ?? 0}
        />

        <label htmlFor="trafficOld">{t("reports.traffic_old")}</label>
        <input
          type="number"
          name="trafficOld"
          id="trafficOld"
          required
          min="0"
          defaultValue={report.trafficOld ?? 0}
        />

        <label htmlFor="averageBookingWindow">
          {t("reports.booking_window_avg")}
        </label>
        <input
          type="number"
          name="averageBookingWindow"
          id="averageBookingWindow"
          required
          min="0"
          defaultValue={report.averageBookingWindow ?? 0}
        />

        <label htmlFor="averageLenghtOfStay">
          {t("reports.lenght_stay_avg")}
        </label>
        <input
          type="number"
          name="averageLenghtOfStay"
          id="averageLenghtOfStay"
          required
          min="0"
          defaultValue={report.averageLenghtOfStay ?? 0}
        />

        <Button
          type="submit"
          text={String(t("reports.edit_report"))}
          loading={isLoading}
        />
      </form>
    </div>
  );
}
