import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../components/dom/Button";
import DropdownMenu from "../../../components/dom/DropdownMenu";
import { closeModal, openModal } from "../../../components/modal/Modal";
import {
  collectionMember,
  collectionRoles,
  COLLECTION_PERMISSIONS,
  iCollection,
  inviteMemberToCollection,
  refreshStoredCollection,
  removeMemberFromCollection,
  setCollectionPermissionsPerUser,
} from "../../../services/collection";
import { selectCollection } from "../../../store/collectionSlice";
import { selectUser } from "../../../store/userSlice";
import settingsSvg from "../../../assets/icons/settings.svg";
import { selectGroup } from "../../../store/groupSlice";
import { GROUP_PERMISSIONS, iGroup } from "../../../services/groups";
import usersSvg from "../../../assets/icons/users.svg";
import { formToObject } from "../../../utils/generic";

interface setRoleUserCollectionModalProps {
  email: string;
  collection: iCollection;
  isEmailValid?: boolean;
  inviteHandler?: (e: FormEvent<HTMLFormElement>) => void;
}

function SetRoleUserCollectionModal(props: setRoleUserCollectionModalProps) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [selected, setSelected] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);

  const roleFormModalSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);

      if (props.inviteHandler) {
        if (props.collection.members.find((e) => e.email === props.email)) {
          throw new Error(
            "This user already exists in this collection. You can only change the role from settings."
          );
        }
        props.inviteHandler(e);
      }

      await setCollectionPermissionsPerUser(data);

      await refreshStoredCollection(props.collection._id);

      closeModal();
    } catch (error: any) {
      console.log(error);
      if (
        error.message ===
        "This user already exists in this collection. You can only change the role from settings."
      ) {
        alert(error.message);
      } else if (
        String(error?.response?.data?.message).includes(
          "does not have Permission"
        )
      ) {
        alert("You can't give a higher role than yours to another member");
      }
    }
    setIsLoading(false);
  };

  return (
    <section id="role-user-group-modal">
      <span className="title">
        {t("collection.set_role_user")} {props.email}
      </span>
      <div className="roles">
        {collectionRoles.map((role, key) => {
          if (!user.isAdmin && role.onlyAdmin) return <></>;

          return (
            <div
              onClick={() => {
                setSelected(key);
              }}
              className={`role ${key === selected ? "selected" : ""}`}
              key={"role_modal_" + key}
            >
              <p className="m-0 name">{t("collection.role_" + role.name)}</p>
              <p className="m-0 description">
                {t("collection.role_description_" + role.name)}
              </p>
              <p className="m-0 text-muted">
                {/* <i>{JSON.stringify(role.permissions)}</i> */}
              </p>
            </div>
          );
        })}
      </div>
      <form onSubmit={roleFormModalSubmit}>
        <input
          type="text"
          name="collectionId"
          required
          defaultValue={props.collection._id}
          hidden
        />
        <input
          type="email"
          name="email"
          required
          defaultValue={props.email}
          hidden
        />
        {collectionRoles[selected]?.permissions.map((perm, key) => {
          return (
            <input
              hidden
              key={key}
              type="text"
              name="permissions[]"
              defaultValue={perm}
            />
          );
        })}
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("collection.cancel"))}
            disabled={isLoading}
          />
          <Button
            loading={isLoading}
            disabled={
              selected < 0 ||
              (props.isEmailValid !== undefined && props.isEmailValid === false)
            }
            type="submit"
            className="confirm"
            text={String(t("collection.confirm_role_button"))}
          />
        </div>
      </form>
    </section>
  );
}

interface InviteUserCollectionModalProps {
  collection: iCollection;
}

function InviteUserCollectionModal({
  collection,
}: InviteUserCollectionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const inviteUserToCollectionSubmit = async (
    e: FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await inviteMemberToCollection(data);
      await refreshStoredCollection(collection._id);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <section id="invite-user-group-modal">
      <div className="head">
        <img src={usersSvg} alt="users icon" />
        <div className="title">
          {t("collection.invite_user_text")} {collection.name}
        </div>
      </div>
      <div className="body">
        <form>
          <label htmlFor="invite-user-collection-form">
            {t("collection.invite_user")}
          </label>
          <div className="line">
            <input
              type="email"
              required
              id="invite-user-collection-form"
              name="email"
              placeholder="lorem.ipsum@email.com"
              onChange={(e) => {
                setEmail(e.target.value);
                setIsEmailValid(e.target.checkValidity());
              }}
            />
          </div>
          <input
            type="text"
            hidden
            required
            name="collectionId"
            defaultValue={collection._id}
          />
        </form>
      </div>
      <SetRoleUserCollectionModal
        email={email}
        collection={collection}
        isEmailValid={isEmailValid}
        inviteHandler={inviteUserToCollectionSubmit}
      />
    </section>
  );
}

export default function CollectionMembers() {
  const user = useSelector(selectUser);
  const collection = useSelector(selectCollection);
  const group = useSelector(selectGroup);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const canHandleMembers = (group: iGroup, collection: iCollection) => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollPerms = collection.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollPerms?.includes(COLLECTION_PERMISSIONS.memebers)
    )
      return true;

    return false;
  };

  const removeUserCollectionSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await removeMemberFromCollection(data);
      await refreshStoredCollection(collection._id);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const removeUserConfirmCollectionModal = (email: string) => {
    return (
      <section id="remove-user-group-modal">
        <span className="title">
          {t("collection.remove_user_confirm")} {email}
        </span>
        <form onSubmit={removeUserCollectionSubmit}>
          <input
            type="text"
            required
            hidden
            name="collectionId"
            defaultValue={collection._id}
          />
          <input
            type="text"
            required
            hidden
            name="email"
            defaultValue={email}
          />
          <div className="buttons">
            <Button
              onClick={() => closeModal()}
              className="cancel"
              light
              text={String(t("collection.cancel"))}
            />
            <Button
              type="submit"
              className="confirm"
              error
              text={String(t("collection.confirm_remove_button"))}
            />
          </div>
        </form>
      </section>
    );
  };

  const getRole = (member: collectionMember) => {
    if (member.permissions.includes(COLLECTION_PERMISSIONS.admin))
      return { name: "admin", onlyAdmin: false, permissions: [] };

    let perms = [...member.permissions];

    const role = collectionRoles.find(
      (e) => e.permissions.sort().join(",") === perms.sort().join(",")
    );

    return role;
  };

  if (!collection._id) return <></>;
  if (!group._id) return <></>;

  return (
    <section className="collection-sub-page">
      <div className="d-flex mb-3">
        <span className="title">{t("collection.memebers")}</span>
        {canHandleMembers(group, collection) ? (
          <Button
            onClick={() =>
              openModal(<InviteUserCollectionModal collection={collection} />)
            }
            small
            className="ml-auto"
            text={String(t("collection.add_memeber"))}
          />
        ) : null}
      </div>
      <div className="members">
        {collection.members.map((member, key) => {
          if (
            !user.isAdmin &&
            getRole(member)?.permissions.includes(
              COLLECTION_PERMISSIONS.stealth
            )
          )
            return <></>;

          return (
            <div key={key} className="member">
              {(canHandleMembers(group, collection) &&
                member.email !== user.email) ||
              user.isAdmin ? (
                <div className="settings">
                  <DropdownMenu
                    position="down-left"
                    options={[
                      {
                        text: t("collection.permissions"),
                        action: () =>
                          openModal(
                            <SetRoleUserCollectionModal
                              collection={collection}
                              email={member.email}
                            />
                          ),
                      },
                      {
                        text: t("collection.remove_user"),
                        red: true,
                        action: () =>
                          openModal(
                            removeUserConfirmCollectionModal(member.email)
                          ),
                      },
                    ]}
                    small
                    hovered
                    icon={settingsSvg}
                  />
                </div>
              ) : null}
              <div>
                <span>{member.email}</span>
                {!member.isInviteAccepted ? (
                  <>
                    <span> - </span>
                    <span className="invite-pending">
                      {t("collection.invite_pending")}
                    </span>
                  </>
                ) : null}
              </div>
              <span className="perms text-muted">
                {/* <i>{JSON.stringify(member.permissions)}</i> */}
                <i>{t("collection.role_" + getRole(member)?.name)}</i>
              </span>
            </div>
          );
        })}
      </div>
    </section>
  );
}
