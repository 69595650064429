import { iCollection } from './../services/collection';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../services/redux";

interface CollectionState {
  data: any;
}

const initialState: CollectionState = {
  data: {},
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState: initialState,
  reducers: {
    setCollection: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { setCollection } = collectionSlice.actions;
export const selectCollection = (state: RootState) =>
  state.collection.data as iCollection;

export default collectionSlice.reducer;
