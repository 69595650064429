import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import closeSvg from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import Button from "../dom/Button";
import { selectCollection } from "../../store/collectionSlice";
import {
  createNft,
  getNft,
  iNft,
  patchNft,
  payloadHotelType,
} from "../../services/nft";
import { refreshStoredCollection } from "../../services/collection";
import MapsAutocomplete from "../mapsautocomplete/MapsAutocomplete";
import CustomDatePicker from "../dom/CustomDatePicker";
import {
  compressImage,
  dateToGMT,
  isEmail,
  starsOptions,
} from "../../utils/generic";
import Select from "../dom/Select";
import { LANG } from "../../services/i18next";
import { useNavigate, useParams } from "react-router-dom";
import { selectUser } from "../../store/userSlice";

export default function CreateNft() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const totalSteps = 3;
  const [isEditMode, setIsEditMode] = useState(false);
  const [roomNamesSuggestion, setRoomNamesSuggestion] = useState<string[]>([]);

  const voidNft = { payload: { rooms: [{}] }, guests: [{}], images: [] };
  const [nft, setNft] = useState<iNft<payloadHotelType>>(voidNft);

  const navigate = useNavigate();
  const { nftId } = useParams();

  const collection = useSelector(selectCollection);

  const user = useSelector(selectUser);

  const loadNft = async (id: string) => {
    setIsLoading(true);
    try {
      let data = voidNft;
      const myNft = await getNft(id);
      setNft({ ...data, ...myNft });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsOpen(true);
    setStep(0);

    if (nftId) {
      //edit mode
      setIsEditMode(true);
      loadNft(nftId);
    } else {
      // new mode
      setNft(voidNft);
      setNft((nft) => {
        if (!collection.census) return { ...nft };

        nft.payload!.hotel = collection.census.name;
        nft.payload!.stars = collection.census.stars;
        nft.payload!.email = collection.census.email;
        nft.payload!.website = collection.census.website;
        nft.payload!.location = collection.census.location;
        nft.payload!.note = collection.census.note;

        if (collection.images) nft.images = [...collection.images];

        return { ...nft };
      });
    }
  }, []);

  const saveNftClick = async () => {
    setIsLoading(true);
    try {
      if (isEditMode) await patchNft(nft);
      else await createNft({ ...nft, collectionId: collection._id });
      await refreshStoredCollection(collection._id);
      navigate("/collection/" + collection._id);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const imagesUploadInput = (e: any) => {
    const images = Array.from(e.target.files);

    images.forEach(async (image: any) => {
      const readAsync = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage);
          }
        };
      });

      setNft((nft) => {
        if (nft.images!.length >= 10) return { ...nft };
        nft.images!.push(readAsync as string);
        return { ...nft };
      });
    });
  };

  useEffect(() => {
    const names: string[] = [];

    if (!collection.census?.info) return;

    const langs = Object.keys(collection.census.info);

    langs.forEach((lang) => {
      const rooms = collection.census?.info?.[lang as LANG]?.rooms;
      if (!rooms?.length) return;

      const roomNames = rooms.map((room) => room.name);

      names.push(...roomNames);
    });

    setRoomNamesSuggestion(names);
  }, [collection, nft]);

  // const step0 = (
  //   <section>
  //     <span className="title">{t("nft.base_info")}</span>
  //     <p>{t("nft.base_info_text")}</p>

  //     <div className="d-flex gap-3">
  //       <div className="d-flex flex-column">
  //         <label htmlFor="nft-form-name">{t("nft.census_name")}</label>
  //         <input
  //           disabled={isLoading}
  //           onChange={(e) =>
  //             setNft((nft) => {
  //               nft.payload!.hotel = e.target.value;
  //               return { ...nft };
  //             })
  //           }
  //           defaultValue={nft.payload?.hotel}
  //           type="text"
  //           id="nft-form-name"
  //           name="name"
  //         />
  //       </div>
  //       <div className="d-flex flex-column">
  //         <label htmlFor="nft-form-stars">{t("nft.census_stars")}</label>
  //         <Select
  //           disabled={isLoading}
  //           onChange={(e) =>
  //             setNft((nft) => {
  //               nft.payload!.stars = e.target.value;
  //               return { ...nft };
  //             })
  //           }
  //           value={nft.payload?.stars}
  //           id="nft-form-stars"
  //           name="stars"
  //           options={starsOptions.map((s) => {
  //             return {
  //               value: s.value,
  //               text: s.text,
  //               key: s.value,
  //             };
  //           })}
  //         />
  //       </div>
  //     </div>
  //     <div className="d-flex gap-3">
  //       <div className="d-flex flex-column">
  //         <label htmlFor="nft-form-email">{t("nft.census_email")}</label>
  //         <input
  //           disabled={isLoading}
  //           onChange={(e) =>
  //             setNft((nft) => {
  //               nft.payload!.email = e.target.value;
  //               return { ...nft };
  //             })
  //           }
  //           defaultValue={nft.payload?.email}
  //           type="text"
  //           id="nft-form-email"
  //           name="email"
  //         />
  //       </div>
  //       <div className="d-flex flex-column">
  //         <label htmlFor="nft-form-website">{t("nft.census_website")}</label>
  //         <input
  //           disabled={isLoading}
  //           onChange={(e) =>
  //             setNft((nft) => {
  //               nft.payload!.website = e.target.value;
  //               return { ...nft };
  //             })
  //           }
  //           defaultValue={nft.payload?.website}
  //           type="text"
  //           id="nft-form-website"
  //           name="website"
  //         />
  //       </div>
  //     </div>
  //     <div>
  //       <label htmlFor="">{t("nft.census_location")}</label>
  //       <MapsAutocomplete
  //         onSelect={(e: any) =>
  //           setNft((nft) => {
  //             nft.payload!.location = e;
  //             return { ...nft };
  //           })
  //         }
  //         defaultValue={nft.payload?.location}
  //       />
  //     </div>
  //     <div className="d-flex flex-column">
  //       <label htmlFor="nft-form-note">{t("nft.census_note")}</label>
  //       <div>
  //         <textarea
  //           disabled={isLoading}
  //           onChange={(e) =>
  //             setNft((nft) => {
  //               nft.payload!.note = e.target.value;
  //               return { ...nft };
  //             })
  //           }
  //           defaultValue={nft.payload?.note}
  //           id="nft-form-note"
  //           cols={50}
  //           name="note"
  //         ></textarea>
  //       </div>
  //     </div>

  //     <Button
  //       loading={isLoading}
  //       className="mt-5"
  //       text={String(t("nft.continue"))}
  //       onClick={() => setStep(step + 1)}
  //     />
  //   </section>
  // );

  const step1 = (
    <section>
      <span className="title">{t("nft.booking_info")}</span>
      <p>{t("nft.booking_info_text")}</p>

      {user?.isAdmin ? (
        <div className="d-flex gap-3">
          <div className="d-flex flex-column">
            <label htmlFor="nft-form-createdAt">{t("nft.createdAt")}</label>
            <CustomDatePicker
              onSelect={(e: string) =>
                setNft((nft) => {
                  nft._createdAt = e;

                  return { ...nft };
                })
              }
              id="nft-form-createdAt"
              name="_createdAt"
              defaultValue={nft._createdAt}
            />
          </div>
        </div>
      ) : null}

      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <label htmlFor="nft-form-reference">{t("nft.reference")}</label>
          <input
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.reference = e.target.value;
                return { ...nft };
              })
            }
            type="text"
            id="nft-form-reference"
            defaultValue={nft.payload?.reference}
            name="reference"
          />
        </div>
      </div>

      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <label htmlFor="nft-form-price">{t("nft.price")} (€)</label>
          <input
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.originalPrice = Number(e.target.value);
                return { ...nft };
              })
            }
            type="number"
            min={0}
            step={0.01}
            id="nft-form-price"
            value={nft.originalPrice ?? 0}
            name="price"
            onClick={(e) => (e.target as any).select()}
          />
        </div>

        <div className="d-flex flex-column">
          <label htmlFor="nft-form-board">{t("nft.board")}</label>

          <Select
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.board = e.target.value;
                return { ...nft };
              })
            }
            id="nft-form-board"
            defaultValue={nft.payload?.board}
            name="board"
            options={[
              { value: "", text: "" },
              { value: "RO", text: "RO" },
              { value: "BNB", text: "BNB" },
              { value: "HB", text: "HB" },
              { value: "FB", text: "FB" },
              { value: "AI", text: "AI" },
            ]}
          />
        </div>
      </div>

      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <label htmlFor="nft-form-checkin">
            {t("nft.checkin")} ({t("nft.required")})
          </label>
          <CustomDatePicker
            defaultValue={nft.payload?.checkin}
            onSelect={(e) => {
              setNft((nft) => {
                if (e) {
                  nft.payload!.checkin = dateToGMT(
                    e,
                    collection.census?.location?.gmt
                  ).toISOString();

                  const lockDate = new Date(e);
                  lockDate.setDate(lockDate.getDate() - 1);
                  lockDate.setHours(23, 59, 0, 0);
                  nft.lockDate = dateToGMT(
                    lockDate,
                    collection.census?.location?.gmt
                  ).toISOString();

                  const dayAfter = new Date(e);
                  dayAfter.setDate(dayAfter.getDate() + 1);

                  if (!nft.payload!.checkout) {
                    nft.payload!.checkout = dayAfter.toISOString();
                  }

                  nft.payload!.checkout = dayAfter.toISOString();
                }

                return { ...nft };
              });
            }}
            id="nft-form-checkin"
            name="checkin"
            // minDate={today}
          />
        </div>

        <div className="d-flex flex-column">
          <label htmlFor="nft-form-checkout">
            {t("nft.checkout")} ({t("nft.required")})
          </label>
          <CustomDatePicker
            onSelect={(e: string) =>
              setNft((nft) => {
                if (e) {
                  nft.payload!.checkout = dateToGMT(
                    e,
                    collection.census?.location?.gmt
                  ).toISOString();
                }
                return { ...nft };
              })
            }
            id="nft-form-checkout"
            name="checkout"
            defaultValue={nft.payload?.checkout}
            minDate={nft.payload?.checkin ? nft.payload?.checkin : undefined}
          />
        </div>
      </div>

      <div className="d-flex flex-column">
        <label htmlFor="nft-form-extra">{t("nft.extra")}</label>
        <div>
          <textarea
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.extra = e.target.value;
                return { ...nft };
              })
            }
            cols={50}
            id="nft-form-extra"
            defaultValue={nft.payload?.extra}
            name="extra"
          ></textarea>
        </div>
      </div>

      <Button
        onClick={() =>
          setNft((nft) => {
            nft.payload?.rooms?.push({});
            return { ...nft };
          })
        }
        small
        text={String(t("nft.add_room"))}
      />

      <div className="mt-3">
        {nft.payload?.rooms?.map((room, key) => {
          return (
            <div key={"room_" + Math.random().toString()}>
              <div className="d-flex gap-1">
                <div
                  onClick={() => {
                    setNft((nft) => {
                      nft.payload?.rooms?.splice(key, 1);
                      return { ...nft };
                    });
                  }}
                  className="delete-guest"
                >
                  <span>x</span>
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="">{t("nft.room_name")}</label>
                  <input
                    list="rooms-data"
                    disabled={isLoading}
                    onChange={(e) =>
                      setNft((nft) => {
                        nft.payload!.rooms![key].name = e.target.value;
                        return nft;
                      })
                    }
                    defaultValue={nft.payload!.rooms![key].name}
                    type="text"
                  />

                  {collection.census?.info ? (
                    <datalist id="rooms-data">
                      {roomNamesSuggestion.map((name) => {
                        return <option value={name} />;
                      })}
                    </datalist>
                  ) : null}
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="">{t("nft.room_guests_adults")}</label>
                  <input
                    disabled={isLoading}
                    onChange={(e) =>
                      setNft((nft) => {
                        nft.payload!.rooms![key].guestsAdults = Number(
                          e.target.value
                        );
                        return nft;
                      })
                    }
                    defaultValue={nft.payload!.rooms![key].guestsAdults}
                    type="number"
                    min={0}
                    step={1}
                  />
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="">{t("nft.room_guests_kids")}</label>
                  <input
                    disabled={isLoading}
                    onChange={(e) =>
                      setNft((nft) => {
                        nft.payload!.rooms![key].guestsKids = Number(
                          e.target.value
                        );
                        return nft;
                      })
                    }
                    defaultValue={nft.payload!.rooms![key].guestsKids}
                    type="number"
                    min={0}
                    step={1}
                  />
                </div>

                <div className="d-flex flex-column">
                  <label htmlFor="">{t("nft.room_amenities")}</label>
                  <input
                    disabled={isLoading}
                    onChange={(e) =>
                      setNft((nft) => {
                        nft.payload!.rooms![key].amenities = e.target.value;
                        return nft;
                      })
                    }
                    defaultValue={nft.payload!.rooms![key].amenities}
                    type="text"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-5 buttons">
        {/* <span className="previous" onClick={() => setStep(step - 1)}>
          {t("nft.previous")}
        </span> */}
        <span></span>
        <Button
          disabled={
            nft.payload?.type === "voucher"
              ? false
              : !nft.payload?.checkin ||
                !nft.payload?.checkout ||
                new Date(nft.payload.checkin) > new Date(nft.payload.checkout)
          }
          text={String(t("nft.continue"))}
          onClick={() => setStep(step + 1)}
          loading={isLoading}
        />
      </div>
    </section>
  );

  const step2 = (
    <section>
      <span className="title">{t("nft.booking_guests")}</span>
      <p>{t("nft.booking_guests_text")}</p>

      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <label htmlFor="nft-form-owner">
            {t("nft.owner")} ({t("nft.required")})
          </label>
          <input
            disabled={isLoading}
            defaultValue={nft.owner}
            placeholder="jack.huston@gmail.com"
            type="email"
            onChange={(e) =>
              setNft((nft) => {
                nft.owner = e.target.value;
                return { ...nft };
              })
            }
          />
        </div>
        <div className="d-flex flex-column">
          <label htmlFor="nft-form-owner">{t("nft.lang")}</label>
          <Select
            defaultValue={nft.payload?.stars}
            onChange={(e) => {
              setNft((nft) => {
                nft.lang = e.target.value;
                return { ...nft };
              });
            }}
            options={Object.keys(LANG).map((lang) => {
              return {
                text: lang,
                value: lang,
              };
            })}
          />
        </div>
      </div>

      <Button
        onClick={() =>
          setNft((nft) => {
            nft.guests?.push({});
            return { ...nft };
          })
        }
        small
        text={String(t("nft.add_guests"))}
      />

      <div className="mt-3">
        {nft.guests?.map((guest, key) => {
          return (
            <div key={"guest_" + Math.random().toString()}>
              <div className="d-flex gap-1">
                <div
                  onClick={() => {
                    setNft((nft) => {
                      nft.guests?.splice(key, 1);
                      return { ...nft };
                    });
                  }}
                  className="delete-guest"
                >
                  <span>x</span>
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="">{t("nft.guests_fitst_name")}</label>
                  <input
                    disabled={isLoading}
                    onChange={(e) =>
                      setNft((nft) => {
                        nft.guests![key].firstName = e.target.value;
                        return nft;
                      })
                    }
                    defaultValue={nft.guests![key].firstName}
                    type="text"
                  />
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="">{t("nft.guests_second_name")}</label>
                  <input
                    disabled={isLoading}
                    onChange={(e) =>
                      setNft((nft) => {
                        nft.guests![key].secondName = e.target.value;
                        return nft;
                      })
                    }
                    defaultValue={nft.guests![key].secondName}
                    type="text"
                  />
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="">{t("nft.guests_email")}</label>
                  <input
                    disabled={isLoading}
                    onChange={(e) =>
                      setNft((nft) => {
                        nft.guests![key].email = e.target.value;
                        return nft;
                      })
                    }
                    defaultValue={nft.guests![key].email}
                    type="email"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-5 buttons">
        <span className="previous" onClick={() => setStep(step - 1)}>
          {t("nft.previous")}
        </span>
        <Button
          text={String(t("nft.continue"))}
          onClick={() => setStep(step + 1)}
          disabled={!isEmail(String(nft.owner))}
        />
      </div>
    </section>
  );

  const step3 = (
    <section>
      <span className="title">{t("nft.photos")}</span>
      <p>{t("nft.photos_text")}</p>

      <input
        disabled={isLoading}
        onChange={imagesUploadInput}
        type="file"
        id="form-images"
        accept="image/png, image/jpeg"
        multiple
        hidden
      />
      <Button
        metadata={`${nft.images!.length}/10`}
        text={String(t("collection.upload_images"))}
        onClick={() => {
          document.getElementById("form-images")?.click();
        }}
      />

      <div className="img-preview-container mt-2">
        {nft.images!.map((i, key) => {
          return (
            <div
              key={"img_" + Math.random().toString()}
              className="img-preview"
              onClick={() => {
                setNft((nft) => {
                  const selected = nft.images![key];
                  nft.images!.splice(key, 1);
                  nft.images! = [selected, ...nft.images!];
                  return { ...nft };
                });
              }}
            >
              <input
                disabled={isLoading}
                readOnly
                type="text"
                hidden
                value={i}
                name="images[]"
              />
              <div
                onClick={() => {
                  setNft((nft) => {
                    nft.images!.splice(key, 1);
                    return { ...nft };
                  });
                }}
                className="delete cursor-pointer"
              >
                <span className="m-0">x</span>
              </div>
              <div
                style={{
                  backgroundImage: `url(${i})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
                className="image"
              ></div>
            </div>
          );
        })}
      </div>

      <div className="mt-5 buttons">
        <span className="previous" onClick={() => setStep(step - 1)}>
          {t("nft.previous")}
        </span>
        <Button
          loading={isLoading}
          text={String(t("nft.save"))}
          onClick={() => saveNftClick()}
        />
      </div>
    </section>
  );

  return (
    <section className={`${isOpen ? "opened" : "closed"}`} id="create-nft">
      <div className="header">
        <span
          className="close"
          onClick={() => navigate("/collection/" + collection._id)}
        >
          <img src={closeSvg} alt="close icon" />
        </span>
        <span className="title">
          {t("nft.create_title")} ({t("nft.create_step")} {step + 1}{" "}
          {t("nft.of")} {totalSteps})
        </span>
      </div>

      <form>
        <div className="steps">
          {/* {step === 0 ? step0 : null} */}
          {step === 0 ? step1 : null}
          {step === 1 ? step2 : null}
          {step === 2 ? step3 : null}
        </div>
      </form>
    </section>
  );
}
