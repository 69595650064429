import axios from "axios";
import store from "./redux";
import { LANG } from "./i18next";

import { setUser } from "../store/userSlice";
import { Pagination } from "../utils/Pagination";

const TOKEN_KEY = "34C657VB8NM89NP7OB6" + process.env.REACT_APP_FRONTEND_URI;

export enum USER_TYPE {
  DEFAULT = "DEFAULT",
  COMPANY = "COMPANY",
  ADMIN = "ADMIN",
}

export interface iUser {
  _id: string;

  isAdmin: boolean;

  email: string;
  password: string;
  emailVerified: boolean;

  phone?: string;
  phonePrefix?: string;

  firstName: string;
  secondName: string;

  wallet: Array<string>;

  stripeId: string;
  stripeAccountDetailSubmitted: boolean;

  lang: LANG;

  marketingEmails: boolean;
  isStealth: boolean;

  platformPolicy: boolean;
  TDHid?: string;

  _createdAt: any;
  _updatedAt: any;
}

export interface iPromoCode {
  _id: string;

  code: string;
  amount: number;
  expiresAt: Date;

  _createdAt: any;
}

export async function register(payload: any) {
  const response = await axios.post("/auth/register", payload);
  return response.data;
}

export async function login(payload: any, social = false): Promise<iUser> {
  let response;
  if (social) {
    response = await axios.post("/auth/social-login", payload);
  } else {
    response = await axios.post("/auth/login", payload);
  }

  const token = response.data?.token;
  // eslint-disable-next-line
  if (!token) throw { ...response.data };

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  window.localStorage.setItem(TOKEN_KEY, token);

  store.dispatch(setUser(response.data));

  return response.data;
}

export async function loginWithToken(token: string) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  window.localStorage.setItem(TOKEN_KEY, token);

  await refreshUserInfo();
}

export async function loginAs(payload: any) {
  const response = await axios.post("/users/login-as", payload);

  const token = response.data?.token;
  // eslint-disable-next-line
  if (!token) throw { ...response.data };

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  window.localStorage.setItem(TOKEN_KEY, token);

  store.dispatch(setUser(response.data));

  return response.data;
}

export async function refreshUserInfo() {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) throw new Error("Token not found");

  const response = await axios.get("/auth");

  store.dispatch(setUser(response.data));

  console.log(response.data); // TODO REMOVE

  return response.data;
}

export async function sendVerificaitonEmail(payload: any) {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) throw new Error("Token not found");

  const response = await axios.post(
    "/auth/request-verification-email",
    payload
  );

  return response.data;
}

export async function sendVerificationEmailByCode(payload: {
  redirect: string;
}) {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) throw new Error("Token not found");

  const response = await axios.post(
    "/auth/request-verification-email-by-code",
    payload
  );

  return response.data;
}

export function isLoggedIn() {
  try {
    return window.localStorage.getItem(TOKEN_KEY) ? true : false;
  } catch (error) {
    return false;
  }
}

export async function logout() {
  axios.defaults.headers.common["Authorization"] = "";

  store.dispatch(setUser({}));

  window.localStorage.removeItem(TOKEN_KEY);
}

export async function verifyEmailByCode(payload: {
  redirect: string;
  code: string;
}) {
  const response = await axios.post(
    "/auth/verification-email-by-code",
    payload
  );

  return response.data;
}

export function initializeLoggedUser() {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    refreshUserInfo();
  }
}

export async function sendRecoveryPasswordEmail(payload: any) {
  const response = await axios.post("/auth/request-reset-password", payload);

  return response.data;
}

export async function resetPassword(payload: any) {
  const response = await axios.post("/auth/reset-password", payload);

  return response.data;
}

export async function patchUser(payload: any): Promise<iUser> {
  const response = await axios.patch("/auth", payload);

  store.dispatch(setUser(response.data));

  return response.data;
}

export async function getUserProfile(id: string): Promise<iUser> {
  const response = await axios.get("/auth/" + id);
  return response.data;
}

export interface loadUsersProps {
  page?: number;
  size?: number;
  query?: string;
}

export async function getUsersPaginated({
  page = 1,
  size = 25,
  query = "",
}): Promise<Pagination<iUser>> {
  const response = await axios.get(`/users/paginate`, {
    params: {
      page,
      size,
      query,
    },
  });
  return response.data;
}

export async function createUser(payload: any): Promise<iUser> {
  const response = await axios.post("/users", payload);
  return response.data;
}

export async function giveCreditsToUser(payload: any) {
  const response = await axios.post("/credits", payload);
  return response.data;
}

export async function createPromoCode(payload: any) {
  const response = await axios.post("/promocode", payload);
  return response.data;
}

export async function getCodesPaginated(): Promise<iPromoCode[]> {
  const response = await axios.get("/promocode/paginate");
  return response.data;
}

export async function deletePromoCode(id: string) {
  const response = await axios.delete("/promocode", {
    data: { promoCodeId: id },
  });

  return response.data;
}
