import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/dom/Button";
import { getReports, iReport } from "../../services/reports";
import { iCollection } from "../../services/collection";
import { datetimeToString } from "../../utils/generic";
import { openModal } from "../../components/modal/Modal";
import ReportEditModal from "./Sub/ReportEditModal";
import ReportSendModal from "./Sub/ReportSendModal";
import ReportCreateModal from "./Sub/ReportCreateModal";
import Loader from "../../components/dom/Loader";

interface Props {
  collection: iCollection;
  onExit?: Function;
}

export default function ReportsPage({ collection, onExit }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState<iReport[]>([]);

  const loadReports = async () => {
    setIsLoading(true);
    try {
      const data = await getReports(collection._id);
      setReports(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadReports();
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      <div className="container">
        <div className="content">
          <br />
          <div>
            <Button
              onClick={() => onExit?.()}
              small
              text={String(t("payment.back"))}
            />
          </div>
          <br />
          <Button
            onClick={() =>
              openModal(
                <ReportCreateModal
                  collection={collection}
                  onSuccess={() => loadReports()}
                />
              )
            }
            text={String(t("reports.create_report"))}
          />

          <br />

          {isLoading && <Loader />}

          {!reports.length && !isLoading ? (
            t("reports.no_show")
          ) : (
            <div className="d-flex flex-md-row flex-column flex-wrap gap-3 cursor-pointer">
              {reports.map((report, key) => {
                return (
                  <div
                    key={key}
                    onClick={() =>
                      openModal(
                        <ReportEditModal
                          report={report}
                          onSuccess={() => loadReports()}
                        />
                      )
                    }
                    style={{ background: "white" }}
                  >
                    <div
                      className="d-flex flex-column flex-row border rounded p-3 gap-3"
                      style={{ width: "400px" }}
                    >
                      <div className="d-flex gap-4">
                        <div className="d-flex flex-column">
                          <span className="fw-bold">
                            {t("reports.status")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.sent_at")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.start_date")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.end_date")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.start_date_old")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.end_date_old")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.nfts_amount")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.nfts_amount_old")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.volume")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.volume_old")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.number_of_resale")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.number_of_resale_old")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.number_of_sale")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.number_of_sale_old")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.traffic")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.traffic_old")}:{" "}
                          </span>
                          <span className="fw-bold">
                            {t("reports.nfts_export")}:{" "}
                          </span>
                        </div>
                        <div className="d-flex flex-column">
                          <span>
                            {report.sent
                              ? t("reports.sent")
                              : t("reports.not_sent")}
                          </span>
                          <span>
                            {report.sent
                              ? datetimeToString(report.sentAt, undefined, true)
                              : "-"}
                          </span>
                          <span>
                            {datetimeToString(
                              report.startDate,
                              undefined,
                              true
                            )}
                          </span>
                          <span>
                            {datetimeToString(report.endDate, undefined, true)}
                          </span>
                          <span>
                            {datetimeToString(
                              report.startDateOld,
                              undefined,
                              true
                            )}
                          </span>
                          <span>
                            {datetimeToString(
                              report.endDateOld,
                              undefined,
                              true
                            )}
                          </span>
                          <span>{report.nftsAmount}</span>
                          <span>{report.nftsAmountOld}</span>
                          <span>{report.volume}</span>
                          <span>{report.volumeOld}</span>
                          <span>{report.numberOfResale}</span>
                          <span>{report.numberOfResaleOld}</span>
                          <span>{report.numberOfSale}</span>
                          <span>{report.numberOfSaleOld}</span>
                          <span>{report.traffic}</span>
                          <span>{report.trafficOld}</span>
                          <span>
                            {report.nftsExport.map((exportItem) =>
                              datetimeToString(exportItem, undefined, true)
                            )}
                          </span>
                        </div>
                      </div>

                      <Button
                        onClick={(e: any) => {
                          e.stopPropagation();
                          openModal(
                            <ReportSendModal
                              reportId={report._id}
                              onSuccess={() => loadReports()}
                            />
                          );
                        }}
                        text={String(t("reports.send_report"))}
                        small
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
