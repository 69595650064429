import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  acceptInviteCollection,
  declineInviteCollection,
  iCollection,
} from "../../services/collection";
import { selectUser } from "../../store/userSlice";
import Button from "../dom/Button";
import { datetimeToString } from "../../utils/generic";

interface Props {
  collection: iCollection;
  onUpdate?: Function;
}

export default function CollectionCard({ collection, onUpdate }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const [subStatus, setSubStatus] = useState("");

  const isInvited = (email: string, collection: iCollection): boolean => {
    return collection.members.some(
      (e) => e.email === email && !e.isInviteAccepted
    );
  };

  const acceptInvite = async () => {
    setIsLoading(true);
    try {
      await acceptInviteCollection({ collectionId: collection._id });
      navigate(`/collection/${collection._id}/nfts`);

      await onUpdate?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const declineInvite = async () => {
    setIsLoading(true);
    try {
      await declineInviteCollection({ collectionId: collection._id });
      onUpdate?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      collection.trialEndDate &&
      new Date(collection.trialEndDate) > new Date()
    )
      setSubStatus("🕙");
    else
      switch (collection.subscriptionStatus) {
        case "active": {
          setSubStatus("🟢");
          break;
        }
        case "trialing": {
          setSubStatus("🟢");
          break;
        }
        case "past_due": {
          setSubStatus("🟡");
          break;
        }
        default: {
          setSubStatus("🔴");
        }
      }
  }, []);

  return (
    <div
      onClick={() => navigate("/collection/" + collection._id)}
      className={`collection-card ${collection.gold ? "gold" : ""}`}
    >
      <div className="sub-status">{subStatus}</div>

      <div className="title">
        <span>{collection.name}</span>
      </div>
      <div className="nft mt-auto">
        <span className="number">{collection.nftCount}</span>
        <span className="text">{t("collection.nfts")}</span>
      </div>
      <Button small text={String(t("collection.view"))} />
      {user.isAdmin ? (
        <div className="last-res mt-1">
          <p className="m-0">
            <i>
              <small>
                {t("collection.last_nft")}:{" "}
                {datetimeToString(collection.lastNftCreatedAt)}
              </small>
            </i>
          </p>
          <p className="m-0">
            <i>
              <small>
                {t("collection.live_date")}:{" "}
                {datetimeToString(collection.liveDate)}
              </small>
            </i>
          </p>
        </div>
      ) : null}
      {isInvited(user.email, collection) ? (
        <div className="buttons">
          <Button
            onClick={(e: any) => {
              e.stopPropagation();
              acceptInvite();
            }}
            small
            loading={isLoading}
            text={String(t("collection.accept_invite"))}
          />
          <Button
            onClick={(e: any) => {
              e.stopPropagation();
              declineInvite();
            }}
            small
            loading={isLoading}
            error
            text={String(t("collection.decline_invite"))}
          />
        </div>
      ) : null}
    </div>
  );
}
