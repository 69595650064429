import axios from "axios";
import { LANG } from "./i18next";

import { Pagination } from "../utils/Pagination";

export interface iEmail {
  _id?: string;

  type?: string;
  templateId?: string;
  translations?: {
    [key in LANG]?: {
      messages: Array<string>;
      subject: string;
      buttonText?: string;
    };
  };
}

export async function getEmailsPaginated({
  page = 1,
  size = 25,
}): Promise<Pagination<iEmail>> {
  const url = "/emails/paginate";
  const response = await axios.get(url, {
    params: {
      page,
      size,
    },
  });
  return response.data;
}

export async function editEmail(payload: any) {
  const response = await axios.post("/emails", payload);
  return response.data;
}

export async function sendTestEmail(payload: any) {
  const response = await axios.post("/emails/send-test", payload);
  return response.data;
}

export async function createSingleSend(payload: any) {
  const response = await axios.post("/emails/create-single-send", payload);
  return response.data;
}
