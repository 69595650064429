import { useEffect, useState } from "react";
import {
  iEmail,
  readMailService,
  reSubmitMailService,
} from "../../services/takyonMails";
import Button from "../../components/dom/Button";

interface EmailDetailProps {
  apikey: string;
  sg_message_id: string;
}

export default function EmailDetailModal({
  apikey,
  sg_message_id,
}: EmailDetailProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [emailDetail, setEmailDetail] = useState<iEmail>();

  const loadDeatailEmail = async () => {
    setIsLoading(true);
    try {
      const email = await readMailService(apikey, sg_message_id);

      setEmailDetail(email);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const reSendMail = async (sg_message_id: string) => {
    const resendMail = await reSubmitMailService(apikey, sg_message_id);

    alert(resendMail);
  };

  useEffect(() => {
    loadDeatailEmail();
  }, []);

  return (
    <div className="container">
      <div className="content">
        <h1>Email Details</h1>
        <br />
        <Button
          onClick={() => reSendMail(emailDetail!.sg_message_id)}
          text={"Resend Email"}
        />
        <hr />
        <table className="light">
          <thead>
            <tr>
              <th>Created At</th>
              <td>{emailDetail?._createdAt}</td>
            </tr>
            <tr>
              <th>To</th>
              <td>{emailDetail?.payload.to}</td>
            </tr>
            <tr>
              <th>From</th>
              <td>{emailDetail?.payload.from.email}</td>
            </tr>
            <tr>
              <th>Template ID</th>
              <td>{emailDetail?.payload.templateId}</td>
            </tr>
            <tr>
              <th>Dynamic Template Data</th>
              <td>{emailDetail?.dynamicTemplateDataString}</td>
            </tr>
            <tr>
              <th>Sent</th>
              <td>{emailDetail?.sent ? "Inviata" : "Non inviata"}</td>
            </tr>
          </thead>
        </table>
        <br />
      </div>
    </div>
  );
}
