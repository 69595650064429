import { useState } from "react";
import Button from "../dom/Button";
import { upload } from "../../services/upload";

/* USAGE

 <Upload
  multiple
  text={String(t("upload.upload"))}
  accept="image/png, image/jpeg"
  onUpload={(urls) => console.log(urls)}
 />

 */

interface Props {
  onUpload(urls: string[]): any;
  onError?(error: any): any;
  onStartLoading?(): any;
  onEndLoading?(): any;
  onChange?(): any;

  accept?: string; // "image/png, image/jpeg"
  type?: string;
  text?: string;
  multiple?: boolean;

  small?: boolean;
  light?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export default function Upload(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [lenght, setLenght] = useState(0);
  const [_id] = useState("upload_" + Math.random());

  const uploadInput = async (e: any) => {
    props.onChange?.();

    setIsLoading(true);
    props.onStartLoading?.();

    try {
      const files = Array.from(e.target.files);

      setLenght(files.length);

      const toUpload = [];
      for (let i = 0; i < files.length; i++) {
        const element = files[i] as any;

        const readAsync = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(element);

          reader.onloadend = async () => {
            if (reader.result) resolve(reader.result);
          };
        });

        toUpload.push(readAsync);
      }

      const urls = await upload(toUpload);

      props.onUpload(urls);
    } catch (error) {
      props.onError?.(error);
    }

    setIsLoading(false);
    props.onEndLoading?.();
  };

  return (
    <>
      <input
        id={_id}
        type={props.type ?? "file"}
        accept={props.accept}
        multiple={props.multiple}
        hidden
        disabled={isLoading}
        onChange={uploadInput}
      />

      <Button
        small={props.small}
        light={props.light}
        disabled={props.disabled}
        loading={isLoading || props.loading}
        metadata={lenght}
        text={props.text}
        onClick={() => {
          document.getElementById(_id)?.click();
        }}
        className="w100"
      />
    </>
  );
}
