import { ChangeEvent, useEffect, useState } from "react";

interface Props {
  onChange?(e: ChangeEvent<HTMLSelectElement>): any;
  defaultValue?: string | number;
  options: Array<{
    value: string | number;
    text: string | number;
    disabled?: boolean;
  }>;
  className?: string;
  name?: string;
  id?: string;
  small?: boolean;
  disabled?: boolean;
  value?: string | number;
  required?: boolean;
}

export default function Select(props: Props) {
  const [value, setValue] = useState<any>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (
      (value === undefined || value === null) &&
      props.defaultValue !== undefined &&
      props.defaultValue !== null
    )
      setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <select
      value={value}
      id={props.id}
      className={`custom-select ${props.className} ${
        props.small ? "small" : ""
      }`}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange?.(e);
      }}
      name={props.name}
      disabled={props.disabled}
    >
      {props.options.map((o) => (
        <option
          key={"select_" + o.value + Math.random()}
          value={o.value}
          disabled={o.disabled}
        >
          {o.text}
        </option>
      ))}
    </select>
  );
}
