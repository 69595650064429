import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { PAGE_DIM } from "../../utils/generic";
import Button from "./Button";

import previous from "../../assets/icons/cleft.svg";
import next from "../../assets/icons/cright.svg";
import doubleprev from "../../assets/icons/double-left-chevron.svg";
import doublenext from "../../assets/icons/double-right-chevron.svg";
import Select from "./Select";

interface iPaginationProps {
  page: number;
  size: number;
  total: number;
  onSizeChange: Function;
  onPageChange: Function;
}

function Pagination({
  total,
  size,
  onSizeChange,
  page,
  onPageChange,
}: iPaginationProps) {
  const { t } = useTranslation();

  const [_page, _setPage] = useState(page);
  const [_size, _setSize] = useState(size);
  const [itemRange, setItemRange] = useState("");

  const numberOfPages = Math.ceil(total / size);

  const sizeRange: Array<any> = [10, 25, 50, 100, 250];

  const handlePrevious = () => {
    if (page <= 1) return;
    onPageChange(page - 1);
  };

  const handleNext = () => {
    if (page >= numberOfPages) return;
    onPageChange(page + 1);
  };

  const handleFirst = () => {
    if (page <= 1) return;
    onPageChange(1);
  };

  const handleLast = () => {
    if (page === numberOfPages) return;
    onPageChange(numberOfPages);
  };

  const handlePageNumber = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = parseInt((e.target as HTMLInputElement).value);
      if (value < 1 || value > numberOfPages) return;
      onPageChange(value || 1);
    }
  };

  const handlePageInputChange = (e: any) => {
    const newInputValue = e.target.value;
    if (
      !isNaN(newInputValue) &&
      newInputValue !== "0" &&
      newInputValue <= numberOfPages
    )
      _setPage(newInputValue);
  };

  const updateItemRange = (currentPage: number, pageSize: number) => {
    const startItem = (currentPage - 1) * pageSize + 1;
    const endItem = Math.min(currentPage * pageSize, total);
    setItemRange(`${startItem} - ${endItem}`);
  };

  useEffect(() => {
    _setPage(page);
    _setSize(size);
    updateItemRange(page, size);
  }, [page, size, total]);

  return (
    <div className="pagination-container">
      <div className="pagination w100">
        <div className="items-per-page">
          <span>{t("pagination.items_per_page")}</span>
          <div>
            <Select
              small
              defaultValue={_size}
              value={_size}
              onChange={(e: any) => {
                onSizeChange(e.target.value);
                onPageChange(1);
              }}
              options={sizeRange.map((sr) => {
                return { value: sr, text: sr };
              })}
            />
          </div>
        </div>
        <div className="total-items font-muted">
          <span>{itemRange}</span>
          <span>{t("pagination.of")}</span>
          <span>{total}</span>
          <span>{t("pagination.items")}</span>
        </div>
        <div className="number-of-pages">
          <Button
            type="button"
            onClick={() => {
              handleFirst();
            }}
            icon={doubleprev}
            toggle
            className={`double ${page === 1 ? "disabled" : ""}`}
          />

          <Button
            type="button"
            onClick={() => {
              handlePrevious();
            }}
            icon={previous}
            toggle
            className={`${page === 1 ? "disabled" : ""}`}
          />

          <div className="numbers">
            <input
              type="text"
              id="page-number"
              className="page-number"
              value={_page}
              min={1}
              max={numberOfPages}
              onInput={(e) => {
                handlePageInputChange(e);
              }}
              onKeyDown={(e) => {
                handlePageNumber(e);
              }}
            />
            <div>
              {t("pagination.of")} {numberOfPages}
            </div>
          </div>

          <Button
            type="button"
            onClick={() => {
              handleNext();
            }}
            icon={next}
            toggle
            className={`${page >= numberOfPages ? "disabled" : ""}`}
          />

          <Button
            type="button"
            onClick={() => {
              handleLast();
            }}
            icon={doublenext}
            toggle
            className={`double ${page >= numberOfPages ? "disabled" : ""}`}
          />
        </div>
      </div>
    </div>
  );
}

export default Pagination;
