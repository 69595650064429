import { useTranslation } from "react-i18next";

import Button from "../../../components/dom/Button";
import { iDocument } from "../../../services/documents";

interface DocProps {
  onExit: Function;
  doc: iDocument;
}

export default function Document({ onExit, doc }: DocProps) {
  const { t } = useTranslation();

  return (
    <section id="support-section">
      <br />
      <div>
        <Button
          onClick={() => onExit?.()}
          small
          text={String(t("payment.back"))}
        />
      </div>
      <br />
      <div className="document-details">
        <h3>{doc.name}</h3>
        <br />
        <div
          className="doc-name documents-item"
          dangerouslySetInnerHTML={{ __html: doc.text }}
        ></div>
      </div>
    </section>
  );
}
