import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { isLoggedIn } from "./services/auth";
import { selectUser } from "./store/userSlice";
import Auth from "./components/auth/Auth";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import Group from "./pages/Group/Group";
import HomeCollections from "./pages/HomeCollections/HomeCollections";
import Collection from "./pages/Collection/Collection";
import Users from "./pages/Users/Users";
import Partners from "./pages/Partners/Partners";
import Emails from "./pages/Emails/Emails";
import SupportFaqs from "./pages/SupportFaqs/SupportFaqs";
import PromoCodes from "./pages/PromoCodes/PromoCodes";
import Invoices from "./pages/Invoices/Invoices";
import Documents from "./pages/Documents/Documents";
import Dropship from "./pages/Dropship/Dropship";
import Translations from "./pages/Translations/Translations";
import Nfts from "./pages/Nfts/Nfts";
import Ai from "./pages/Ai/Ai";
import Reports from "./pages/Reports/Reports";
import Quote from "./pages/Quote/Quote";
import CollectionManager from "./pages/CollectionManager/CollectionManager";
import HotelRegistrationManager from "./pages/BookingEngineIntegration/HotelRegistrationManager";
import EmailsService from "./pages/Emails/EmailsService";

export default function Router() {
  return (
    <Routes>
      <Route path="" element={<HomeCollections />} />
      <Route path="auth" element={<Auth mode="login" />} />
      <Route path="auth/login-token" element={<Auth mode="login_token" />} />
      <Route path="auth/register" element={<Auth mode="register" />} />
      <Route
        path="auth/reset-password"
        element={<Auth mode="reset_password" />}
      />
      <Route path="account/*" element={<AccountSettings />} />
      <Route path="group/:id/*" element={<Group />} />
      <Route path="collection/:id/*" element={<Collection />} />
      <Route path="dropship/:id/*" element={<Dropship />} />
      <Route path="users" element={<Users />} />
      <Route path="partners" element={<Partners />} />
      <Route path="emails" element={<Emails />} />
      <Route path="support" element={<SupportFaqs />} />
      <Route path="promocodes" element={<PromoCodes />} />
      <Route path="invoices" element={<Invoices />} />
      <Route path="docs" element={<Documents />} />
      <Route path="translations" element={<Translations />} />
      <Route path="nfts" element={<Nfts />} />
      <Route path="ai" element={<Ai />} />
      <Route path="reports" element={<Reports />} />
      <Route path="quote" element={<Quote />} />
      <Route path="collection-manager" element={<CollectionManager />} />
      <Route
        path="hotel-registration-manager"
        element={<HotelRegistrationManager />}
      />
      <Route path="emails-service" element={<EmailsService />} />
    </Routes>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !pathname.includes("reset-password") &&
      !pathname.includes("login-token")
    ) {
      if (!isLoggedIn()) navigate("/auth");
      if (isLoggedIn() && user._id && !user.emailVerified) navigate("/auth");
    }

    window.scrollTo({ top: 0, behavior: "auto" });
    // eslint-disable-next-line
  }, [pathname, user]);
  return <></>;
}
