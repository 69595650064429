import { useEffect, useState } from "react";
import {
  getInvoicesPaginated,
  iInvoice,
  iInvoiceOutput,
  sendInvoiceEmail,
  toggleInvoiceArchived,
  checkInvoiceMethod,
  sendInvoiceMethod,
  readInvoiceStatus,
  refreshStatusInvoice,
  getLastUpdateBilling,
} from "../../services/payment";
import { datetimeToString } from "../../utils/generic";
import Button from "../../components/dom/Button";
import { closeModal, openModal } from "../../components/modal/Modal";
import {
  BillingForm,
  ChargesLogModal,
} from "../AccountSettings/sub/PaymentSettings";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/dom/Loader";
import Select from "../../components/dom/Select";
import { DEFAULT_LANGUAGE, LANG } from "../../services/i18next";
import { AnyAction } from "redux";
import { AnyARecord } from "dns";
import { getMyApiKeys, iApiKey } from "../../services/apikey";

type ServerError = { errorMessage: string };

const datePickerStyle = {
  backgroundColor: "transparent",
  border: "1px solid #171717",
  borderRadius: "5px",
  height: "40px",
  padding: "5px 20px",
  fontSize: "14px",
  transitionDuration: "0.2s",
};

const errorStatusStyle = {
  color: "white",
  backgroundColor: "red",
  fontWeight: "bold",
  padding: "5px 10px",
  borderRadius: "5px",
  fontSize: "16px",
  display: "inline-block",
};

interface InoviceModalProps {
  invoice: iInvoice;
  onSuccess: Function;
}
interface SendINvoiceProps {
  invoice: iInvoice;
  apikey: string;
  onSuccess: Function;
}

interface IErrorInvoice {
  status: string;
  message: string;
}

type InvoiceStatus =
  | "RC"
  | "GENERATED"
  | "SEND_PROVIDER"
  | "PRESA IN CARICO"
  | "NS"
  | "NON INVIATA"
  | "INVIATA"
  | "MC"
  | "SEND_ANOTHER"
  | "NON TROVATA"
  | "ERROR";

const statusMessages: Record<InvoiceStatus, string> = {
  RC: "Fattura consegnata al destinatario.",
  GENERATED: "Xml fattura generato.",
  SEND_PROVIDER: "Fattura inviata al SDI.",
  "PRESA IN CARICO": "Fattura accettata dal fornitore, da inviare a SDI.",
  NS: "Fattura rifiutata dal SdI causa controlli non superati.",
  "NON INVIATA": "Fattura al fornitore, ma si Ã¨ verificato un errore.",
  INVIATA: "La fattura Ã¨ stata inviata al Sistema di Interscambio (SDI).",
  MC: "La fattura Ã¨ emessa e presente sul portale Fatture e Pagamenti del cliente, tuttavia non Ã¨ stata consegnata tramite il metodo scelto.",
  SEND_ANOTHER: "Fattura spedita con un altro servizio.",
  "NON TROVATA": "La fattura non Ã¨ stata trovata nel provider.",
  ERROR:
    "Il file della fattura Ã¨ stato inviato al fornitore, che ha restituito un errore specifico.",
};

const lightSignalMap: Record<InvoiceStatus, string> = {
  RC: "🟢",
  GENERATED: "🟡",
  SEND_PROVIDER: "🟡",
  "PRESA IN CARICO": "🟡",
  NS: "🔴",
  "NON INVIATA": "🔴",
  INVIATA: "🟢",
  MC: "🟢",
  SEND_ANOTHER: "🟡",
  "NON TROVATA": "🔴",
  ERROR: "🔴",
};

function getSignalStatus(status: string): string {
  console.log("Status:" + status);
  if (status in lightSignalMap) {
    return lightSignalMap[status as InvoiceStatus];
  }
  return ""; // valore predefinito nel caso in cui lo stato non sia presente
}

function SetInvoiceArchive({ invoice, onSuccess }: InoviceModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(invoice.invoceInternalId);

  const toggleArchiveInvoiceClick = async (
    invoiceId: string,
    archived: boolean
  ) => {
    setIsLoading(true);
    try {
      await toggleInvoiceArchived(invoiceId, archived, id);

      onSuccess();
      closeModal();
    } catch (error) {}
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <form>
        <label htmlFor="">Internal invoice ID (optional)</label>
        <input type="text" value={id} onChange={(e) => setId(e.target.value)} />

        <Button
          loading={isLoading}
          text={`${invoice.archived ? "Unarchive" : "Archive"}`}
          onClick={() =>
            toggleArchiveInvoiceClick(invoice._id!, !invoice.archived)
          }
        />
      </form>
    </div>
  );
}

function SendInvoiceModal({ invoice, apikey, onSuccess }: SendINvoiceProps) {
  const cleanString = (str: string) => {
    // /[^a-zA-Z0-9À-ÖØ-öø-ÿ ]/g
    return str.replace(/[&"'-]/g, "");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceOut, setInvoiceOut] = useState<iInvoiceOutput>({
    InvoiceNum: "",
    billingDetails: {
      ...invoice.billingDetails,
      vat: invoice.billingDetails.vat.trim() || "",
      fiscalCode: invoice.billingDetails.fiscalCode || "",
      name: cleanString(invoice.billingDetails.name || ""),
      zip: invoice.billingDetails.zip || "",
      city: invoice.billingDetails.city || "",
      address: invoice.billingDetails.address || "",
      province: invoice.billingDetails.province || "",
      contactName: invoice.billingDetails.contactName || "",
      contactPhone: invoice.billingDetails.contactPhone || "",
      recipientCode: invoice.billingDetails.recipientCode.toUpperCase() || "",
      pec: invoice.billingDetails.pec || "",
    },
    invoiceDocumentType: "TD01",
    InvoiceCurrency: "EUR",
    InvoiceDate: new Date(invoice._createdAt).toISOString().slice(0, 10),
    totalPrice: (Number(invoice.payload?.total) / 100).toString() || "",
    tax: "0.22",
    lineNumber: "1",
    amount: "1",
    description: `Takyon - Fee di creazione  ${datetimeToString(
      invoice.payload?.period_end * 1000
    )} - ${datetimeToString(invoice.payload?.period_start * 1000)}`,
    paymentCondition: "TP02",
    paymentMode: "MP19",
    paymentDate: new Date(Number(invoice.payload?.effective_at) * 1000)
      .toISOString()
      .slice(0, 10),
    invoiceServiceId: invoice.invoiceSeviceId,
  });
  const [labelError, setLabelError] = useState();

  const flattenObjectValues = (obj: any): any[] => {
    return Object.entries(obj).flatMap(([key, value]) => {
      if (
        [
          "fiscalCode",
          "contactName",
          "contactPhone",
          "recipientCode",
          "pec",
        ].includes(key)
      ) {
        return [];
      }
      return typeof value === "object"
        ? flattenObjectValues(value)
        : String(value);
    });
  };

  const errorInvoice = async (invoiceId: string) => {
    try {
      const response: any = await readInvoiceStatus(invoiceId, apikey);
      setLabelError(response.message);
    } catch (error) {
      console.log(error);
    }
  };

  const validateVat = (vat: string) => {
    const vatUpper = vat.toUpperCase();
    return vat.length === 11 && !vatUpper.startsWith("IT");
  };

  useEffect(() => {
    if (
      invoice.invoiceServiceStatus === "ERROR" ||
      invoice.invoiceServiceStatus === "NS"
    ) {
      errorInvoice(invoice.invoiceSeviceId);
    }
  }, [invoice.invoiceServiceStatus]);

  const sendInvoiceClick = async (onSuccess: Function) => {
    const payload = { ...invoiceOut };

    // Set the tax to 22%
    payload.tax = (Number(invoiceOut.tax) * 100).toString();
    payload.InvoiceCurrency = invoiceOut.InvoiceCurrency.toUpperCase();
    payload.billingDetails.province =
      payload.billingDetails.province?.toUpperCase();

    if (flattenObjectValues(payload).some((value) => value === "")) {
      alert("Please fill all required fields");
      return;
    }

    if (!validateVat(payload.billingDetails.vat!)) {
      alert("VAT must be 11 characters long and should not start with 'IT'");
      return;
    }

    setIsLoading(true);

    try {
      const response: any = await checkInvoiceMethod(payload, invoice._id);
      //console.log(response._id);
      const responseSend: any = await sendInvoiceMethod(
        [response._id],
        invoice._id
      );

      setIsLoading(false);
      closeModal();
      alert(responseSend.message);
      onSuccess();
      //console.log(response.xml);
      //openModal(<ViewXMLModal xmlData={String(response.xml)} />);
    } catch (error: any) {
      setIsLoading(false);
      alert(error.message);
    }
  };

  return (
    <div>
      <form className="p-3">
        {invoice.invoiceServiceStatus === "ERROR" ? (
          <div className="d-flex gap-2">
            <div className="d-flex flex-column w100">
              <label style={errorStatusStyle}>{labelError}</label>
            </div>
          </div>
        ) : null}
        <div className="d-flex gap-2">
          <div className="d-flex flex-column w100">
            <label htmlFor="">Invoice Number*</label>
            <input
              type="text"
              value={invoiceOut.InvoiceNum}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  InvoiceNum: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex flex-column w100">
            <label htmlFor="">Description*</label>
            <input
              type="text"
              value={invoiceOut.description}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  description: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <div className="d-flex flex-column w100">
            <label htmlFor="">Name*</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.name}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    name: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="d-flex flex-column w100">
            <label htmlFor="">Contact Name</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.contactName}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    contactName: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="d-flex row gx-2">
          <div className="d-flex flex-column col-6">
            <label htmlFor="">Address*</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.address}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    address: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-2">
            <label htmlFor="">City*</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.city}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    city: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-2">
            <label htmlFor="">Province*</label>
            <input
              type="text"
              value={invoiceOut?.billingDetails?.province?.toUpperCase()}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    province: e.target.value.toUpperCase(),
                  },
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-2">
            <label htmlFor="">Zip Code*</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.zip}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    zip: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="d-flex row gx-2">
          <div className="d-flex flex-column col-6">
            <label htmlFor="">PEC</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.pec}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    pec: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-3">
            <label htmlFor="">Contact Phone</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.contactPhone}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    contactPhone: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-3">
            <label htmlFor="">Recipient Code</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.recipientCode}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    recipientCode: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="d-flex row gx-2">
          <div className="d-flex flex-column col-4">
            <label htmlFor="">Vat*</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.vat}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    vat: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-4">
            <label htmlFor="">Fiscal Code</label>
            <input
              type="text"
              value={invoiceOut.billingDetails.fiscalCode}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  billingDetails: {
                    ...invoiceOut.billingDetails,
                    fiscalCode: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-4">
            <label htmlFor="">Invoice Date*</label>
            <input
              style={datePickerStyle}
              className="date"
              type="date"
              value={new Date(invoiceOut.InvoiceDate)
                .toISOString()
                .slice(0, 10)}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  InvoiceDate: new Date(e.target.value)
                    .toISOString()
                    .slice(0, 10),
                })
              }
            />
          </div>
        </div>
        <div className="d-flex gx-2 row">
          <div className="d-flex flex-column col-4">
            <label htmlFor="">Amount*</label>
            <input
              type="text"
              value={invoiceOut.totalPrice}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  totalPrice: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-2">
            <label htmlFor="">Tax*</label>
            <input
              type="text"
              value={invoiceOut.tax}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  tax: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-2">
            <label htmlFor="">Currency*</label>
            <input
              type="text"
              value={invoiceOut?.InvoiceCurrency?.toUpperCase()}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  InvoiceCurrency: e.target.value.toUpperCase(),
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-2">
            <label htmlFor="">Line Number*</label>
            <input
              type="text"
              value={invoiceOut.lineNumber}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  lineNumber: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-2">
            <label htmlFor="">Quantity*</label>
            <input
              type="text"
              value={invoiceOut.amount}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  amount: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex row gx-2">
          <div className="d-flex flex-column col-3">
            <label htmlFor="">Document Type*</label>
            <input
              type="text"
              value={invoiceOut.invoiceDocumentType}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  invoiceDocumentType: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-3">
            <label htmlFor="">Payment Condition*</label>
            <input
              type="text"
              value={invoiceOut.paymentCondition}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  paymentCondition: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-3">
            <label htmlFor="">Payment Mode*</label>
            <input
              type="text"
              value={invoiceOut.paymentMode}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  paymentMode: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex flex-column col-3">
            <label htmlFor="">Payment Date*</label>
            <input
              style={datePickerStyle}
              type="date"
              value={new Date(invoiceOut.paymentDate)
                .toISOString()
                .slice(0, 10)}
              onChange={(e) =>
                setInvoiceOut({
                  ...invoiceOut,
                  paymentDate: new Date(e.target.value)
                    .toISOString()
                    .slice(0, 10),
                })
              }
            />
          </div>
        </div>
        <Button
          text="Create Invoice"
          disabled={invoiceOut.InvoiceNum === ""}
          loading={isLoading}
          onClick={() => {
            sendInvoiceClick(onSuccess);
          }}
        />
      </form>
    </div>
  );
}

function ViewXMLModal({ xmlData }: { xmlData: string }) {
  const [xmlContent, setXmlContent] = useState(xmlData);
  return (
    <div className="p-3">
      <textarea
        style={{
          minHeight: `500px`,
        }}
        className="w-100"
        value={xmlContent}
        onChange={(e) => setXmlContent(e.target.value)}
      ></textarea>
      <div className="d-flex align-items-end w-100">
        <Button
          text="Invia"
          onClick={() => {
            closeModal();
          }}
        />
      </div>
    </div>
  );
}

function SendEmailInvoiceModal({ invoice, onSuccess }: InoviceModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(invoice.payload?.customer_email);
  const [lang, setLang] = useState(DEFAULT_LANGUAGE);

  const sendEmail = async () => {
    setIsLoading(true);
    try {
      await sendInvoiceEmail(invoice._id, email, lang as any);

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <form>
        <label htmlFor="">Recipient Email</label>
        <div className="d-flex gap-1">
          <input
            className="w100"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Select
            defaultValue={DEFAULT_LANGUAGE}
            onChange={(e) => setLang(e.target.value as LANG)}
            options={Object.keys(LANG).map((lang) => {
              return {
                text: lang,
                value: lang,
              };
            })}
          />
        </div>
        <Button loading={isLoading} text="Send" onClick={sendEmail} />
      </form>
    </div>
  );
}

export default function Invoices() {
  const [invoices, setInvoices] = useState<iInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState<Array<iApiKey>>([]);
  const [mode, setMode] = useState<
    "archived" | "unarchived" | "status" | "statusInternal"
  >("unarchived");
  const [lastUpdate, setLastUpdate] = useState<string>();

  const navigate = useNavigate();

  const loadInvoices = async () => {
    setIsLoading(true);
    try {
      let archived: any;

      if (mode === "archived") archived = true;
      if (mode === "unarchived") archived = false;

      const data = await getInvoicesPaginated(0, 0, archived);
      setInvoices(data.data);

      const lastUpdateBilling = await getLastUpdateBilling();
      setLastUpdate(lastUpdateBilling);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadApiKeys = async () => {
    setIsLoading(true);
    try {
      const data = await getMyApiKeys();
      setApiKeys(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  async function refreshStatus(isProvider: boolean) {
    setIsLoading(true);
    const data = await refreshStatusInvoice(isProvider);
    setIsLoading(false);
    alert(data);
  }

  const handleClick = (mode: string, status: boolean) => {
    switch (mode) {
      case "archived":
        setMode("archived");
        break;
      case "unarchived":
        setMode("unarchived");
        break;
      case "status":
        setMode("status");
        break;
      case "statusInternal":
        setMode("statusInternal");
        break;
    }
    refreshStatus(status);
  };

  const explanatoryStatus = (status: string, test: number): string => {
    if (!status) return "Fattura NON generata";

    return statusMessages[status as InvoiceStatus];
  };

  useEffect(() => {
    loadInvoices();
    loadApiKeys();
  }, [mode]);

  //if (isLoading) return <Loader />;

  return (
    <main>
      {isLoading && (
        <div className="loaderFull">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Loader />
          </div>
        </div>
      )}
      <div className="container">
        <div className="content">
          <br />
          <div className="d-flex gap-3">
            <Button
              text="Unarchived"
              small
              onClick={() => setMode("unarchived")}
              disabled={mode === "unarchived"}
              light={
                mode === "archived" ||
                mode === "status" ||
                mode === "statusInternal"
              }
            />
            <Button
              text="Archived"
              small
              onClick={() => setMode("archived")}
              disabled={mode === "archived"}
              light={
                mode === "unarchived" ||
                mode === "status" ||
                mode === "statusInternal"
              }
            />
            <Button
              text="Change Status"
              small
              onClick={() => handleClick("status", true)}
              disabled={mode === "status"}
              light={
                mode === "archived" ||
                mode === "unarchived" ||
                mode === "statusInternal"
              }
            />
            <Button
              text="Change Status internal"
              small
              onClick={() => handleClick("statusInternal", false)}
              disabled={mode === "statusInternal"}
              light={
                mode === "archived" ||
                mode === "unarchived" ||
                mode === "status"
              }
            />
            <span className="ms-auto">last update: {lastUpdate}</span>
          </div>
          <br />
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Payment Method Name</th>
                <th>Date Reciveced</th>
                <th>Date Paid</th>
                <th>Collection IDs</th>
                <th>Amount</th>
                <th># Billing</th>
                <th> </th>
                <th>Status</th>
                <th>Billing</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, key) => {
                const total = Number(invoice.payload?.total) / 100;
                const totalMinusIva = total / 1.22;
                const signalStaus = getSignalStatus(
                  invoice.invoiceServiceStatus
                );
                const backgroundColor = key % 2 === 0 ? "#f2f2f2" : "#ffffff";

                return (
                  <tr key={"invoice_" + key} style={{ backgroundColor }}>
                    <td>{key + 1}</td>
                    <td>{invoice.invoceInternalId}</td>
                    <td>{invoice.paymentMethodName}</td>
                    <td>{datetimeToString(invoice._createdAt)}</td>
                    <td>
                      {datetimeToString(invoice.payload?.effective_at * 1000)}
                    </td>
                    <td>
                      {!invoice.collectionNames?.length ? "🔴" : null}
                      {invoice.collectionNames?.length
                        ? invoice.collectionNames.map((c, key) => (
                            <p
                              className="m-0"
                              key={"collection_" + invoice.collectionIds?.[key]}
                              onClick={() =>
                                navigate(
                                  `/collection/${invoice.collectionIds?.[key]}/payment`
                                )
                              }
                            >
                              <u className="cursor-pointer">{c}</u>,{" "}
                            </p>
                          ))
                        : null}
                    </td>
                    <td>
                      <p>
                        <u>{total.toFixed(2)}€</u> ({totalMinusIva.toFixed(2)}
                        €)
                      </p>
                    </td>
                    <td>{invoice.invoiceServiceStatus}</td>
                    <td
                      className="pt-0"
                      style={{
                        maxWidth: "50px",
                        wordWrap: "break-word",
                      }}
                    >
                      {signalStaus}
                    </td>
                    <td>
                      <div className="d-flex flex-column gap-1">
                        {explanatoryStatus(invoice.invoiceServiceStatus, key)}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex flex-column gap-1">
                        <Button
                          text="View Billing Data"
                          small
                          onClick={() =>
                            openModal(
                              <form className="p-3">
                                <BillingForm
                                  billing={invoice.billingDetails}
                                  disabled
                                />
                              </form>
                            )
                          }
                        />
                        <Button
                          text="View Charges"
                          small
                          onClick={() =>
                            openModal(
                              <ChargesLogModal
                                payment={
                                  {
                                    _id: invoice.paymentMethodId,
                                    subscriptionCurrentEndDate:
                                      invoice.payload?.period_end * 1000,
                                    subscriptionCurrentStartDate:
                                      invoice.payload?.period_start * 1000,
                                  } as any
                                }
                              />
                            )
                          }
                        />
                        <Button
                          text={`${invoice.archived ? "Unarchive" : "Archive"}`}
                          small
                          onClick={() =>
                            openModal(
                              <SetInvoiceArchive
                                invoice={invoice}
                                onSuccess={loadInvoices}
                              />
                            )
                          }
                        />
                        <Button
                          text={`${
                            invoice.invoiceServiceStatus
                              ? "Resend Invoice"
                              : "Send Invoice"
                          }`}
                          disabled={
                            invoice.invoiceServiceStatus ===
                              "PRESA IN CARICO" ||
                            invoice.invoiceServiceStatus === "SEND_PROVIDER" ||
                            invoice.invoiceServiceStatus === "RC" ||
                            invoice.invoiceServiceStatus === "SEND_ANOTHER"
                          }
                          small
                          onClick={() =>
                            openModal(
                              <SendInvoiceModal
                                invoice={invoice}
                                apikey={apiKeys[0].token}
                                onSuccess={loadInvoices}
                              />
                            )
                          }
                        />
                        <Button
                          text={`${
                            invoice.emailSent ? "Email Sent" : "Send Email"
                          }`}
                          small
                          // disabled={invoice.emailSent}
                          light={invoice.emailSent}
                          onClick={() =>
                            openModal(
                              <SendEmailInvoiceModal
                                invoice={invoice}
                                onSuccess={loadInvoices}
                              />
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}
