import axios from "axios";
import { Pagination } from "../utils/Pagination";

export interface iHotelBE {
  _id: string;
  bookingEngine: string;
  hotelName: string;
  bookingEngineHotelId: string;
  structureToken?: string;
  structureUser?: string;
  structurePassword?: string;
  enable: boolean;
}

export async function getHotelPaginated(
  page: number,
  size: number
): Promise<Pagination<iHotelBE>> {
  const response = await axios.get("/hotelsBoking/getListHotel");
  return response.data;
}

export async function registerNewHotelBooking(payload: any) {
  const response = await axios.post(`/hotelsBoking/registerHotel`, {
    payload,
  });
  return response.data;
}

export async function enableDisableHotelBooking(payload: any) {
  const response = await axios.post(`/hotelsBoking/enableDisableHotel`, {
    payload,
  });
  return response.data;
}

export async function deleteHotelBooking(idCollection: string) {
  const response = await axios.delete(`/hotelsBoking/deleteHotel`, {
    data: { idCollection },
  });
  return response.data;
}
