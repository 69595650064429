import { useTranslation } from "react-i18next";
import {
  COLLECTION_PERMISSIONS,
  ExperienceCategory,
  autofillCollectionCensusInfo,
  experienceCensus,
  experienceCensusInfo,
  iCollection,
  patchCollection,
  refreshStoredCollection,
} from "../../services/collection";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/userSlice";
import {
  starsOptions,
  hotelCensusLocationTypeOptions,
  expCategoriesOptions,
} from "../../utils/generic";
import MapsAutocomplete from "../mapsautocomplete/MapsAutocomplete";

import closeSvg from "../../assets/icons/close.svg";
import * as Yup from "yup";

import Button from "../dom/Button";
import Select from "../dom/Select";
import { LANG } from "../../services/i18next";
import Upload from "../Upload/Upload";

interface CollectionCensusProps {
  collection: iCollection;
  onRefresh: Function;
  canEdit: boolean;
}

function CensusExperienceGeneric({
  collection,
  onRefresh,
  canEdit,
}: CollectionCensusProps) {
  const [census, setCensus] = useState({
    name: "",
    category: "",
    website: "",
    location: {},
  });
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (collection.census) setCensus({ ...(collection.census as any) });
  }, [collection]);

  const save = async () => {
    setIsLoading(true);

    ExperienceFormSchema.validate(census, { abortEarly: false })
      .then(async (valid) => {
        try {
          // always patch because a collection will have a type selected
          // during the creation
          await patchCollection({
            ...collection,
            census: census,
            collectionId: collection._id,
          });
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        // Data is invalid, handle errors
        console.log("Validation failed:", err.errors);
        setErrors(err.errors);
      });

    setIsLoading(false);
  };

  return (
    <form>
      <strong>{t("experience.census.generic.title")}</strong>

      <div className="d-flex gap-3 mt-3">
        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-name">
            {t("experience.census.census_name")}
          </label>
          <input
            disabled={isLoading || !canEdit}
            value={census.name}
            onChange={(e) => {
              setCensus((census) => {
                census.name = e.target.value;
                return { ...census };
              });
            }}
            type="text"
            id="census-form-name"
          />
        </div>
        <p>{errors.length > 0 && errors[0]}</p>
      </div>

      <div className="d-flex gap-3">
        <div className="d-flex flex-column w100">
          <label htmlFor="census-form-stars">
            {t("experience.census.category")}
          </label>
          <Select
            disabled={isLoading || !canEdit}
            value={String(census.category)}
            defaultValue={""}
            onChange={(e) => {
              setCensus((census) => {
                census.category = e.target.value as ExperienceCategory;
                return { ...census };
              });
            }}
            id="census-form-stars"
            options={[{ value: "", text: "Seleziona" }, ...categories]}
          />
          <p>{errors.length > 0 && errors[1]}</p>
        </div>
      </div>
      <br />

      <div>
        <label htmlFor="">{t("collection.census_location")}</label>
        <MapsAutocomplete
          onSelect={(e: string) => {
            setCensus((census) => {
              census.location = e;
              return { ...census };
            });
          }}
          defaultValue={census.location}
        />
      </div>

      <br />

      <div className="d-flex flex-column w100">
        <label htmlFor="census-form-website">
          {t("collection.census_website")}
        </label>
        <input
          disabled={isLoading || !canEdit}
          value={census.website}
          onChange={(e) => {
            setCensus((census) => {
              census.website = e.target.value;
              return { ...census };
            });
          }}
          type="text"
          id="census-form-website"
        />
      </div>

      <br />

      {canEdit ? (
        <div>
          <Button
            loading={isLoading}
            text={String(t("collection.census_save"))}
            onClick={() => save()}
            // light={!isEditButtonEnabled}
            // disabled={!isEditButtonEnabled}
          />
        </div>
      ) : null}
    </form>
  );
}

function CensusExperienceInfo({ collection, canEdit }: CollectionCensusProps) {
  const [info, setInfo] = useState<experienceCensusInfo>({});
  // const [lang, setLang] = useState<LANG>(DEFAULT_LANGUAGE);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setInfo((info) => {
      if (collection.census?.info)
        info = JSON.parse(JSON.stringify(collection.census.info));

      Object.values(LANG).forEach((lang) => {
        if (!info[lang]) info[lang] = {};

        if (!info[lang]?.experiences) info[lang]!.experiences = [];
      });

      return { ...info };
    });
  }, [collection]);

  const save = async () => {
    setIsLoading(true);
    try {
      await patchCollection({
        ...collection,
        census: { ...collection.census, info },
        collectionId: collection._id,
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <form>
      <fieldset disabled={isLoading || !canEdit}>
        <div className="w100 d-flex flex-row justify-content-between">
          <strong>{t("collection.hotel_census_info")}</strong>
        </div>
        <br />
        <div className="d-flex justify-content-between">
          <Button
            onClick={() => {
              document
                .getElementById("translations-scroll")
                ?.scrollBy({ left: -500, behavior: "smooth" });
            }}
            light
            small
            text="<"
          />
          <Button
            onClick={() => {
              document
                .getElementById("translations-scroll")
                ?.scrollBy({ left: 500, behavior: "smooth" });
            }}
            light
            small
            text=">"
          />
        </div>
        <br />
        <div
          id="translations-scroll"
          className="d-flex"
          style={{ overflowX: "auto" }}
        >
          {Object.values(LANG).map((lang) => {
            return (
              <div style={{ minWidth: "500px" }} className="d-inline mx-3">
                <h5 style={{ maxWidth: "max-content" }} className="border p-1">
                  {lang}
                </h5>

                <div className="w100 d-flex flex-row justify-content-between">
                  <span>{t("collection.info_experiences")}</span>

                  <Button
                    loading={isLoading}
                    small
                    text={String(t("collection.add_experience"))}
                    onClick={() => {
                      setInfo((info) => {
                        info = JSON.parse(JSON.stringify(info));
                        info[lang]!.experiences = [
                          { name: "", description: "", images: [] },
                          ...info[lang]!.experiences!,
                        ];
                        return { ...info };
                      });
                    }}
                  />
                </div>

                <br />

                {info[lang]?.experiences?.map((experience, key) => {
                  return (
                    <div
                      key={"room_" + key}
                      className="mb-3"
                      style={{
                        padding: "20px",
                        border: "1px solid gray",
                        borderRadius: "10px",
                      }}
                    >
                      <p className="bodytext">Name</p>

                      <div className="d-flex">
                        <input
                          className="w100 m-0"
                          type="text"
                          value={experience.name}
                          onChange={(e) => {
                            setInfo((info) => {
                              info = JSON.parse(JSON.stringify(info));
                              info[lang]!.experiences![key]!.name =
                                e.target.value;
                              return { ...info };
                            });
                          }}
                        />

                        <img
                          src={closeSvg}
                          alt="delete icon"
                          className="cursor-pointer"
                          width="20px"
                          onClick={() => {
                            setInfo((info) => {
                              info = JSON.parse(JSON.stringify(info));
                              info[lang]!.experiences?.splice(key, 1);
                              return { ...info };
                            });
                          }}
                        />
                      </div>

                      <br />

                      <p className="bodytext">Description</p>
                      <textarea
                        className="w100 m-0"
                        value={experience.description}
                        onChange={(e) => {
                          setInfo((info) => {
                            info = JSON.parse(JSON.stringify(info));
                            info[lang]!.experiences![key]!.description =
                              e.target.value;
                            return { ...info };
                          });
                        }}
                      />

                      <br />
                      <br />

                      <p className="bodytext">Host Name</p>

                      <input
                        className="w100 m-0"
                        type="text"
                        value={experience.hostName}
                        onChange={(e) => {
                          setInfo((info) => {
                            info = JSON.parse(JSON.stringify(info));
                            info[lang]!.experiences![key]!.hostName =
                              e.target.value;
                            return { ...info };
                          });
                        }}
                      />
                      <br />

                      <p className="bodytext">Host description</p>
                      <textarea
                        className="w100 m-0"
                        value={experience.hostDescription}
                        onChange={(e) => {
                          setInfo((info) => {
                            info = JSON.parse(JSON.stringify(info));
                            info[lang]!.experiences![key]!.hostDescription =
                              e.target.value;
                            return { ...info };
                          });
                        }}
                      />

                      <br />

                      {/* room images */}
                      <hr />

                      <br />

                      <div className="w100 d-flex flex-row justify-content-between">
                        <span>{t("collection.experience_images")}</span>

                        <div>
                          <Upload
                            onStartLoading={() => setIsLoading(true)}
                            onEndLoading={() => setIsLoading(false)}
                            accept="image/png, image/jpeg"
                            text={String(t("collection.upload"))}
                            multiple
                            small
                            onUpload={(urls) => {
                              setInfo((info) => {
                                info = JSON.parse(JSON.stringify(info));
                                info[lang]!.experiences![key].images = [
                                  ...(info[lang]!.experiences![key].images ??
                                    []),
                                  ...urls,
                                ];
                                return { ...info };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-wrap">
                        {info[lang]!.experiences![key].images?.map(
                          (image, image_key) => (
                            <div
                              key={"image_" + image_key}
                              className="position-relative"
                            >
                              <img
                                width={"130px"}
                                key={"image_" + Math.random()}
                                src={image}
                                alt="takyon hotel"
                                className="m-1"
                              />

                              <img
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  backgroundColor: "white",
                                  borderRadius: "100%",
                                }}
                                src={closeSvg}
                                alt="delete icon"
                                className="cursor-pointer"
                                width="20px"
                                onClick={() => {
                                  setInfo((info) => {
                                    info = JSON.parse(JSON.stringify(info));
                                    info[lang]!.experiences![
                                      key
                                    ].images!.splice(image_key, 1);
                                    return { ...info };
                                  });
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <br />
        <br />

        {canEdit ? (
          <div>
            <Button
              loading={isLoading}
              text={String(t("collection.census_save"))}
              onClick={() => save()}
              // light={!isEditButtonEnabled}
              // disabled={!isEditButtonEnabled}
              id="save-info-btn"
            />
          </div>
        ) : null}
      </fieldset>
    </form>
  );
}

interface CollectionCensusProps {
  collection: iCollection;
  onRefresh: Function;
  canEdit: boolean;
}

const categories = Object.entries(ExperienceCategory).map(([key, value]) => {
  return {
    value: key,
    text: value,
    key: key,
  };
});

const ExperienceFormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
});

export default function CensusExperience({
  collection,
  canEdit,
}: CollectionCensusProps) {
  const user = useSelector(selectUser);

  return (
    <section className="collection-sub-page collection-census">
      <>
        {/* generic details */}
        <CensusExperienceGeneric
          canEdit={canEdit}
          onRefresh={() => {}}
          collection={collection}
        />
        <hr />
        {user.isAdmin ||
        user.email === "dataentry@takyon.io" ||
        user.email === "dataentry2@takyon.io" ? (
          <CensusExperienceInfo
            canEdit={canEdit}
            onRefresh={() => {}}
            collection={collection}
          />
        ) : null}
      </>
    </section>
  );
}
