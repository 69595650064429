import { useSelector } from "react-redux";
import { selectUser } from "../../store/userSlice";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ProfileSettings from "./sub/ProfileSettings";
import GroupsSettings from "./sub/GroupsSettings";
import { useEffect, useState } from "react";

import userSvg from "../../assets/icons/user.svg";
import groupSvg from "../../assets/icons/group.svg";
import paymentSvg from "../../assets/icons/payment.svg";
import devSvg from "../../assets/icons/dev.svg";
import DeveloperSettings from "./sub/DeveloperSettings";
import { useTranslation } from "react-i18next";
import PaymentSettings from "./sub/PaymentSettings";

function AccountSettingsRouter() {
  return (
    <Routes>
      <Route path="/profile" element={<ProfileSettings />} />
      <Route path="/group" element={<GroupsSettings />} />
      <Route path="/developer" element={<DeveloperSettings />} />
      <Route path="/payment/*" element={<PaymentSettings />} />
    </Routes>
  );
}

interface iMenuItem {
  name: string;
  icon?: any;
  separator?: boolean;
  uri?: string;
  selected?: boolean;
}

export default function AccountSettings() {
  const { t } = useTranslation();

  const menu: Array<iMenuItem> = [
    {
      name: t("settings.profile"),
      uri: "profile",
      icon: userSvg,
    },
    {
      name: t("settings.group"),
      uri: "group",
      icon: groupSvg,
    },
    {
      name: t("settings.payment"),
      uri: "payment",
      icon: paymentSvg,
      separator: true,
    },
    {
      name: t("settings.developer"),
      uri: "developer",
      icon: devSvg,
    },
  ];

  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [menuitems, setMenuitems] = useState<Array<iMenuItem>>([]);

  useEffect(() => {
    setMenuitems(() => {
      return menu.map((i: iMenuItem) => {
        if (pathname.includes(String(i.uri))) i.selected = true;
        return i;
      });
    });
    // eslint-disable-next-line
  }, [pathname]);

  if (!user._id) return <></>;

  return (
    <main id="account-settings">
      <div className="container header-container">
        <div className="user-info">
          <img className="user-icon" src={userSvg} alt="user icon" />
          <div className="name">
            {user.firstName || user.secondName ? (
              <p className="h5">
                {(user.firstName ?? "") + " " + (user.secondName ?? "")}
              </p>
            ) : (
              <p className="h5">{user.email.split("@")[0]}</p>
            )}
            <p>{user.email}</p>
          </div>
        </div>
      </div>
      <div className="container account-settings-container">
        <div className="sidenav">
          {menuitems.map((item, key) => {
            return (
              <div key={"menu_item_" + key}>
                <div
                  className={`menu-item ${item.selected ? "selected" : ""}`}
                  onClick={() => {
                    if (item.uri) navigate(item.uri);
                  }}
                >
                  <img className="menu-icon" src={item.icon} alt="" />
                  <span>{item.name}</span>
                </div>
                {item.separator ? <div className="hr"></div> : null}
              </div>
            );
          })}
        </div>
        <div className="settings-content">
          <span className="h4 m-0">
            {menuitems.find((e) => e.selected)?.name}
          </span>
          <div className="hr"></div>
          <AccountSettingsRouter />
        </div>
      </div>
    </main>
  );
}
