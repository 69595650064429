import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { formToObject } from "../../../utils/generic";
import { createFaqInCategory } from "../../../services/supportfaqs";
import { closeModal } from "../../../components/modal/Modal";
import Button from "../../../components/dom/Button";

interface FaqModalCreateProps {
  refresh: Function;
  category?: any;
}

export default function FaqModalCreate({
  refresh,
  category,
}: FaqModalCreateProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const createFaqSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    setIsLoading(true);

    try {
      const data = formToObject(e.target);
      const _id = category._id;
      data.language = category.lang;

      await createFaqInCategory({ _id: _id, faq: data });

      closeModal();
      refresh?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  isLoading && <div className="loader"></div>;

  return (
    <div id="faq-modal">
      <form onSubmit={createFaqSubmit} className="modal-form">
        <label htmlFor="language">{t("support.language")}</label>
        <input
          type="text"
          id="language"
          name="language"
          value={category.lang}
        />

        <label htmlFor="question">{t("support.question")}</label>
        <textarea id="question" name="question" required />

        <label htmlFor="answer">{t("support.answer")}</label>
        <textarea id="answer" name="answer" required />

        <Button
          className="btn btn-primary"
          text={String(t("support.save"))}
          type="submit"
          loading={isLoading}
        />
      </form>
    </div>
  );
}
