import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  deleteFaqInCategory,
  iFaq,
  iSupportFaq,
} from "../../../services/supportfaqs";
import { closeModal } from "../../../components/modal/Modal";
import Button from "../../../components/dom/Button";

interface FaqDeleteProps {
  category: iSupportFaq;
  faq: iFaq;
  refresh: Function;
}

export default function FaqModalDelete({
  category,
  refresh,
  faq,
}: FaqDeleteProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteFaqSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await deleteFaqInCategory({
        _id: category._id,
        _faqId: faq._faqId,
      });

      closeModal();
      refresh?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div id="faq-modal">
      <form onSubmit={deleteFaqSubmit} className="modal-form">
        <span className="message">{t("support.delete_faq_confirm")}</span>

        <Button
          className="btn btn-primary"
          text={String(t("support.delete"))}
          type="submit"
          loading={isLoading}
        />
      </form>
    </div>
  );
}
