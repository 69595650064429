import { useEffect, useState } from "react";

interface Props {
  onChange?: Function;
  disabled?: boolean;
  small?: boolean;
  className?: string;
  light?: boolean;
  id?: string;
  checked?: boolean;
  name?: string;
  error?: boolean;
}

export default function Checkbox(props: Props) {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(props.checked ?? false);
  }, [props.checked]);

  return (
    <div className="custom-checkbox">
      <label className="switch">
        <input type="hidden" value="off" name={props.name} />
        <input
          disabled={props.disabled}
          onChange={(e) => {
            e.stopPropagation();
            if (props.disabled) return;
            setChecked(e.target.checked);
            props.onChange?.(e.target.checked);
          }}
          className={`${props.className ?? ""} ${props.small ? "small" : ""} ${
            props.light ? "light" : ""
          } ${props.error ? "error" : ""}`}
          id={props.id}
          checked={checked}
          name={props.name}
          type="checkbox"
        />
        <div className="slider round"></div>
      </label>
    </div>
  );
}
