interface Props {
  scale?: number;
  light?: boolean;
  height?: string;
  width?: string;
}

export default function Loader({ scale, light, height, width }: Props) {
  return (
    <div
      style={{
        transform: `scale(${scale ?? 1})`,
        height: height,
        width: width,
      }}
      className={`loader ${light ? "light" : ""}`}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
