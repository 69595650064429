import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  COLLECTION_PERMISSIONS,
  COLLECTION_TYPE,
} from "../../../services/collection";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import { selectUser } from "../../../store/userSlice";
import { CollectionRouterProps } from "../Collection";
import CensusExperience from "../../../components/census/CensusExperience";
import CensusHotel from "../../../components/census/CensusHotel";
import CollectionSettings from "../../../components/admin/CollectionSettings";

export default function CollectionCensusByType({
  collection,
  group,
  onRefresh,
}: CollectionRouterProps) {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const collectionType = () => {
    return collection.type || "hotel";
  };

  const canEdit = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.census)
    )
      return true;

    return false;
  };

  return (
    <>
      <section className="collection-sub-page collection-census">
        {user.isAdmin ? <CollectionSettings collection={collection} /> : null}
      </section>

      <section className="collection-sub-page collection-census">
        {collectionType() === COLLECTION_TYPE.hotel && (
          <CensusHotel
            group={group}
            onRefresh={onRefresh}
            collection={collection}
          />
        )}

        {collectionType() === COLLECTION_TYPE.experience && (
          <CensusExperience
            canEdit={canEdit()}
            onRefresh={onRefresh}
            collection={collection}
          />
        )}
      </section>
    </>
  );
}
