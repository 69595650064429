import React, { useEffect, useState } from "react";

function calcrooms(amount: number) {
  const res = 0.016 * amount + 0.5;
  return res;
}

function calcmainCity(bool: boolean) {
  const res = bool ? 1.5 : 1;
  return res;
}

function calcseasonal(bool: boolean) {
  const res = bool ? 0.8 : 1;
  return res;
}

function calcgroup(amount: number) {
  const res = -0.02 * amount + 1.02;
  return res;
}

export default function Quote() {
  const [base, setBase] = useState(300);
  const [rooms, setRooms] = useState(30);
  const [group, setGroup] = useState(1);
  const [seasonal, setSeasonal] = useState(false);
  const [mainCity, setMainCity] = useState(false);

  const [result, setResult] = useState(0);

  useEffect(() => {
    const res = Math.max(
      50,
      Math.round(
        base *
          calcrooms(rooms) *
          calcmainCity(mainCity) *
          calcseasonal(seasonal) *
          calcgroup(group)
      )
    );

    setResult(res);
  }, [base, rooms, group, seasonal, mainCity]);

  return (
    <>
      <div className="container">
        <div className="content">
          <br />
          <br />
          <form>
            <label>
              Base Price:
              <br />
              <input
                min={0}
                type="number"
                value={base}
                onChange={(e) => setBase(Number(e.target.value))}
              />
            </label>

            <label>
              Number of Rooms:
              <br />
              <input
                min={0}
                type="number"
                value={rooms}
                onChange={(e) => setRooms(Number(e.target.value))}
              />
            </label>

            <label>
              Group Size:
              <br />
              <input
                min={1}
                type="number"
                value={group}
                onChange={(e) => setGroup(Number(e.target.value))}
              />
            </label>

            <label>
              <input
                className="mx-3"
                type="checkbox"
                checked={seasonal}
                onChange={(e) => setSeasonal(e.target.checked)}
              />
              Seasonal Pricing
            </label>

            <label>
              <input
                className="mx-3"
                type="checkbox"
                checked={mainCity}
                onChange={(e) => setMainCity(e.target.checked)}
              />
              Main City
            </label>
          </form>
          <br />
          <hr />
          <br />
          <h1>
            {result.toFixed(0)}€ {group > 1 ? "per hotel" : null}
          </h1>
          {group > 1 ? <p>{(result * group).toFixed(0)}€ Total</p> : null}
        </div>
      </div>
    </>
  );
}
