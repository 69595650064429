import { useState } from "react";
import { useTranslation } from "react-i18next";

import { iCollection } from "../../../services/collection";
import { formToObject } from "../../../utils/generic";
import { createAllReport, createReport } from "../../../services/reports";
import CustomDatePicker from "../../../components/dom/CustomDatePicker";
import Button from "../../../components/dom/Button";
import { closeModal } from "../../../components/modal/Modal";

interface Props {
  collection: iCollection;
  onSuccess?: Function;
}

export default function ReportCreateModal({ collection, onSuccess }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const monthStart = new Date();
  monthStart.setMonth(monthStart.getMonth() - 1);
  monthStart.setDate(1);

  const monthEnd = new Date();
  monthEnd.setMonth(monthEnd.getMonth());
  monthEnd.setDate(1);

  const prevMonthStart = new Date();
  prevMonthStart.setMonth(prevMonthStart.getMonth() - 2);
  prevMonthStart.setDate(1);

  const prevMonthEnd = new Date();
  prevMonthEnd.setMonth(prevMonthEnd.getMonth() - 1);
  prevMonthEnd.setDate(1);

  const createSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      data.collectionId = collection._id;

      await createReport(data);
      closeModal();
      onSuccess?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <span>{t("reports.create_report")}</span>
      <form onSubmit={createSubmit} className="modal-form mt-3">
        <label htmlFor="startdate">{t("reports.start_date")}</label>
        <CustomDatePicker
          defaultValue={monthStart}
          name="startDate"
          id="startdate"
          required
        />

        <label htmlFor="enddate">{t("reports.end_date")}</label>
        <CustomDatePicker
          defaultValue={monthEnd}
          name="endDate"
          id="enddate"
          required
        />

        <label htmlFor="startdateold">{t("reports.start_date_old")}</label>
        <CustomDatePicker
          defaultValue={prevMonthStart}
          name="startDateOld"
          id="startdateold"
          required
        />

        <label htmlFor="enddateold">{t("reports.end_date_old")}</label>
        <CustomDatePicker
          defaultValue={prevMonthEnd}
          name="endDateOld"
          id="enddateold"
          required
        />

        <Button
          type="submit"
          text={String(t("reports.create_report"))}
          loading={isLoading}
        />
      </form>
    </div>
  );
}

export function ReportCreateAllModal() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const monthStart = new Date();
  monthStart.setMonth(monthStart.getMonth() - 1);
  monthStart.setDate(1);

  const monthEnd = new Date();
  monthEnd.setMonth(monthEnd.getMonth());
  monthEnd.setDate(1);

  const prevMonthStart = new Date();
  prevMonthStart.setMonth(prevMonthStart.getMonth() - 2);
  prevMonthStart.setDate(1);

  const prevMonthEnd = new Date();
  prevMonthEnd.setMonth(prevMonthEnd.getMonth() - 1);
  prevMonthEnd.setDate(1);

  const createSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);

      await createAllReport(data);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <span>{t("reports.create_report")}</span>
      <form onSubmit={createSubmit} className="modal-form mt-3">
        <label htmlFor="startdate">{t("reports.start_date")}</label>
        <CustomDatePicker
          defaultValue={monthStart}
          name="startDate"
          id="startdate"
          required
        />

        <label htmlFor="enddate">{t("reports.end_date")}</label>
        <CustomDatePicker
          defaultValue={monthEnd}
          name="endDate"
          id="enddate"
          required
        />

        <label htmlFor="startdateold">{t("reports.start_date_old")}</label>
        <CustomDatePicker
          defaultValue={prevMonthStart}
          name="startDateOld"
          id="startdateold"
          required
        />

        <label htmlFor="enddateold">{t("reports.end_date_old")}</label>
        <CustomDatePicker
          defaultValue={prevMonthEnd}
          name="endDateOld"
          id="enddateold"
          required
        />

        <Button
          type="submit"
          text={String(t("reports.create_report"))}
          loading={isLoading}
        />
      </form>
    </div>
  );
}
