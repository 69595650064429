import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormEvent, useState } from "react";

import Button from "../dom/Button";
import { selectUser } from "../../store/userSlice";
import Checkbox from "../dom/Checkbox";
import { formToObject } from "../../utils/generic";
import { patchUser } from "../../services/auth";
import { closeModal } from "../modal/Modal";

export function PolicyModal() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [policyBtnDisabled, setPolicyBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const policyFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);

      await patchUser(data);

      closeModal();
    } catch (error: any) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <form
        onSubmit={(e) => policyFormSubmit(e)}
        className="d-flex flex-column gap-3"
      >
        <span>{t("policy.1")}</span>

        <div className="d-flex align-items-center gap-2">
          <Checkbox
            checked={user.platformPolicy}
            name="platformPolicy"
            onChange={() => setPolicyBtnDisabled(false)}
            id="policy"
          />
          <label htmlFor="policy">{t("policy.2")}</label>
        </div>
        <div className="align-self-end">
          <Button
            light={policyBtnDisabled}
            disabled={policyBtnDisabled}
            loading={isLoading}
            type="submit"
            text={String(t("policy.save"))}
          />
        </div>
      </form>
    </div>
  );
}
