import { FormEvent, useEffect, useRef, useState } from "react";

import {
  PAGE_DIM,
  autoGrowTextArea,
  formToObject,
  scrollToTopList,
} from "../../utils/generic";

import {
  createSingleSend,
  editEmail,
  getEmailsPaginated,
  iEmail,
  sendTestEmail,
} from "../../services/emails";
import Pagination from "../../components/dom/Pagination";
import Button from "../../components/dom/Button";
import { useTranslation } from "react-i18next";
import { closeModal, openModal } from "../../components/modal/Modal";
import CustomDatePicker from "../../components/dom/CustomDatePicker";

interface loadEmailsProps {
  page: number;
  size: number;
}

interface CreateEmailModalProps {
  onCreate: Function;
}

function CreateEmailModal({ onCreate }: CreateEmailModalProps) {
  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = formToObject(e.target);
      onCreate(data);

      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form className="p-3" onSubmit={submit}>
        <label>Mail Tag</label>
        <input name="type" type="text" />
        <div>
          <Button type="submit" text="Create" />
        </div>
      </form>
    </div>
  );
}

function CreateSingleSendModal() {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const create = async () => {
    setIsLoading(true);
    try {
      await createSingleSend({ name, date });

      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <form action="">
        <label htmlFor="">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label htmlFor="">Date</label>
        <CustomDatePicker
          onSelect={(e) => {
            if (e) setDate(e);
          }}
          name=""
        />
        <Button
          onClick={create}
          disabled={!name || !date}
          text="Create"
          loading={isLoading}
        />
      </form>
    </div>
  );
}

export default function Emails() {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<iEmail[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [edited, setEdited] = useState<(string | undefined)[]>([]);
  const [open, setOpen] = useState<string | undefined>();
  const [testEmail, setTestEmail] = useState("");

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const elementRef = useRef<HTMLDivElement>(null);

  const loadEmails = async ({ page = 1, size }: loadEmailsProps) => {
    setIsLoading(true);
    try {
      const data = await getEmailsPaginated({ page, size });

      setTotal(data.total || 0);

      setEmails(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  const saveEmail = async (email: iEmail) => {
    setIsLoading(true);
    try {
      setEdited((edited) => {
        edited = edited.filter((e) => e !== email.type);
        return edited;
      });

      await editEmail(email);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadEmails({ page: page, size: size });
    // eslint-disable-next-line
  }, [page, size]);

  //   if (isLoading) return <Loader />;

  return (
    <main>
      <div className="container">
        <div className="content">
          <br />
          <div className="d-flex gap-1">
            <Button
              onClick={() =>
                openModal(
                  <CreateEmailModal
                    onCreate={(e: any) => {
                      const mailType = e.type;

                      setEmails([
                        {
                          templateId: "",
                          type: mailType,
                          translations: {
                            it: {
                              subject: "",
                              buttonText: "",
                              messages: [],
                            },
                            en: {
                              subject: "",
                              buttonText: "",
                              messages: [],
                            },
                          },
                        },
                        ...emails,
                      ]);
                    }}
                  />
                )
              }
              text={"New Email"}
            />
            <Button
              onClick={() => openModal(<CreateSingleSendModal />)}
              text="Create single send"
            />
          </div>
          <hr />
          {emails.length ? (
            <>
              <div ref={elementRef}>
                {emails.map((email) => (
                  <div
                    key={"email_" + email._id}
                    className="d-flex flex-column gap-3"
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        setOpen(open === email.type ? undefined : email.type)
                      }
                    >
                      Type: <strong>{email.type}</strong>
                    </div>
                    <div className={`${open === email.type ? "" : "h0"}`}>
                      <div className="d-flex align-items-center gap-3">
                        Template:
                        <input
                          className="w100"
                          type="text"
                          value={email.templateId}
                          onChange={(e) => {
                            setEdited((edited) => {
                              edited.push(email.type);
                              return edited;
                            });
                            setEmails(
                              emails.map((_email) => {
                                if (email.type === _email.type)
                                  _email.templateId = e.target.value;
                                return _email;
                              })
                            );
                          }}
                        />
                      </div>

                      {[...Object.keys(email.translations as any)].map(
                        (lang) => {
                          const config = email.translations as any;
                          return (
                            <div
                              className="border p-3"
                              key={"email_" + email._id + "_lang_" + lang}
                            >
                              <div className="d-flex align-items-center my-3 gap-3">
                                <u className="text-nowrap">LANG: {lang}</u>
                                <input
                                  onChange={(e) => setTestEmail(e.target.value)}
                                  value={testEmail}
                                  className="w100"
                                  type="text"
                                />
                                <Button
                                  small
                                  loading={isLoading}
                                  text="send test"
                                  onClick={async () => {
                                    setIsLoading(true);
                                    try {
                                      await sendTestEmail({
                                        type: email.type,
                                        to: testEmail,
                                        lang: lang,
                                      });
                                    } catch (error) {
                                      alert(error);
                                    }
                                    setIsLoading(false);
                                  }}
                                />
                              </div>
                              <div className="d-flex align-items-center gap-3">
                                Subject:
                                <input
                                  className="w100"
                                  type="text"
                                  value={config[lang].subject}
                                  onChange={(e) => {
                                    setEdited((edited) => {
                                      edited.push(email.type);
                                      return edited;
                                    });
                                    setEmails(
                                      emails.map((_email) => {
                                        if (email.type === _email.type) {
                                          (_email.translations as any)[
                                            lang
                                          ].subject = e.target.value;
                                        }

                                        return _email;
                                      })
                                    );
                                  }}
                                />
                              </div>

                              <div>
                                <div className="mb-2">Messages:</div>
                                {config[lang].messages.map(
                                  (m: string, index: number) => {
                                    return (
                                      <div
                                        key={
                                          "email_" +
                                          email._id +
                                          "_lang_" +
                                          lang +
                                          "_" +
                                          index
                                        }
                                      >
                                        <div className="d-flex gap-3 mb-1 ESTO">
                                          <strong>[{index}]</strong>
                                          <textarea
                                            onInput={autoGrowTextArea}
                                            name=""
                                            id=""
                                            className="w100"
                                            value={m}
                                            onChange={(e) => {
                                              setEdited((edited) => {
                                                edited.push(email.type);
                                                return edited;
                                              });
                                              setEmails(
                                                emails.map((_email) => {
                                                  if (
                                                    email.type === _email.type
                                                  ) {
                                                    (
                                                      _email.translations as any
                                                    )[lang].messages[index] =
                                                      e.target.value;
                                                  }

                                                  return _email;
                                                })
                                              );
                                            }}
                                          >
                                            {m}
                                          </textarea>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                                <Button
                                  text="add message"
                                  small
                                  onClick={() => {
                                    setEmails(
                                      emails.map((_email) => {
                                        if (email.type === _email.type) {
                                          (_email.translations as any)[
                                            lang
                                          ].messages.push("");
                                        }

                                        return _email;
                                      })
                                    );
                                  }}
                                />
                                <div className="d-flex gap-3 my-3">
                                  Button:{" "}
                                  <input
                                    className="w100"
                                    type="text"
                                    value={config[lang].buttonText}
                                    onChange={(e) => {
                                      setEdited((edited) => {
                                        edited.push(email.type);
                                        return edited;
                                      });
                                      setEmails(
                                        emails.map((_email) => {
                                          if (email.type === _email.type) {
                                            (_email.translations as any)[
                                              lang
                                            ].buttonText = e.target.value;
                                          }

                                          return _email;
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}

                      <div className="ml-auto">
                        <Button
                          loading={isLoading}
                          onClick={() => saveEmail(email)}
                          light={!edited.includes(email.type)}
                          disabled={!edited.includes(email.type)}
                          text={"Save " + email.type}
                        />
                      </div>

                      <hr />
                    </div>
                  </div>
                ))}
              </div>
              <Pagination
                page={page}
                size={size}
                total={total}
                onSizeChange={onSizeChange}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <p>{t("pagination.no_show")}</p>
          )}
        </div>
      </div>
    </main>
  );
}
