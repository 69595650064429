import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectCollection } from "../../../store/collectionSlice";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { GROUP_PERMISSIONS, iGroup } from "../../../services/groups";
import {
  COLLECTION_PERMISSIONS,
  iCollection,
} from "../../../services/collection";
import {
  BID_STATUS,
  cancelBid,
  getBidsPaginated,
  iBid,
  payloadHotelType,
} from "../../../services/nft";
import {
  PAGE_DIM,
  datetimeToString,
  formToObject,
  scrollToTopList,
} from "../../../utils/generic";
import { closeModal, openModal } from "../../../components/modal/Modal";
import DropdownMenu from "../../../components/dom/DropdownMenu";
import Button from "../../../components/dom/Button";
import NftModal from "../NftModal";
import Pagination from "../../../components/dom/Pagination";
import Loader from "../../../components/dom/Loader";

import viewSvg from "../../../assets/icons/view.svg";
import cleftSvg from "../../../assets/icons/cleft.svg";
import crightSvg from "../../../assets/icons/cright.svg";
import { BuybackModal } from "./CollectionNfts";

interface DeleteOfferModalProps {
  offer: iBid;
  callback?: Function;
}

function DeleteOfferModal({ offer, callback }: DeleteOfferModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const deleteOfferSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const payload = formToObject(e.target);
      await cancelBid(payload);

      callback?.();
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <section id="remove-user-group-modal">
      <span className="title">
        {t("offer.delete_confirm")}{" "}
        {String(offer._id).substring(String(offer._id).length - 6)}
      </span>
      <form onSubmit={deleteOfferSubmit}>
        <input
          type="text"
          required
          hidden
          name="bidId"
          defaultValue={offer._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("offer.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("offer.confirm_delete_button"))}
            loading={isLoading}
          />
        </div>
      </form>
    </section>
  );
}

export default function CollectionOffers() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const collection = useSelector(selectCollection);
  const group = useSelector(selectGroup);

  const elementRef = useRef<HTMLTableElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState<iBid<payloadHotelType>[]>([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const canHandleOffers = (group: iGroup, collection: iCollection) => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollPerms = collection.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollPerms?.includes(COLLECTION_PERMISSIONS.sale)
    )
      return true;

    return false;
  };

  const loadOffers = async () => {
    setIsLoading(true);
    try {
      const offers = await getBidsPaginated({
        page,
        size,
        collectionId: collection._id,
      });

      setTotal(offers.total || 0);

      setOffers(offers.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  useEffect(() => {
    if (canHandleOffers(group, collection)) loadOffers();
    // eslint-disable-next-line
  }, [page, size]);

  const canHandleSale = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollPerms = collection.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollPerms?.includes(COLLECTION_PERMISSIONS.sale)
    )
      return true;

    return false;
  };

  if (!collection._id) return <></>;
  if (!group._id) return <></>;

  if (!canHandleSale())
    return (
      <section className="collection-sub-page">
        {t("collection.no_permissions")}
      </section>
    );

  if (isLoading) return <Loader />;

  return (
    <section className="collection-sub-page">
      {offers.length ? (
        <>
          <table ref={elementRef}>
            <thead>
              <tr>
                <th></th>
                <th>{t("offer.id")}</th>
                <th>{t("offer.nft_id")}</th>
                <th>{t("offer.dates")}</th>
                <th>{t("offer.rooms")}</th>
                <th>{t("offer.guests")}</th>
                <th>{t("offer.price")}</th>
                <th>{t("offer.valid_until")}</th>
                <th>{t("offer.status")}</th>
                <th>{t("offer.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {offers.map((offer) => {
                return (
                  <tr key={Math.random()}>
                    <td>
                      {offer._nft?._id ? (
                        <img
                          className="view-icon"
                          onClick={() => {
                            openModal(<NftModal nftId={offer._nft._id!} />);
                          }}
                          src={viewSvg}
                          alt="eye icon"
                        />
                      ) : null}
                    </td>
                    <td>
                      {String(offer._id).substring(
                        String(offer._id).length - 6
                      )}
                    </td>
                    <td>
                      {offer._nft?._id
                        ? String(offer._nft?._id).substring(
                            String(offer._nft?._id).length - 6
                          )
                        : "DELETED"}
                    </td>
                    <td>
                      {datetimeToString(
                        offer._nft?.payload?.checkin,
                        undefined,
                        true
                      )}{" "}
                      -{" "}
                      {datetimeToString(
                        offer._nft?.payload?.checkout,
                        undefined,
                        true
                      )}
                    </td>
                    <td>
                      {offer._nft?.payload?.rooms?.map((r) => r.name).join(",")}
                    </td>
                    <td>
                      {offer._nft?.payload?.rooms?.reduce((total, room) => {
                        return (
                          total +
                          (room.guestsAdults ?? 0) +
                          (room.guestsKids ?? 0)
                        );
                      }, 0)}
                    </td>
                    <td>{offer.amount.toFixed(2)} €</td>
                    <td>
                      {datetimeToString(offer.expireDate, undefined, true)}
                    </td>
                    <td>{offer.status}</td>

                    <td>
                      <DropdownMenu
                        position="down-left"
                        small
                        hovered
                        options={[
                          {
                            disabled: offer.status !== BID_STATUS.PENDING,
                            text: t("offer.delete"),
                            red: true,
                            action: () => {
                              openModal(
                                <DeleteOfferModal
                                  offer={offer}
                                  callback={loadOffers}
                                />
                              );
                            },
                          },
                          {
                            disabled:
                              offer.status === BID_STATUS.PENDING ||
                              offer.status === BID_STATUS.ACCEPTED,
                            text: t("offer.remake"),
                            action: () => {
                              openModal(
                                <BuybackModal
                                  defaultMode="offer"
                                  amount={offer.amount}
                                  nft={offer._nft}
                                  callback={loadOffers}
                                />
                              );
                            },
                          },
                        ]}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            page={page}
            size={size}
            total={total}
            onSizeChange={onSizeChange}
            onPageChange={onPageChange}
          />
        </>
      ) : (
        <p>{t("pagination.no_show")}</p>
      )}

      <div className="d-flex justify-content-end">
        {offers.length >= PAGE_DIM || page > 1 ? (
          <div className="page">
            <img
              onClick={() => {
                if (page <= 1) return;
                setPage(page - 1);
              }}
              src={cleftSvg}
              alt="chevron icon"
              className={`chevron ${page > 1 ? "" : "disabled"}`}
            />
            <span>
              {t("nft.page")} {page}
            </span>
            <img
              onClick={() => {
                if (offers.length < PAGE_DIM) return;
                setPage(page + 1);
              }}
              src={crightSvg}
              alt="chevron icon"
              className={`chevron ${
                offers.length >= PAGE_DIM ? "" : "disabled"
              }`}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
}
