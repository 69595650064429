import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../components/dom/Button";
import Checkbox from "../../../components/dom/Checkbox";
import Select from "../../../components/dom/Select";
import { patchUser } from "../../../services/auth";
import { LANG } from "../../../services/i18next";
import { selectUser } from "../../../store/userSlice";
import { formToObject } from "../../../utils/generic";

export default function ProfileSettings() {
  const languageOptions = Object.keys(LANG);
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const [profileBtnDisabled, setProfileBtnDisabled] = useState(true);
  const [marketingBtnDisabled, setMarketingBtnDisabled] = useState(true);
  const [langBtnDisabled, setLangBtnDisabled] = useState(true);

  const profileFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await patchUser(data);
      setProfileBtnDisabled(true);
      setMarketingBtnDisabled(true);
      setLangBtnDisabled(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <section>
      <span className="title">{t("profile.profile_info")}</span>
      <form onSubmit={(e) => profileFormSubmit(e)}>
        <label htmlFor="profile-form-first-name">
          {t("profile.first_name")}
        </label>
        <input
          onInput={() => setProfileBtnDisabled(false)}
          id="profile-form-first-name"
          defaultValue={user.firstName}
          name="firstName"
          type="text"
          disabled={isLoading}
        />
        <label htmlFor="profile-form-second-name">
          {t("profile.second_name")}
        </label>
        <input
          onInput={() => setProfileBtnDisabled(false)}
          id="profile-form-second-name"
          defaultValue={user.secondName}
          name="secondName"
          type="text"
          disabled={isLoading}
        />
        <div className="div">
          <Button
            light={profileBtnDisabled}
            disabled={profileBtnDisabled}
            loading={!profileBtnDisabled && isLoading}
            type="submit"
            text={String(t("profile.save"))}
          />
        </div>
      </form>

      <div className="hr"></div>

      <span className="title">{t("profile.email_preferences")}</span>
      <form onSubmit={(e) => profileFormSubmit(e)}>
        <div className="d-flex"></div>
        <label htmlFor="profile-form-marketing">
          {t("profile.marketing_emails")}
        </label>
        <Checkbox
          checked={user.marketingEmails}
          onChange={() => setMarketingBtnDisabled(false)}
          id="profile-form-marketing"
          name="marketingEmails"
        />
        <div className="div">
          <Button
            light={marketingBtnDisabled}
            disabled={marketingBtnDisabled}
            loading={!marketingBtnDisabled && isLoading}
            type="submit"
            text={String(t("profile.save"))}
          />
        </div>
      </form>

      <div className="hr"></div>
      <span className="title">{t("profile.generic")}</span>
      <form onSubmit={(e) => profileFormSubmit(e)}>
        {t("profile.language")}
        <div>
          <Select
            id="profile-form-lang"
            className="my-2"
            name="lang"
            onChange={() => setLangBtnDisabled(false)}
            defaultValue={user.lang}
            options={languageOptions.map((l) => {
              return {
                value: l,
                text: l,
              };
            })}
          />
        </div>
        <div className="div">
          <Button
            light={langBtnDisabled}
            disabled={langBtnDisabled}
            loading={!langBtnDisabled && isLoading}
            type="submit"
            text={String(t("profile.save"))}
          />
        </div>
      </form>
    </section>
  );
}
