import { FormEvent, useEffect, useRef, useState } from "react";
import {
  deleteHotelBooking,
  enableDisableHotelBooking,
  getHotelPaginated,
  iHotelBE,
  registerNewHotelBooking,
} from "../../services/hotelregistration";
import Loader from "../../components/dom/Loader";
import Button from "../../components/dom/Button";
import { formToObject, PAGE_DIM, scrollToTopList } from "../../utils/generic";
import { closeModal, openModal } from "../../components/modal/Modal";
import Pagination from "../../components/dom/Pagination";
import Select from "../../components/dom/Select";

interface RegistetHotelProps {
  onSuccess: Function;
}

interface EnableDisableHotelProps {
  idCollectionBE: string;
  enable: boolean;
  hotelName: string;
  onSuccess: Function;
}

interface DeleteHotelProps {
  idCollectionBE: string;
  hotelName: string;
  onSuccess: Function;
}

enum BOOKING_ENGINES {
  MIRAI_BOOKING = "Mirai",
  SLOPE_BOOKING = "Slope",
  VERTICAL_BOOKING = "VerticalBooking",
}

const bookingEngine = Object.entries(BOOKING_ENGINES).map(([key, value]) => {
  return {
    value: value,
    text: value,
    key: key,
  };
});

function RegisterNewHotelModal({ onSuccess }: RegistetHotelProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [enabel, setEnabel] = useState(false);
  const [bookingEngineList, setBookingEngineList] = useState<
    BOOKING_ENGINES | ""
  >("");

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = formToObject(e.target);

      console.log(data.bookingEngine);

      /*if (
        !data.bookingEngine ||
        !data.hotelName ||
        !data.bookingEngineHotelId
      ) {
        alert("Please fill all required fields");
        setIsLoading(false);
        return;
      }*/

      await registerNewHotelBooking(data);

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <form onSubmit={submit} className="p-3">
      <label htmlFor="hotelName">Hotel Name</label>
      <input type="text" name="hotelName" />

      <label htmlFor="bookingEngine">Booking Engine</label>
      <Select
        name="bookingEngine"
        value={bookingEngineList}
        defaultValue={""}
        onChange={(e) => {
          setBookingEngineList(e.target.value as BOOKING_ENGINES);
        }}
        id="bookingEngine-form"
        options={[{ value: "", text: "Seleziona" }, ...bookingEngine]}
      />
      <br />

      <label htmlFor="bookingEngineHotelId">Booking Engine Hotel Id </label>
      <input type="text" name="bookingEngineHotelId" />

      <label htmlFor="structureToken">Structure Token</label>
      <input type="text" name="structureToken" />

      <label htmlFor="structureUser">Structure User</label>
      <input type="text" name="structureUser" />

      <label htmlFor="structurePassword">Structure Password</label>
      <input type="password" name="structurePassword" />

      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-center gap-2">
          <label className="m-3">
            <span className="h5">Enable</span>
          </label>
          <input
            className="m-3"
            type="checkbox"
            name="enable"
            id="enable"
            checked={enabel}
            onChange={(e) => setEnabel(e.target.checked)}
          />
        </div>
        <div className="d-flex gap-2">
          <Button
            onClick={() => closeModal()}
            light
            disabled={isLoading}
            text={String("Cancel")}
          />
          <Button type="submit" loading={isLoading} text={String("Submit")} />
        </div>
      </div>
    </form>
  );
}

function EnableDisableHotelBE({
  idCollectionBE,
  enable,
  hotelName,
  onSuccess,
}: EnableDisableHotelProps) {
  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    setIsLoading(true);
    try {
      await enableDisableHotelBooking({
        idCollection: idCollectionBE,
        enable: enable,
      });

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="d-flex flex-column align-items-center p-3 pt-0">
      <p>Are you sure you want to activate/deactivate the hotel {hotelName}?</p>

      <div className="d-flex gap-3">
        <Button
          loading={isLoading}
          text="Yes"
          light
          onClick={() => confirm()}
        />
        <Button
          loading={isLoading}
          text="No"
          error
          onClick={() => closeModal()}
        />
      </div>
    </div>
  );
}

function DeleteHotelBE({
  idCollectionBE,
  hotelName,
  onSuccess,
}: DeleteHotelProps) {
  const [isLoading, setIsLoading] = useState(false);

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      await deleteHotelBooking(idCollectionBE);

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="d-flex flex-column align-items-center p-3 pt-0">
      <p>Are you sure you want to delete the hotel {hotelName}?</p>

      <div className="d-flex gap-3">
        <Button text="Yes" light onClick={() => confirmDelete()} />
        <Button text="No" error onClick={() => closeModal()} />
      </div>
    </div>
  );
}

export default function HotelRegistrationManager() {
  const [isLoading, setIsLoading] = useState(true);
  const [hotels, setHotels] = useState<iHotelBE[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const elementRef = useRef<HTMLTableElement>(null);

  const loadHotel = async () => {
    setIsLoading(true);
    try {
      const data = await getHotelPaginated(0, 0);

      setTotal(data.total || 0);
      setHotels(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  useEffect(() => {
    loadHotel();
  }, []);

  return (
    <main>
      {isLoading && (
        <div className="loaderFull">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Loader />
          </div>
        </div>
      )}
      <div className="container">
        <div className="content">
          <br />
          <div className="d-flex gap-3">
            <Button
              text="Registra hotel"
              onClick={() =>
                openModal(<RegisterNewHotelModal onSuccess={loadHotel} />)
              }
            />
          </div>
          <br />
          <table>
            <thead>
              <tr>
                <th>Nome Hotel</th>
                <th>Booking Engine</th>
                <th>B.E. HotelId</th>
                <th>Enable</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {hotels?.map((hotel, key) => {
                return (
                  <tr key={"hotel_" + key}>
                    <td>{hotel.hotelName}</td>
                    <td>{hotel.bookingEngine}</td>
                    <td>{hotel.bookingEngineHotelId}</td>
                    <td>{hotel.enable ? "🟢" : "🔴"}</td>
                    <td>
                      <Button
                        onClick={() =>
                          openModal(
                            <EnableDisableHotelBE
                              idCollectionBE={hotel._id}
                              enable={!hotel.enable}
                              hotelName={hotel.hotelName}
                              onSuccess={loadHotel}
                            />
                          )
                        }
                        small
                        text={hotel.enable ? "Disable" : "Enable"}
                      />

                      <Button
                        onClick={() =>
                          openModal(
                            <DeleteHotelBE
                              idCollectionBE={hotel._id}
                              hotelName={hotel.hotelName}
                              onSuccess={loadHotel}
                            />
                          )
                        }
                        small
                        error
                        text={"Delete"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            page={page}
            size={size}
            total={total}
            onSizeChange={onSizeChange}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </main>
  );
}
