import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getCollections,
  iCollection,
  loadCollectionProps,
} from "../../services/collection";
import { scrollToTopList } from "../../utils/generic";
import Pagination from "../../components/dom/Pagination";
import Button from "../../components/dom/Button";
import Loader from "../../components/dom/Loader";
import ReportsPage from "./ReportsPage";
import { openModal } from "../../components/modal/Modal";
import ReportCreateModal, {
  ReportCreateAllModal,
} from "./Sub/ReportCreateModal";
import searchSvg from "../../assets/icons/search.svg";
import { debounce } from "lodash";


export default function Reports() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState<Array<iCollection>>([]);
  const [isReportsShowed, setIsReportsShowed] = useState<
    iCollection | undefined
  >();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(50);

  const elementRef = useRef<HTMLTableElement>(null);

  const loadCollections = async ({
    page = 1,
    query,
    size,
    sort,
  }: loadCollectionProps) => {

    setIsLoading(true);
    try {
      const data = await getCollections({
        page,
        query,
        size,
        sort,
      });

      setTotal(data.total || 0);

      setCollections(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCollections({ page: page, size: size });
  }, [page, size]);

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  // Search - start
  const [textQuery, setTextQuery] = useState("");

  const searchCallbackDebounced = useRef(debounce(loadCollections, 1000));

  const isQueryGood = (query: string) => {
    return /\S/.test(query) && query.length >= 1;
  };

  const searchInput = (text: string) => {
    if (!text || !isQueryGood(text)) {
      setTextQuery("");
      loadCollections({ page, size });
      return;
    }

    const textQ = {
      $or: [
        { _id: { $regex: text, $options: "i" } },
        { name: { $regex: text, $options: "i" } },
      ],
    };

    // when user start searching
    // we force page to 1
    setPage(1);
    searchCallbackDebounced.current({ page: 1, query: textQ, size });
  };
  // Search - end

  if (isReportsShowed) {
    return (
      <ReportsPage
        collection={isReportsShowed}
        onExit={() => setIsReportsShowed(undefined)}
      />
    );
  }

  // if (isLoading) return <Loader />;

  return (
    <main>
      <div className="container">
        <div className="content">
          <div className="d-flex gap-3 py-3">
            <div className="w-100">
              <div className="search-nft">
                <input
                  value={textQuery}
                  onChange={(e) => {
                    setIsLoading(true);
                    setTextQuery(e.target.value);
                    searchInput(e.target.value);
                  }}
                  className="w100"
                  type="text"
                  placeholder={String(t("collection.search_placeholder"))}
                />
                <img src={searchSvg} alt="search icon" className="icon" />
              </div>
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <Loader/>
                </div>
              ) : null}
            </div>
            <div>
            <Button
              text="Launch generation Job"
              onClick={() => openModal(<ReportCreateAllModal />)}
            />
            </div>
          </div>

          {collections.length ? (
            <>
              <table ref={elementRef}>
                <thead>
                  <tr>
                    <th>{t("reports.collection_name")}</th>
                    <th>{t("reports.collection_id")}</th>
                    <th>{t("reports.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {collections.map((collection, index) => (
                    <tr key={index}>
                      <td>{collection.name}</td>
                      <td>{collection._id}</td>
                      <td>
                        <Button
                          onClick={() => {
                            setIsReportsShowed(collection);
                          }}
                          text="Report"
                          small
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination
                page={page}
                size={size}
                total={total}
                onSizeChange={onSizeChange}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <p>{t("reports.no_show")}</p>
          )}
        </div>
      </div>
    </main>
  );
}
