import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/dom/Button";
import Select from "../../../components/dom/Select";
import {
  COLLECTION_PERMISSIONS,
  patchCollection,
  refreshStoredCollection,
  setTrialDate,
} from "../../../services/collection";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import {
  getAllPaymentMethods,
  getPaymentMethod,
  iPaymentMethod,
  PAYMENT_METHODS_PERMISSIONS,
} from "../../../services/payment";
import { selectCollection } from "../../../store/collectionSlice";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { datetimeToString, formToObject } from "../../../utils/generic";
import { PaymentDetails } from "../../AccountSettings/sub/PaymentSettings";
import CustomDatePicker from "../../../components/dom/CustomDatePicker";

export default function CollectionPayment() {
  const collection = useSelector(selectCollection);
  const group = useSelector(selectGroup);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);
  const [paymentMethod, setPaymentMethod] = useState<iPaymentMethod>();
  const [paymentmethods, setPaymentmethods] = useState<iPaymentMethod[]>([]);

  const [isEdited, setIsEdited] = useState(false);

  const navigate = useNavigate();

  const isAdmin = () => {
    return user.isAdmin;
  };

  const loadPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const data = await getAllPaymentMethods();
      setPaymentmethods(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const { t } = useTranslation();

  const canSeePayment = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.view_payment_data)
    )
      return true;

    return false;
  };

  const loadPaymentMethod = async () => {
    setIsLoading(true);
    try {
      const data = await getPaymentMethod(collection._id);
      setPaymentMethod(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const setPaymentMethodSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await patchCollection(data);

      refreshStoredCollection(collection._id);
      setIsEdited(false);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const setTrialDateSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const payload = formToObject(e.target);

      await setTrialDate(payload);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    if (collection.paymentMethodId) await loadPaymentMethod();
  };

  useEffect(() => {
    if (canSeePayment()) {
      loadPaymentMethods();

      if (collection.paymentMethodId) loadPaymentMethod();
    }

    // eslint-disable-next-line
  }, [collection]);

  if (!canSeePayment()) return <section>{t("collection.no_access")}</section>;

  const isInTrial =
    collection.trialEndDate && new Date(collection.trialEndDate) > new Date();

  return (
    <section className="collection-sub-page collection-payments">
      {user.isAdmin ? (
        <>
          <form onSubmit={setTrialDateSubmit}>
            <label htmlFor="">
              {t("collection.trial_until_date")}{" "}
              {collection.trialEndDate
                ? `(${datetimeToString(collection.trialEndDate)})`
                : ""}
            </label>
            <CustomDatePicker
              name="trialEndDate"
              defaultValue={isInTrial ? collection.trialEndDate : undefined}
            />

            <input
              type="text"
              hidden
              name="collectionId"
              value={collection._id}
            />

            <div>
              <Button
                loading={isLoading}
                text={String(t("collection.save_trial"))}
                type="submit"
              />
            </div>
          </form>
          <hr />
        </>
      ) : null}

      {isInTrial ? (
        <div className="w100 p-3 tooltip-dark">
          {t("collection.trial_text")}{" "}
          {datetimeToString(collection.trialEndDate)}
        </div>
      ) : null}

      <br />
      <form onSubmit={setPaymentMethodSubmit}>
        <label htmlFor="">{t("payment.name")}</label>
        <input
          type="text"
          hidden
          defaultValue={collection._id}
          name="collectionId"
        />
        <Select
          disabled={isLoading}
          name="paymentmethodId"
          onChange={(e: any) => {
            setIsEdited(true);
            setPaymentMethod(
              paymentmethods.find((p) => p._id === e.target.value)
            );
          }}
          className="payment-method-select"
          value={paymentMethod?._id ?? "0"}
          options={[
            {
              value: "0",
              text: String(t("payment.select")),
              disabled: true,
            },
            ...paymentmethods.map((p) => {
              return {
                text: isAdmin()
                  ? `${p.name} - ${
                      p.members.find((m) =>
                        m.permissions.includes(
                          PAYMENT_METHODS_PERMISSIONS.admin
                        )
                      )?.email
                    }`
                  : p.name,
                value: p._id,
              };
            }),
          ]}
        />
        <div className="mt-3">
          <Button
            onClick={() => navigate("/account/payment")}
            small
            text={String(t("payment.create_new"))}
          />
        </div>
        <br />
        <div>
          <Button
            type="submit"
            loading={isLoading}
            light={!isEdited}
            // disabled={!isEdited}
            text={String(t("collection.save"))}
          />
        </div>
      </form>
      <hr />
      {paymentMethod && collection.paymentMethodId === paymentMethod._id ? (
        <>
          <br />
          <PaymentDetails
            refresh={loadPaymentMethod}
            collectionId={collection._id}
            noactions
            payment={paymentMethod}
          />
          <hr />
        </>
      ) : null}
    </section>
  );
}
