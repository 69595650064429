import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BillingDetails,
  cancelSubscription,
  createPaymentMethod,
  deletePaymentMethod,
  fulfillPayments,
  getAllPaymentMethods,
  getPendingPayments,
  getPendingPaymentStats,
  getSubscriptionChargeLogs,
  getSubscriptionLink,
  getSubscriptionPortal,
  iPaymentMethod,
  iPendingPayment,
  iSubscriptionChargeLog,
  PAYMENT_METHODS_PERMISSIONS,
} from "../../../services/payment";
import trashImg from "../../../assets/icons/trash.svg";

import Button from "../../../components/dom/Button";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/userSlice";
import { closeModal, openModal } from "../../../components/modal/Modal";
import {
  PAGE_DIM,
  datetimeToString,
  formToObject,
} from "../../../utils/generic";
import cleftSvg from "../../../assets/icons/cleft.svg";
import crightSvg from "../../../assets/icons/cright.svg";
import Loader from "../../../components/dom/Loader";

interface ChargesLogModalProps {
  payment: iPaymentMethod;
  collectionId?: string;
}

export function ChargesLogModal({
  payment,
  collectionId,
}: ChargesLogModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<iSubscriptionChargeLog[]>([]);

  const [start, setStart] = useState(
    new Date(payment.subscriptionCurrentStartDate)
  );
  const [end, setEnd] = useState(new Date(payment.subscriptionCurrentEndDate));

  const { t } = useTranslation();

  const loadLogs = async () => {
    setIsLoading(true);
    try {
      const data = await getSubscriptionChargeLogs(
        payment._id,
        new Date(start),
        new Date(end),
        collectionId
      );

      setLogs(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadLogs();
  }, [start]);

  if (isLoading) return <Loader />;

  return (
    <div className="d-flex flex-column p-3 gap-2">
      <div className="d-flex gap-1 align-items-center mb-1">
        {payment.subscriptionOldDates?.map((e) => (
          <Button
            onClick={() => {
              setEnd(new Date(e.subscriptionCurrentEndDate));
              setStart(new Date(e.subscriptionCurrentStartDate));
            }}
            disabled={
              new Date(e.subscriptionCurrentEndDate).getTime() ===
                new Date(end).getTime() &&
              new Date(e.subscriptionCurrentStartDate).getTime() ===
                new Date(start).getTime()
            }
            light={
              !(
                new Date(e.subscriptionCurrentEndDate).getTime() ===
                  new Date(end).getTime() &&
                new Date(e.subscriptionCurrentStartDate).getTime() ===
                  new Date(start).getTime()
              )
            }
            small
            text={`${datetimeToString(
              e.subscriptionCurrentStartDate,
              undefined,
              true
            )} - ${datetimeToString(
              e.subscriptionCurrentEndDate,
              undefined,
              true
            )}`}
          />
        ))}

        {payment.subscriptionOldDates ? (
          <Button
            onClick={() => {
              setEnd(new Date(payment.subscriptionCurrentEndDate));
              setStart(new Date(payment.subscriptionCurrentStartDate));
            }}
            small
            text={`${datetimeToString(
              payment.subscriptionCurrentStartDate,
              undefined,
              true
            )} - ${datetimeToString(
              payment.subscriptionCurrentEndDate,
              undefined,
              true
            )}`}
            disabled={
              new Date(payment.subscriptionCurrentEndDate).getTime() ===
                new Date(end).getTime() &&
              new Date(payment.subscriptionCurrentStartDate).getTime() ===
                new Date(start).getTime()
            }
            light={
              !(
                new Date(payment.subscriptionCurrentEndDate).getTime() ===
                  new Date(end).getTime() &&
                new Date(payment.subscriptionCurrentStartDate).getTime() ===
                  new Date(start).getTime()
              )
            }
          />
        ) : null}
      </div>
      <div className="text-center">
        <strong>
          {datetimeToString(start)} - {datetimeToString(end)}
        </strong>
      </div>
      <div>
        <small>
          {t("payment.amount_of_charges")}: {logs.length} | {t("payment.total")}
          :{" "}
          {Number(
            logs.reduce(
              (prev, curr) => prev + Math.ceil(curr.amount * 100) / 100,
              0
            )
          ).toFixed(2)}
          €
        </small>
      </div>
      <table>
        <thead>
          <tr>
            <th>{t("payment.charge_reference")}</th>
            <th>{t("payment.charge_amount")}</th>
            <th>{t("payment.charge_scope")}</th>
            <th>{t("payment.charge_date")}</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr>
              <td>{log.nftReference}</td>
              <td>{Number(log.amount).toFixed(2)} €</td>
              <td>{log.scope}</td>
              <td>{datetimeToString(log._createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function CancelSubModalConfirm({ id }: { id: string }) {
  const [isLoading, setIsLoading] = useState(false);

  const cancel = async () => {
    setIsLoading(true);
    try {
      await cancelSubscription({ paymentmethodId: id });

      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <p className="text-center">Are you sure you want to cancel the sub?</p>
      <br />
      <div className="d-flex justify-content-between w100">
        <Button
          disabled={isLoading}
          light
          text="Close"
          onClick={() => closeModal()}
        />
        <Button
          loading={isLoading}
          error
          text="Cancel the sub"
          onClick={() => cancel()}
        />
      </div>
    </div>
  );
}

interface Props {
  payment: iPaymentMethod;
  collectionId?: string;
  onExit?: Function;
  noactions?: boolean;
  refresh?: Function;
  hidePayouts?: boolean;
}

export function PaymentDetails({
  payment,
  onExit,
  noactions,
  collectionId,
  refresh,
  hidePayouts,
}: Props) {
  const { t } = useTranslation();

  const [stats, setStats] = useState<{
    totalAmount: number;
    amountToFulfill: number;
    subscriptionAmount: {
      subscriptionStripeAmount: number;
      subscriptionTakyonAmount: number;
    };
  }>();

  const user = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [payments, setPayments] = useState<iPendingPayment[]>([]);
  const [subPortalLink, setSubPortalLink] = useState("");

  const [sessionLink, setSessionLink] = useState("");

  const getSessionLink = async () => {
    setIsLoading(true);
    try {
      const link = await getSubscriptionLink(payment._id);
      setSessionLink(link);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadSubPortalLink = async () => {
    setIsLoading(true);
    try {
      const link = await getSubscriptionPortal(collectionId ?? payment._id);
      setSubPortalLink(link);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadStats = async () => {
    setIsLoading(true);
    try {
      const data = await getPendingPaymentStats(payment._id, collectionId);
      setStats(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadPendingPayments = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await getPendingPayments(payment._id, page, collectionId);
      setPayments(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadStats();
    if (payment.customerId) loadSubPortalLink();
    else getSessionLink();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadPendingPayments(page);

    // eslint-disable-next-line
  }, [page]);

  const canFulfill = () => {
    return user.isAdmin;
  };

  const fulfillPaymentsClick = async () => {
    setIsLoading(true);
    try {
      await fulfillPayments({ paymentmethodId: payment._id });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadPendingPayments();
    await loadStats();
  };

  const isAdmin = () => {
    return user.isAdmin;
  };

  return (
    <>
      {!noactions ? (
        <>
          <div>
            <Button
              onClick={() => onExit?.()}
              small
              text={String(t("payment.back"))}
            />
          </div>
          <br />
          <span className="title">{payment.name}</span>
        </>
      ) : null}

      <div className="text-center w100">
        <a
          onClick={() =>
            openModal(
              <div className="p-3">
                <form>
                  <BillingForm billing={payment.billingDetails} disabled />
                </form>
              </div>
            )
          }
          className="cursor-pointer selected"
        >
          {String(t("payment.open_billing"))}
        </a>
      </div>
      <div className="hr"></div>

      <>
        <span className="title">{t("payment.partner_to_takyon")}</span>

        <br />

        <p>
          <u>{t("payment.subscription_status")}</u>:{" "}
          {payment.subscriptionStatus === "active" ||
          payment.subscriptionStatus === "trialing"
            ? "🟢"
            : null}
          {payment.subscriptionStatus === "past_due" ? "🟡" : null}{" "}
          {payment.subscriptionStatus ?? t("payment.needs_setup")}
        </p>
        <p>
          {t("payment.subscription_next_invoice")}:{" "}
          {datetimeToString(payment.subscriptionCurrentEndDate)}
        </p>
        {payment.subscriptionCredit ? (
          <p>
            {t("payment.subscription_credits")}:{" "}
            {Number(payment.subscriptionCredit).toFixed(2)} €
          </p>
        ) : null}
        {isAdmin() ? (
          <p>
            {t("payment.subscription_amount_takyon")}:{" "}
            {Number(stats?.subscriptionAmount.subscriptionTakyonAmount).toFixed(
              2
            )}{" "}
            €{" "}
          </p>
        ) : null}
        <p>
          {t("payment.subscription_amount_stripe")}:{" "}
          {Number(stats?.subscriptionAmount.subscriptionStripeAmount).toFixed(
            2
          )}{" "}
          €
        </p>
        <div>
          <a
            id="sub-portal-link"
            href={subPortalLink}
            target={"_blank"}
            hidden
            rel="noreferrer"
          >
            {subPortalLink}
          </a>

          <>
            <a hidden id="subscription-link" href={sessionLink}>
              {" "}
            </a>
            {payment.customerId ? (
              <Button
                onClick={() =>
                  document.getElementById("sub-portal-link")?.click()
                }
                disabled={subPortalLink ? false : true}
                loading={isLoading}
                text={String(t("payment.subscription_portal"))}
              />
            ) : (
              <Button
                onClick={() =>
                  document.getElementById("subscription-link")?.click()
                }
                disabled={sessionLink ? false : true}
                loading={isLoading}
                text={String(t("payment.setup_subscription"))}
              />
            )}
          </>
          {sessionLink || subPortalLink ? null : (
            <i>{String(t("payment.setup_subscription_error"))}</i>
          )}
        </div>
      </>

      <div className="mt-2">
        {isAdmin() ? (
          <div className="d-flex gap-3">
            <Button
              small
              text={String(t("payment.charges"))}
              onClick={() =>
                openModal(
                  <ChargesLogModal
                    payment={payment}
                    collectionId={collectionId}
                  />
                )
              }
            />
            <Button
              small
              error
              text={String(t("payment.cancel sub"))}
              onClick={() =>
                openModal(<CancelSubModalConfirm id={payment._id} />)
              }
            />
          </div>
        ) : null}
      </div>

      <div className="hr"></div>

      {!hidePayouts ? (
        <>
          <span className="title">{t("payment.takyon_to_partner")}</span>
          <br />
          <small>
            <i>{t("payment.takyon_to_partner_info")}</i>
          </small>
          <p className="m-0">
            {t("payment.total_amount")}: {Number(stats?.totalAmount).toFixed(2)}
            €
          </p>
          <p>
            {t("payment.amount_to_fulfill")}:{" "}
            {Number(stats?.amountToFulfill).toFixed(2)}€
          </p>

          {canFulfill() ? (
            <div>
              <Button
                loading={isLoading}
                onClick={() => fulfillPaymentsClick()}
                text={String(t("payment.fulfill_all"))}
              />
            </div>
          ) : null}

          <table className="collection-dev-table mt-3">
            <thead>
              <tr>
                <th>{t("payment.nft")}</th>
                <th>{t("payment.collection")}</th>
                <th>{t("payment.amount")} (€)</th>
                <th>{t("payment.fulfilled")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment, key) => {
                return (
                  <tr key={"collection_" + key}>
                    <td>{payment.nftId}</td>
                    <td>{payment.collectionId}</td>
                    <td>{payment.amount.toFixed(2)}</td>
                    <td>{payment.fulfilled ? "OK" : "-"}</td>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-end">
            {payments.length >= PAGE_DIM || page > 1 ? (
              <div className="page">
                <img
                  onClick={() => {
                    if (page <= 1) return;
                    setPage(page - 1);
                  }}
                  src={cleftSvg}
                  alt="chevron icon"
                  className={`chevron ${page > 1 ? "" : "disabled"}`}
                />
                <span>
                  {t("nft.page")} {page}
                </span>
                <img
                  onClick={() => {
                    if (payments.length < PAGE_DIM) return;
                    setPage(page + 1);
                  }}
                  src={crightSvg}
                  alt="chevron icon"
                  className={`chevron ${
                    payments.length >= PAGE_DIM ? "" : "disabled"
                  }`}
                />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
}

interface BillingProps {
  billing?: BillingDetails;
  disabled?: boolean;
}

export function BillingForm({ billing, disabled }: BillingProps) {
  const { t } = useTranslation();

  return (
    <>
      <p className="title">{t("payment.title")}</p>
      <label htmlFor="">{t("payment.vat")}*</label>
      <input
        disabled={disabled}
        defaultValue={billing?.vat}
        required
        type="text"
        name="billingDetails[vat]"
      />

      <label htmlFor="">{t("payment.fiscal_code")}</label>
      <input
        defaultValue={billing?.fiscalCode}
        disabled={disabled}
        type="text"
        name="billingDetails[fiscalCode]"
      />

      <label htmlFor="">{t("payment.business_name")}*</label>
      <input
        required
        defaultValue={billing?.name}
        disabled={disabled}
        type="text"
        name="billingDetails[name]"
      />

      <div className="d-flex gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.zip")}*</label>
          <input
            defaultValue={billing?.zip}
            required
            disabled={disabled}
            type="text"
            name="billingDetails[zip]"
          />
        </div>

        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.city")}</label>
          <input
            defaultValue={billing?.city}
            disabled={disabled}
            type="text"
            name="billingDetails[city]"
          />
        </div>
      </div>

      <div className="d-flex row gx-2">
        <div className="d-flex flex-column col-10">
          <label htmlFor="">{t("payment.address")}*</label>
          <input
            required
            defaultValue={billing?.address}
            disabled={disabled}
            type="text"
            name="billingDetails[address]"
          />
        </div>
        <div className="d-flex flex-column col-2">
          <label htmlFor="">{t("payment.province")}*</label>
          <input
            defaultValue={billing?.province}
            disabled={disabled}
            type="text"
            name="billingDetails[province]"
          />
        </div>
      </div>

      <div className="d-flex gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.contact_name")}</label>
          <input
            defaultValue={billing?.contactName}
            disabled={disabled}
            type="text"
            name="billingDetails[contactName]"
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.contact_phone")}</label>
          <input
            defaultValue={billing?.contactPhone}
            disabled={disabled}
            type="text"
            name="billingDetails[contactPhone]"
          />
        </div>
      </div>

      <div className="d-flex gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.recipient_code")}*</label>
          <input
            required
            defaultValue={billing?.recipientCode}
            disabled={disabled}
            type="text"
            name="billingDetails[recipientCode]"
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">{t("payment.pec")}*</label>
          <input
            required
            defaultValue={billing?.pec}
            disabled={disabled}
            type="email"
            name="billingDetails[pec]"
          />
        </div>
      </div>
    </>
  );
}

interface iPaymentMethodCreateModal {
  refresh?: Function;
}

function PaymentMethodCreateModal({ refresh }: iPaymentMethodCreateModal) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const createPaymentMethodSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      const paymentMethod = await createPaymentMethod(data);
      const link = await getSubscriptionLink(paymentMethod._id);
      window.location.href = link;
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await refresh?.();
  };

  return (
    <section id="payment-method-modal">
      <span className="title">{t("payment.create_new_text_1")}</span>
      <form onSubmit={(e) => createPaymentMethodSubmit(e)}>
        <label htmlFor="payment-form-name">{t("payment.name")}*</label>
        <input id="payment-form-name" type="text" name="name" required />

        <div className="hr"></div>
        <br />

        <span className="title">{t("payment.create_new_text_2")}</span>

        <BillingForm />

        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("payment.cancel"))}
            disabled={isLoading}
          />
          <Button
            loading={isLoading}
            type="submit"
            className="confirm"
            text={String(t("payment.save"))}
          />
        </div>
      </form>
    </section>
  );
}

export default function PaymentSettings() {
  const { t } = useTranslation();
  const [paymentMethods, setPaymentMethods] = useState<Array<iPaymentMethod>>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);

  const [isDetails, setIsDetails] = useState<iPaymentMethod | undefined>();

  const loadPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const data = await getAllPaymentMethods();
      setPaymentMethods(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPaymentMethods();
    // eslint-disable-next-line
  }, []);

  const isInvited = (payment: iPaymentMethod) => {
    return (
      payment.members.find((e) => e.email === user.email)?.isInviteAccepted ===
      false
    );
  };

  const canDelete = (payment: iPaymentMethod) => {
    if (user.isAdmin) return true;
    return (
      payment.members
        .find((e) => e.email === user.email)
        ?.permissions.includes(PAYMENT_METHODS_PERMISSIONS.delete) ||
      payment.members
        .find((e) => e.email === user.email)
        ?.permissions.includes(PAYMENT_METHODS_PERMISSIONS.admin)
    );
  };

  const deletePaymentErrorModal = (message: string) => {
    return (
      <section id="delete-apikey-modal">
        <strong>
          <span className="title">{t("dev.error_delete")}</span>
        </strong>
        <p>{message}</p>
      </section>
    );
  };

  const deletePaymentMethodSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const payload = formToObject(e.target);
      await deletePaymentMethod(payload);
      closeModal();
    } catch (error: any) {
      closeModal();
      openModal(
        deletePaymentErrorModal(String(error?.response?.data?.message))
      );
    }
    setIsLoading(false);
    loadPaymentMethods();
  };

  const deleteApiKeyConfirmModal = (payment: iPaymentMethod) => (
    <section id="delete-apikey-modal">
      <span className="title">
        {t("dev.confirm_delete")} {payment.name}
      </span>
      <form onSubmit={deletePaymentMethodSubmit}>
        <input
          type="text"
          required
          hidden
          name="paymentmethodId"
          defaultValue={payment._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("dev.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("dev.confirm_delete_button"))}
          />
        </div>
      </form>
    </section>
  );

  if (isDetails)
    return (
      <PaymentDetails
        refresh={loadPaymentMethods}
        onExit={() => setIsDetails(undefined)}
        payment={isDetails}
      />
    );

  return (
    <section>
      <Button
        className="ml-auto"
        loading={isLoading}
        onClick={() =>
          openModal(
            <PaymentMethodCreateModal refresh={() => loadPaymentMethods()} />
          )
        }
        text={String(t("payment.create"))}
        small
      />

      <span className="title">{t("payment.payment_list")}</span>
      <div className="list">
        <div className="group-list">
          {paymentMethods.length === 0 ? (
            <div className="group">{t("payment.no_payment_method")}</div>
          ) : null}
          {paymentMethods.map((payment, key) => {
            return (
              <div
                onClick={() => setIsDetails(payment)}
                className={`group clickable`}
                key={"settings_group_" + key}
              >
                <div className="left">
                  <div className="name">
                    <span className="h6">{payment.name}</span>
                  </div>
                </div>
                <div className="right">
                  {isInvited(payment) ? (
                    <div>
                      <Button
                        // onClick={() => acceptInviteBtn(group._id)}
                        className="my-1"
                        text={String(t("payment.accept_invite"))}
                        small
                      />
                      <Button
                        // onClick={() => declineInviteBtn(group._id)}
                        className="my-1"
                        error
                        text={String(t("payment.decline_invite"))}
                        small
                      />
                    </div>
                  ) : null}
                  {canDelete(payment) ? (
                    <div>
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          openModal(deleteApiKeyConfirmModal(payment));
                        }}
                        className="p-1"
                        style={{ height: "25px", width: "25px" }}
                        src={trashImg}
                        alt="trash icon"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
