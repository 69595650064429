import axios from "axios";
import { setGroup } from "../store/groupSlice";
import store from "./redux";
import { iPaginationProps, Pagination } from "../utils/Pagination";

export enum GROUP_PERMISSIONS {
  admin = "admin",
  delete = "delete",
  update = "update",
  dropship = "dropship",
  memebers = "memebers",
  view_members = "view_members",
  collection = "collection",
  create_collection = "create_collection",
  stealth = "stealth",
}

export const groupRoles: Array<{
  name: string;
  permissions: Array<GROUP_PERMISSIONS>;
  onlyAdmin?: boolean;
}> = [
  // {
  //   name: "administrator",
  //   permissions: [
  //     GROUP_PERMISSIONS.update,
  //     GROUP_PERMISSIONS.memebers,
  //     GROUP_PERMISSIONS.preset,
  //     GROUP_PERMISSIONS.view_members,
  //     GROUP_PERMISSIONS.collection,
  //   ],
  // },
  {
    name: "moderator",
    permissions: [
      GROUP_PERMISSIONS.update,
      GROUP_PERMISSIONS.memebers,
      GROUP_PERMISSIONS.view_members,
      GROUP_PERMISSIONS.collection,
      GROUP_PERMISSIONS.create_collection,
    ],
  },
  {
    name: "rm",
    permissions: [
      GROUP_PERMISSIONS.update,
      GROUP_PERMISSIONS.memebers,
      GROUP_PERMISSIONS.view_members,
    ],
  },
  {
    name: "editor",
    permissions: [
      GROUP_PERMISSIONS.view_members,
      GROUP_PERMISSIONS.create_collection,
    ],
  },
  {
    name: "member",
    permissions: [],
  },
  {
    name: "stealth",
    permissions: [GROUP_PERMISSIONS.stealth],
    onlyAdmin: true,
  },
  // {
  //   name: "api",
  //   permissions: [GROUP_PERMISSIONS.collection],
  // },
];

export interface groupMember {
  email: string;
  permissions: Array<GROUP_PERMISSIONS>;
  isInviteAccepted: boolean;
}

export interface iGroup {
  _id: string;
  name: string;
  verified: boolean;
  members: Array<groupMember>;
  feeOnCreation?: number;
  feeFixedOnCreation?: number;
  feeMonthly?: number;
  creatorFee?: number;
}

export async function refreshStoredGroup(id: string): Promise<iGroup> {
  const group = await getGroup(id);
  store.dispatch(setGroup(group));
  return group;
}

export async function getGroupsImIn(
  params: iPaginationProps
): Promise<Pagination<iGroup>> {
  const response = await axios.get("/v2/group", { params });
  return response.data;
}

export async function getGroups(
  params: iPaginationProps
): Promise<Pagination<iGroup>> {
  const response = await axios.get("/group/paginate", { params });
  return response.data;
}

export async function createGroup(payload: any): Promise<iGroup> {
  const response = await axios.post("/group", payload);
  return response.data;
}

export async function getGroup(id: string): Promise<iGroup> {
  const response = await axios.get("/group/" + id);
  return response.data;
}

export async function patchGroup(payload: any): Promise<iGroup> {
  const response = await axios.patch("/group", payload);
  return response.data;
}

export async function inviteMemberToGroup(payload: any): Promise<iGroup> {
  const response = await axios.post("/group/member", payload);
  return response.data;
}

export async function removeMemberFromGroup(payload: any): Promise<any> {
  const response = await axios.delete("/group/member", { data: payload });
  return response.data;
}

export async function verifyGroup(payload: any): Promise<iGroup> {
  const response = await axios.patch("/group/verify", payload);
  return response.data;
}

export async function setGroupPermissionsPerUser(payload: any): Promise<any> {
  const response = await axios.patch("/group/member", payload);
  return response.data;
}

export async function acceptInviteGroup(payload: any): Promise<any> {
  const response = await axios.post("/group/join", payload);
  return response.data;
}

export async function declineInviteGroup(payload: any): Promise<any> {
  const response = await axios.post("/group/decline", payload);
  return response.data;
}

export async function leaveGroup(payload: any): Promise<any> {
  const response = await axios.post("/group/leave", payload);
  return response.data;
}

export async function deleteGroup(payload: any): Promise<any> {
  const response = await axios.delete("/group", { data: payload });
  return response.data;
}

export async function transferGroup(payload: any): Promise<any> {
  const response = await axios.post("/group/transfer-ownership", payload);
  return response.data;
}
