import { useTranslation } from "react-i18next";
import { FormEvent, useState } from "react";

import { deleteDocument, iDocument } from "../../../services/documents";
import { closeModal } from "../../../components/modal/Modal";
import Button from "../../../components/dom/Button";

interface DocProps {
  doc: iDocument;
  onSuccess: Function;
}

export default function DeleteDocModal({ doc, onSuccess }: DocProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteDocSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await deleteDocument({
        _id: doc._id,
      });

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div id="faq-modal">
      <form onSubmit={deleteDocSubmit} className="modal-form">
        <span className="message">{t("documents.delete_doc_confirm_1")}</span>
        <span className="message bold">
          {t("documents.delete_doc_confirm_2")}
        </span>

        <Button
          className="btn btn-primary"
          text={String(t("documents.delete"))}
          type="submit"
          loading={isLoading}
        />
      </form>
    </div>
  );
}
