import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../components/dom/Button";
import {
  GROUP_PERMISSIONS,
  iGroup,
  patchGroup,
  refreshStoredGroup,
} from "../../../services/groups";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { formToObject } from "../../../utils/generic";

export default function GroupInfo() {
  const group = useSelector(selectGroup);
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isInfoButtonDisabled, setIsInfoButtonDisabled] = useState(true);

  const { t } = useTranslation();

  const canUpdate = (group: iGroup) => {
    if (user.isAdmin) return true;

    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (
      myPerms.includes(GROUP_PERMISSIONS.admin) ||
      myPerms.includes(GROUP_PERMISSIONS.update)
    )
      return true;

    return false;
  };

  const infoGroupFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      const group = await patchGroup(data);
      await refreshStoredGroup(group._id);
      setIsInfoButtonDisabled(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const isAdmin = (): boolean => {
    if (user.isAdmin) return true;
    return false;
  };

  return (
    <section className="group-sub-page">
      <span className="title">{t("group.information")}</span>
      <form onSubmit={(e) => infoGroupFormSubmit(e)}>
        <label htmlFor="info-group-form-name">{t("group.name")}</label>
        <input
          id="info-group-form-name"
          onInput={() => setIsInfoButtonDisabled(false)}
          type="text"
          required
          defaultValue={group.name}
          name="name"
          disabled={isLoading}
          readOnly={!canUpdate(group)}
        />
        {isAdmin() ? (
          <>
            <label htmlFor="">{t("group.fee_on_creation")}</label>
            <input
              onInput={() => setIsInfoButtonDisabled(false)}
              type="number"
              name="feeOnCreation"
              step={0.0001}
              min={0}
              defaultValue={group.feeOnCreation}
              disabled={!canUpdate(group)}
            />
            <label htmlFor="">{t("group.monthly_fee")}</label>
            <input
              onInput={() => setIsInfoButtonDisabled(false)}
              type="number"
              name="feeMonthly"
              step={0.0001}
              min={0}
              defaultValue={group.feeMonthly}
              disabled={!canUpdate(group)}
            />
            <label htmlFor="">{t("group.fixed_fee_on_creation")}</label>
            <input
              onInput={() => setIsInfoButtonDisabled(false)}
              type="number"
              name="feeFixedOnCreation"
              step={0.0001}
              min={0}
              defaultValue={group.feeFixedOnCreation}
              disabled={!canUpdate(group)}
            />
          </>
        ) : null}
        {isAdmin() ? (
          <>
            <label htmlFor="">{t("group.creator_fee")}</label>
            <input
              onInput={() => setIsInfoButtonDisabled(false)}
              type="number"
              name="creatorFee"
              step={0.0001}
              min={0}
              defaultValue={group.creatorFee}
              disabled={!canUpdate(group)}
            />
          </>
        ) : null}
        <input
          type="text"
          required
          name="groupId"
          hidden
          defaultValue={group._id}
        />
        {canUpdate(group) ? (
          <div>
            <Button
              light={isInfoButtonDisabled}
              loading={!isInfoButtonDisabled && isLoading}
              disabled={isInfoButtonDisabled}
              text={String(t("group.save"))}
              type="submit"
            />
          </div>
        ) : null}
      </form>
    </section>
  );
}
