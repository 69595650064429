import { useSelector } from "react-redux";
import store from "../../services/redux";
import {
  selectModal,
  selectModalAvoidCloseBtn,
  selectModalPreventClose,
  setModal,
  setModalAvoidCloseBtn,
  setModalPreventClose,
} from "../../store/appSlice";
import closeSvg from "../../assets/icons/close.svg";
import { toggleScroll } from "../../utils/generic";

export function openModal(
  element: JSX.Element,
  preventClose = true,
  avoidCloseBtn = false
) {
  toggleScroll(false);
  store.dispatch(setModal(element));
  store.dispatch(setModalPreventClose(preventClose));
  store.dispatch(setModalAvoidCloseBtn(avoidCloseBtn));
}

export function closeModal() {
  toggleScroll(true);
  store.dispatch(setModal(null));
}

export default function Modal() {
  const modal = useSelector(selectModal);
  const modalPreventClose = useSelector(selectModalPreventClose);
  const modalAvoidCloseBtn = useSelector(selectModalAvoidCloseBtn);

  if (!modal) return <></>;

  return (
    <div
      onClick={() => {
        if (!modalPreventClose) closeModal();
      }}
      id="modal-container"
    >
      <div className="modal-middle">
        {!modalAvoidCloseBtn ? (
          <img
            onClick={() => closeModal()}
            src={closeSvg}
            alt="close icon"
            className="close-modal"
          />
        ) : null}

        <div onClick={(e) => e.stopPropagation()} className="modal-content">
          {modal}
        </div>
      </div>
    </div>
  );
}
