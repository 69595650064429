import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

import {
  COLLECTION_PERMISSIONS,
  createFakeHotelSupply,
  iCollection,
  iCreateFakeHotelSupply,
} from "../../../services/collection";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import {
  buyback,
  buybackOffer,
  createNft,
  deleteNft,
  getExport,
  getNftsOfCollectionPaginated,
  iNft,
  NFT_OWNER_TYPE,
  NFT_SELL_STATUS,
} from "../../../services/nft";
import { selectCollection } from "../../../store/collectionSlice";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import {
  PAGE_DIM,
  csvToObj,
  datetimeToString,
  downloadExcel,
  formToObject,
  scrollToTopList,
  sleep,
} from "../../../utils/generic";
import { closeModal, openModal } from "../../../components/modal/Modal";
import { DEFAULT_OVERPRICE } from "../../../services/fees";
import CustomDatePicker from "../../../components/dom/CustomDatePicker";
import NftModal from "../NftModal";
import Pagination from "../../../components/dom/Pagination";
import Button from "../../../components/dom/Button";
import DropdownMenu from "../../../components/dom/DropdownMenu";
import Loader from "../../../components/dom/Loader";

import searchSvg from "../../../assets/icons/search.svg";
import viewSvg from "../../../assets/icons/view.svg";
import { getPaymentMethod, iPaymentMethod } from "../../../services/payment";
import { LANG } from "../../../services/i18next";
import UpsertExperience from "../../../components/createnft/UpsertExperience";

function CreateFakeHotelSupplyModal({
  collection,
  onCreate,
}: {
  collection: iCollection;
  onCreate(): any;
}) {
  const [data, setData] = useState<iCreateFakeHotelSupply>({
    collectionId: collection._id,
    guestsAmount: 0,
    guestsKidsAmount: 0,
    nightsAmount: 0,
    startFromDate: "",
    roomType: "",
    owner: "",
    putForSale: false,
    price: 0,
    discountFromMarket: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [roomNamesSuggestion, setRoomNamesSuggestion] = useState<string[]>([]);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }, [success]);

  useEffect(() => {
    const names: string[] = [];

    if (!collection.census?.info) return;

    const langs = Object.keys(collection.census.info);

    langs.forEach((lang) => {
      const rooms = (collection.census?.info as any)[lang as LANG]?.rooms;
      if (!rooms?.length) return;

      const roomNames = rooms.map((room: any) => room.name);

      names.push(...roomNames);
    });

    setRoomNamesSuggestion(names);
  }, [collection]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type, checked } = e.target as any;
    setData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await createFakeHotelSupply(data);
      setError("");
      onCreate();
    } catch (error) {
      console.log(error);

      setError((error as any).response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <form onSubmit={submit}>
        <label htmlFor="guestsAmount">Guests Amount</label>
        <input
          id="guestsAmount"
          type="number"
          name="guestsAmount"
          value={data.guestsAmount}
          onChange={handleChange}
          placeholder="Guests Amount"
        />

        <label htmlFor="guestsKidsAmount">Guests Kids Amount</label>
        <input
          id="guestsKidsAmount"
          type="number"
          name="guestsKidsAmount"
          value={data.guestsKidsAmount}
          onChange={handleChange}
          placeholder="Guests Kids Amount"
        />

        <label htmlFor="nightsAmount">Nights Amount</label>
        <input
          id="nightsAmount"
          type="number"
          name="nightsAmount"
          value={data.nightsAmount}
          onChange={handleChange}
          placeholder="Nights Amount"
        />

        <label htmlFor="startFromDate">Start From Date</label>
        <input
          id="startFromDate"
          type="date"
          name="startFromDate"
          value={data.startFromDate}
          onChange={handleChange}
        />

        <label htmlFor="roomType">Room Type</label>
        <div className="d-flex flex-column">
          <input
            id="roomType"
            list="rooms-data"
            name="roomType"
            disabled={isLoading}
            onChange={handleChange}
            value={data.roomType}
            placeholder="Room Type"
          />

          {collection.census?.info && (
            <datalist id="rooms-data">
              {roomNamesSuggestion.map((name, index) => (
                <option key={index} value={name} />
              ))}
            </datalist>
          )}
        </div>

        <label htmlFor="owner">Owner</label>
        <input
          id="owner"
          name="owner"
          value={data.owner}
          onChange={handleChange}
          placeholder="Owner"
        />

        <div>
          <label htmlFor="putForSale">Put For Sale</label>
          <input
            id="putForSale"
            type="checkbox"
            name="putForSale"
            checked={data.putForSale}
            onChange={handleChange}
          />
        </div>

        <label htmlFor="price">Market price</label>
        <input
          id="price"
          type="number"
          name="price"
          value={data.price}
          onChange={handleChange}
          placeholder="Discount From Market"
          step={0.01}
        />

        <label htmlFor="discountFromMarket">Discount From Market</label>
        <input
          id="discountFromMarket"
          type="number"
          name="discountFromMarket"
          value={data.discountFromMarket}
          onChange={handleChange}
          placeholder="Discount From Market"
          step={0.01}
        />

        <Button type="submit" text="Create" loading={isLoading} />

        {success ? <p className="">SUCCESS!</p> : null}

        <p className="text-danger">{error}</p>
      </form>
    </div>
  );
}

function ExportModal() {
  const [isLoading, setIsLoading] = useState(false);
  const collection = useSelector(selectCollection);
  const group = useSelector(selectCollection);
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState<iPaymentMethod>();

  const loadPaymentMethod = async () => {
    setIsLoading(true);
    try {
      const data = await getPaymentMethod(collection._id);
      setPaymentMethod(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPaymentMethod();

    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = formToObject(e.target);

      const data = await getExport({
        collectionId: collection._id,
        start: formData.start,
        end: formData.end,
      });

      const formattedData = data.map((nft) => {
        return {
          SUBMIT_DATE: datetimeToString(
            nft.realCreationDate,
            undefined,
            undefined,
            true
          ),
          CREATION_DATE: datetimeToString(
            nft._createdAt,
            undefined,
            undefined,
            true
          ),
          BOOKING_NUMBER: nft.payload?.reference,
          OWNER: nft.owner,
          ROOMS: nft.payload?.rooms?.reduce(
            (a: any, b: any) => `${b.name} | ${a}`,
            ""
          ),
          CHECKIN: datetimeToString(nft.payload?.checkin),
          CHECKOUT: datetimeToString(nft.payload?.checkout),
          PRICE: Number(nft.originalPrice).toFixed(2),
          FEE: Number(
            (nft.originalPrice ?? 0) *
              (collection.feeOnCreation ?? group.feeOnCreation ?? 0)
          ).toFixed(2),
        };
      });

      downloadExcel(
        formattedData,
        `EXPORT ${datetimeToString(formData.start)} - ${datetimeToString(
          formData.end
        )}`
      );
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3" style={{ minHeight: "500px" }}>
      <div className="d-flex justify-content-center">
        <span className="h5">{t("nft.export_title")}</span>
      </div>
      <br />

      {paymentMethod?.subscriptionCurrentStartDate ? (
        <p>
          {t("nft.export_disclaimer_1")}{" "}
          {new Date(paymentMethod?.subscriptionCurrentStartDate).getDate()}{" "}
          {t("nft.export_disclaimer_2")}
        </p>
      ) : null}
      <form onSubmit={handleSubmit}>
        <div className="d-flex gap-3">
          <div>
            <label htmlFor="">{t("nft.export_date_start")}</label>
            <CustomDatePicker
              defaultValue={paymentMethod?.subscriptionCurrentStartDate}
              name="start"
            />
          </div>
          <div>
            <label htmlFor="">{t("nft.export_date_end")}</label>
            <CustomDatePicker
              defaultValue={paymentMethod?.subscriptionCurrentEndDate}
              name="end"
            />
          </div>
        </div>

        <Button
          loading={isLoading}
          type="submit"
          text={String(t("nft.export_submit"))}
        />
      </form>
    </div>
  );
}

interface iBuybackModal {
  nft: iNft;
  callback?: Function;
  amount?: number;
  defaultMode?: "buy" | "offer";
}

export function BuybackModal({
  nft,
  callback,
  amount,
  defaultMode,
}: iBuybackModal) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState<"buy" | "offer">(defaultMode ?? "buy");
  const [offerValue, setOfferValue] = useState(
    amount ?? Number((nft.currentPrice ?? nft.originalPrice)?.toFixed(2))
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();
  const collection = useSelector(selectCollection);

  const stripeFees = (nft.currentPrice ?? 0) * DEFAULT_OVERPRICE;
  const price = nft.currentPrice ?? 0;
  const paymentAmount = price + stripeFees;

  const buybackSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const payload = formToObject(e.target);
      await buyback(payload);

      closeModal();
      callback?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const buybackOfferSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // logic here
      const payload = formToObject(e.target);
      await buybackOffer(payload);

      closeModal();
      // callback?.();

      navigate(`/collection/${collection._id}/offers`);
    } catch (error: any) {
      const e = String(error?.response?.data?.message);

      if (e.includes("already exists"))
        setError(String(t("nft.buyback_offer_already_exists")));
      else if (e) setError(e);
      else setError(String(t("nft.buyback_generic_error")));

      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <div className="d-flex gap-2 mb-3">
        <Button
          small
          text={String(t("nft.buyback_buy"))}
          disabled={mode === "buy"}
          onClick={() => setMode("buy")}
          light={mode !== "buy"}
        />
        <Button
          small
          text={String(t("nft.buyback_offer"))}
          disabled={mode === "offer"}
          onClick={() => setMode("offer")}
          light={mode !== "offer"}
        />
      </div>
      <hr />
      <p className="m-0">{t("nft.buyback_text")}</p>
      <hr />
      {mode === "buy" ? (
        <>
          <form onSubmit={buybackSubmit}>
            <input name="nftId" type="text" hidden defaultValue={nft._id} />
            <div className="d-flex justify-content-between">
              <small>{t("nft.buyback_price")}</small>
              <small>{nft.currentPrice?.toFixed(2)} €</small>
            </div>
            <div className="d-flex justify-content-between">
              <small>{t("nft.buyback_fees")}</small>
              <small>{stripeFees?.toFixed(2)} €</small>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <span>{t("nft.buyback_payment_amount")}</span>
              <span className="h4">{paymentAmount?.toFixed(2)} €</span>
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              <input
                className="m-0"
                type="checkbox"
                name="delete"
                id="buyback-form-delete"
              />
              <label className="m-0" htmlFor="buyback-form-delete">
                {t("nft.buyback_delete_label")}
              </label>
            </div>
            {nft.sellStatus !== NFT_SELL_STATUS.FOR_SALE ? (
              <p className="text-center border py-2">
                <i>{t("nft.buyback_not_for_sale")}</i>
              </p>
            ) : null}
            <div className="d-flex justify-content-end mt-2 gap-2">
              <Button
                onClick={() => closeModal()}
                light
                disabled={isLoading}
                text={String(t("nft.buyback_cancel"))}
              />
              <Button
                type="submit"
                loading={isLoading}
                disabled={nft.sellStatus !== NFT_SELL_STATUS.FOR_SALE}
                text={`${t("nft.buyback_confirm")}*`}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <small>*{t("nft.buyback_tip")}</small>
            </div>
          </form>
        </>
      ) : null}

      {mode === "offer" ? (
        <>
          <form onSubmit={buybackOfferSubmit}>
            <input name="nftId" type="text" hidden defaultValue={nft._id} />
            <label htmlFor="">{t("nft.buyback_offer_date")}</label>
            <CustomDatePicker
              name="expireDate"
              defaultValue={new Date().setDate(new Date().getDate() + 1)}
            />
            <label htmlFor="">{t("nft.buyback_offer_value")}</label>
            <input
              type="number"
              name="amount"
              min={0}
              step={0.01}
              value={offerValue}
              onChange={(e) => setOfferValue(Number(e.target.value))}
            />
            <div className="d-flex justify-content-between">
              <small>{t("nft.buyback_fees")}</small>
              <small>
                {((offerValue ?? 0) * DEFAULT_OVERPRICE).toFixed(2)} €
              </small>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <span>{t("nft.buyback_payment_amount")}</span>
              <span className="h4">
                {((offerValue ?? 0) * (1 + DEFAULT_OVERPRICE)).toFixed(2)} €
              </span>
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              <input
                className="m-0"
                type="checkbox"
                name="delete"
                id="buyback-form-delete"
              />
              <label className="m-0" htmlFor="buyback-form-delete">
                {t("nft.buyback_delete_label")}
              </label>
            </div>
            <div className="d-flex justify-content-end mt-2 gap-2">
              <Button
                onClick={() => closeModal()}
                light
                disabled={isLoading}
                text={String(t("nft.buyback_cancel"))}
              />
              <Button
                type="submit"
                loading={isLoading}
                text={`${t("nft.buyback_offer_confirm")}*`}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <small>*{t("nft.buyback_tip")}</small>
            </div>
          </form>
        </>
      ) : null}

      <p className="text-center text-danger pt-2">{error}</p>
    </div>
  );
}

export interface loadNftsProps {
  collection: iCollection;
  page?: number;
  size?: number;
  textQuery?: any;
  modeQuery?: any;
}

export default function CollectionNfts() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementRef = useRef<HTMLTableElement>(null);

  const [nfts, setNfts] = useState<Array<iNft>>([]);

  const collection = useSelector(selectCollection);
  const group = useSelector(selectGroup);
  const user = useSelector(selectUser);

  const [textQuery, setTextQuery] = useState<any>({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [mode, setMode] = useState<"all" | "past" | "active" | "sale">(
    "active"
  );
  const [modeQuery, setModeQuery] = useState<any>({
    "payload.checkout": {
      $gt: new Date(),
    },
  });

  const canEditNft = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.create_nft)
    )
      return true;

    return false;
  };

  const canDeleteNft = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.delete_nft)
    )
      return true;

    return false;
  };

  const canSaleNft = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.sale)
    )
      return true;

    return false;
  };

  const canCreateNft = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.create_nft)
    )
      return true;

    return false;
  };

  const loadNfts = async ({
    collection,
    page = 1,
    size,
    textQuery,
    modeQuery,
  }: loadNftsProps) => {
    setIsLoading(true);
    try {
      const data = await getNftsOfCollectionPaginated({
        collectionId: collection._id,
        page,
        size,
        query: {
          ...textQuery,
          ...modeQuery,
        },
      });

      setTotal(data.total || 0);

      setNfts(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  const deleteNftSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      closeModal();
      await deleteNft(data);
      await loadNfts({
        collection,
        page: page,
        size: size,
        textQuery,
        modeQuery,
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const deleteNftModal = (id: string) => {
    return (
      <section id="remove-user-group-modal">
        <span className="title">
          {t("nft.delete_confirm")} {id}
        </span>
        <form onSubmit={deleteNftSubmit}>
          <input type="text" required hidden name="nftId" defaultValue={id} />
          <div className="buttons">
            <Button
              onClick={() => closeModal()}
              className="cancel"
              light
              text={String(t("nft.cancel"))}
            />
            <Button
              type="submit"
              className="confirm"
              error
              text={String(t("nft.confirm_delete_button"))}
            />
          </div>
        </form>
      </section>
    );
  };

  useEffect(() => {
    loadNfts({ collection, page, size, textQuery, modeQuery });
    // eslint-disable-next-line
  }, [page, size, collection]);

  const searchCallbackDebounced = useRef(debounce(loadNfts, 1000));

  const isQueryGood = (query: string) => {
    return /\S/.test(query) && query.length >= 1;
  };

  const searchInput = (text: string) => {
    if (!text || !isQueryGood(text)) {
      setTextQuery({});
      loadNfts({ collection, page: page, size: size, modeQuery });
      return;
    }

    const textQ = {
      $or: [
        { _id: { $regex: text, $options: "i" } },
        { shortId: { $regex: text, $options: "i" } },
        { owner: { $regex: text, $options: "i" } },
        { "payload.reference": { $regex: text, $options: "i" } },
        { "payload.room": { $regex: text, $options: "i" } },
      ],
    };

    setTextQuery(textQ);
    searchCallbackDebounced.current({
      collection,
      page,
      textQuery: textQ,
      modeQuery,
    });
  };

  const bulkUploadCsv = async (e: any) => {
    const csv = await e.target.files[0].text();

    type csvType = {
      img0: string;
      img1: string;
      img2: string;
      img3: string;
      img4: string;
      lock_date: string;
      owner: string;
      price: string;
      checkin: string;
      checkout: string;
      email: string;
      extra: string;
      hotel: string;
      location: string;
      note: string;
      reference: string;
      room_name: string;
      room_adults: string;
      room_kids: string;
      room_amenities: string;
      stars: string;
      website: string;
      discount_market: string;
      put_for_sale: string;
    };

    const data: csvType[] = csvToObj(csv, ";");

    const payloads: (iNft | null)[] = data
      .map((e, key) => {
        try {
          return {
            collectionId: collection._id,
            images: [e.img0, e.img1, e.img2, e.img3, e.img4].filter(
              (img) => img
            ),
            lockDate: new Date(e.lock_date).toISOString(),
            owner: e.owner,
            guests: [{}],
            payload: {
              checkin: new Date(e.checkin).toISOString(),
              checkout: new Date(e.checkout).toISOString(),
              email: e.email,
              extra: e.extra,
              hotel: e.hotel,
              location: { label: e.location },
              note: e.note,
              reference: e.reference,
              rooms: [
                {
                  name: e.room_name,
                  guestsAdults: Number(e.room_adults),
                  guestsKids: Number(e.room_kids),
                  amenities: e.room_amenities,
                },
              ],
              stars: e.stars,
              website: e.website,
            },
            originalPrice: e.price ? Number(e.price) : 0,
          };
        } catch (error) {
          console.log("Error parsing line", key, error);
          return null;
        }
      })
      .filter((e) => e);

    setIsLoading(true);
    for (let i = 0; i < payloads.length; i++) {
      try {
        const payload = payloads[i];
        await createNft({
          ...payload,
          discountFromMarket: Number(data[i].discount_market),
          putForSale:
            String(data[i].put_for_sale).toLowerCase() === "true"
              ? true
              : false,
        });

        await sleep(10000);
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);

    await loadNfts({
      collection,
      page: page,
      size: size,
      textQuery,
      modeQuery,
    });

    alert(`Generated ${payloads.length} nfts`);
  };

  return (
    <section>
      <div className="d-flex justify-content-end gap-2">
        {user.isAdmin && canCreateNft() ? (
          <>
            <input
              onChange={(e) => bulkUploadCsv(e)}
              hidden
              type="file"
              name=""
              id="upload-bulk-input"
            />
            <Button
              onClick={() =>
                document.getElementById("upload-bulk-input")?.click()
              }
              text={String(t("nft.upload_bulk"))}
            />
            <Button
              onClick={() =>
                openModal(
                  <CreateFakeHotelSupplyModal
                    onCreate={() =>
                      loadNfts({ collection, page, size, textQuery, modeQuery })
                    }
                    collection={collection}
                  />
                )
              }
              text={String(t("nft.fake_supply"))}
            />
          </>
        ) : null}
        {canCreateNft() ? (
          <div>
            {collection.type && collection.type == "experience" ? (
              <Button
                onClick={() => {
                  if (!group.verified)
                    return alert(
                      "Your group is not verified. Verify your group first"
                    );

                  if (
                    collection.subscriptionStatus !== "active" &&
                    collection.subscriptionStatus !== "trialing" &&
                    collection.subscriptionStatus !== "past_due" &&
                    collection.feeOnCreation &&
                    collection.feeOnCreation > 0
                  )
                    return alert(
                      "Your collection has not PaymentMethod with active subscription. Activate subscription first."
                    );

                  openModal(<UpsertExperience />);
                  //navigate("experience/edit");
                }}
                text={String(t("nft.create_experience"))}
              />
            ) : (
              <Button
                onClick={() => {
                  if (!group.verified)
                    return alert(
                      "Your group is not verified. Verify your group first"
                    );

                  if (
                    collection.subscriptionStatus !== "active" &&
                    collection.subscriptionStatus !== "trialing" &&
                    collection.subscriptionStatus !== "past_due" &&
                    collection.feeOnCreation &&
                    collection.feeOnCreation > 0
                  )
                    return alert(
                      "Your collection has not PaymentMethod with active subscription. Activate subscription first."
                    );

                  navigate("edit");
                }}
                text={String(t("nft.create_new"))}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className="d-flex gap-2 mt-2">
        <Button
          disabled={isLoading || mode === "active"}
          light={mode !== "active"}
          small
          text={String(t("nft.active"))}
          onClick={() => {
            setMode("active");
            const modeQ = {
              "payload.checkout": {
                $gt: new Date(),
              },
            };
            setModeQuery(modeQ);
            loadNfts({
              collection,
              page: page,
              size: size,
              textQuery,
              modeQuery: modeQ,
            });
          }}
        />
        <Button
          disabled={isLoading || mode === "sale"}
          light={mode !== "sale"}
          small
          text={String(t("nft.sale"))}
          onClick={() => {
            setMode("sale");
            const modeQ = {
              sellStatus: NFT_SELL_STATUS.FOR_SALE,
            };
            setModeQuery(modeQ);
            loadNfts({
              collection,
              page: page,
              size: size,
              textQuery,
              modeQuery: modeQ,
            });
          }}
        />
        <Button
          disabled={isLoading || mode === "past"}
          light={mode !== "past"}
          small
          text={String(t("nft.past"))}
          onClick={() => {
            setMode("past");
            const modeQ = {
              "payload.checkout": {
                $lt: new Date(),
              },
            };
            setModeQuery(modeQ);
            loadNfts({
              collection,
              page: page,
              size: size,
              textQuery,
              modeQuery: modeQ,
            });
          }}
        />
        <Button
          disabled={isLoading || mode === "all"}
          light={mode !== "all"}
          small
          text={String(t("nft.all"))}
          onClick={() => {
            setMode("all");
            setModeQuery({});
            loadNfts({ collection, page: page, size: size, textQuery });
          }}
        />

        <Button
          small
          text={String(t("nft.export"))}
          onClick={() => {
            openModal(<ExportModal />);
          }}
          className="ml-auto"
        />
      </div>
      <div className="search-nft">
        <input
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            searchInput(e.target.value);
          }}
          className="w100"
          type="text"
          placeholder={String(t("nft.search_placeholder"))}
        />
        <img src={searchSvg} alt="search icon" className="icon" />
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : nfts.length ? (
        <>
          <table className="mt-3" ref={elementRef}>
            <thead>
              <tr>
                <th>{t("nft.details")}</th>
                <th>{t("nft.created_at")}</th>
                <th>{t("nft.booking_ref")}</th>
                <th>{t("nft.hotel")}</th>
                <th>{t("nft.owner")}</th>
                <th>{t("nft.guests")}</th>
                <th>
                  {t("nft.checkin")} - {t("nft.checkout")}
                </th>
                <th>{t("nft.original_price")}</th>
                <th>{t("nft.current_price")}</th>
                <th>{t("nft.sale")}</th>
                {canEditNft() || canSaleNft() ? (
                  <th>{t("nft.actions")}</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {nfts.map((nft) => (
                <tr key={"nft_" + nft._id}>
                  <td>
                    <img
                      className="view-icon"
                      onClick={() => {
                        openModal(<NftModal nftId={nft._id!} />);
                      }}
                      src={viewSvg}
                      alt="eye icon"
                    />
                  </td>
                  <td>{datetimeToString(nft._createdAt, undefined, true)}</td>
                  <td>{nft.payload?.reference}</td>
                  <td>{nft._collectionCensus?.name}</td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      maxWidth: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {nft.ownerType === NFT_OWNER_TYPE.RESOLD ? "🟡" : ""}{" "}
                    <a href={`mailto:${nft.owner}`}>{nft.owner}</a>
                  </td>
                  <td>
                    {nft.payload?.rooms?.reduce((total: any, room: any) => {
                      return (
                        total +
                        (room.guestsAdults ?? 0) +
                        (room.guestsKids ?? 0)
                      );
                    }, 0)}
                  </td>
                  <td>
                    {datetimeToString(
                      nft.payload?.checkin,
                      collection.census?.location?.gmt,
                      true
                    )}{" "}
                    -{" "}
                    {datetimeToString(
                      nft.payload?.checkout,
                      collection.census?.location?.gmt,
                      true
                    )}
                  </td>
                  <td>{Number(nft.originalPrice).toFixed(2)}€</td>
                  <td>
                    {nft.currentPrice
                      ? `${Number(nft.currentPrice).toFixed(2)}€`
                      : null}
                  </td>
                  <td>
                    {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? "🟢" : ""}
                  </td>
                  {canEditNft() || canSaleNft() || canDeleteNft() ? (
                    <td>
                      <DropdownMenu
                        position="down-left"
                        small
                        hovered
                        options={[
                          {
                            disabled: !canEditNft(),
                            text: t("nft.edit"),
                            action: () =>
                              collection.type == "experience"
                                ? openModal(
                                    <UpsertExperience nftId={nft._id!} />
                                  )
                                : navigate("edit/" + nft._id),
                          },
                          {
                            disabled:
                              new Date(nft.lockDate as any) < new Date() ||
                              !canSaleNft(),
                            text: t("nft.buyback"),
                            action: () => openModal(<BuybackModal nft={nft} />),
                          },
                          {
                            disabled: !canDeleteNft(),
                            text: t("nft.delete"),
                            action: () =>
                              openModal(deleteNftModal(String(nft._id))),
                            red: true,
                          },
                        ]}
                      />
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            page={page}
            size={size}
            total={total}
            onSizeChange={onSizeChange}
            onPageChange={onPageChange}
          />
        </>
      ) : (
        <p>{t("pagination.no_show")}</p>
      )}
    </section>
  );
}
