import { FormEvent, useEffect, useRef, useState } from "react";

import Button from "../../components/dom/Button";
import Loader from "../../components/dom/Loader";
import { closeModal, openModal } from "../../components/modal/Modal";
import { PAGE_DIM, formToObject, scrollToTopList } from "../../utils/generic";
import CustomDatePicker from "../../components/dom/CustomDatePicker";
import { getMyApiKeys, iApiKey } from "../../services/apikey";
import {
  iEmail,
  readListMailsSevice,
  searchMailService,
} from "../../services/takyonMails";
import Pagination from "../../components/dom/Pagination";
import { useTranslation } from "react-i18next";
import viewSvg from "../../assets/icons/view.svg";
import EmailDetailModal from "./EmailDetailModal";

interface SearchEmailsProps {
  apikey: string;
  onSuccess: (results: iEmail[]) => void;
}

function CreateSerachForm({ apikey, onSuccess }: SearchEmailsProps) {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = formToObject(e.target);

      if (data.dateSearch) {
        let newDate = new Date(data.dateSearch);
        newDate.setHours(newDate.getHours() + 2);
        data.dateSearch = newDate.toISOString();
      }
      const mailsService = await searchMailService(apikey, data);

      onSuccess(mailsService.data);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <form onSubmit={submit} className="p-3">
      <label htmlFor="email">To*</label>
      <input type="email" name="toSearch" id="toSearch" />

      <label htmlFor="">From Email</label>
      <input type="email" name="fromSearch" />

      <label htmlFor="">Subject</label>
      <input type="text" name="subjectSearch" />

      <label htmlFor="">Text</label>
      <input type="text" name="textSearch" />

      <label htmlFor=""></label>
      <div className="d-flex flex-column w100">
        <label htmlFor="">Send Date</label>
        <CustomDatePicker name="dateSearch" />
      </div>

      <Button type="submit" text={"Search"} />
    </form>
  );
}

export default function EmailsService() {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<iEmail[]>([]);
  const [apiKeys, setApiKeys] = useState<Array<iApiKey>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const elementRef = useRef<HTMLTableElement>(null);

  const loadEmail = async () => {
    setIsLoading(true);

    try {
      const data = await getMyApiKeys();
      setApiKeys(data);

      const apiKey = data[0].token;

      const mailsService = await readListMailsSevice(apiKey, page, size);
      setTotal(mailsService.total || 0);

      setEmails(mailsService.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  const searchComplete = (results: iEmail[]) => {
    setTotal(results.length || 0);
    setEmails(results);
  };

  useEffect(() => {
    loadEmail();
  }, [page, size]);

  return (
    <main>
      {isLoading && (
        <div className="loaderFull">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Loader />
          </div>
        </div>
      )}
      <div className="container">
        <div className="content">
          <br />
          <Button
            onClick={() =>
              openModal(
                <CreateSerachForm
                  apikey={apiKeys[0].token}
                  onSuccess={searchComplete}
                />
              )
            }
            text={"Search Email"}
          />
          <hr />
        </div>
        {emails.length ? (
          <>
            <table>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Date</th>
                  <th>To</th>
                  <th>From</th>
                  <th>Subject</th>
                </tr>
              </thead>
              <tbody>
                {emails.map((email, key) => {
                  return (
                    <tr key={"mail_" + key}>
                      <td>
                        <img
                          className="view-icon"
                          onClick={() => {
                            openModal(
                              <EmailDetailModal
                                apikey={apiKeys[0].token}
                                sg_message_id={email.sg_message_id}
                              />
                            );
                          }}
                          src={viewSvg}
                          alt="eye icon"
                        />
                      </td>
                      <td>{email._createdAt}</td>
                      <td>{email.payload.to}</td>
                      <td>{email.payload.from.email}</td>
                      <td>{email.payload.dynamicTemplateData.__SUBJECT__}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              page={page}
              size={size}
              total={total}
              onSizeChange={onSizeChange}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <p>{t("pagination.no_show")}</p>
        )}
      </div>
    </main>
  );
}
