import axios from "axios";
import { Pagination } from "../utils/Pagination";

export enum DROPSHIP_PERMISSIONS {
  admin = "admin",
  update = "update",
  memebers = "memebers",
  sale = "sale",
  set_payment_method = "set_payment_method",
  view_payment_data = "view_payment_data",
  receive_notifications = "receive_notifications",
}

export interface dropshipMember {
  email: string;
  permissions: Array<DROPSHIP_PERMISSIONS>;
  isInviteAccepted: boolean;
}

export interface iDropship {
  _id: string;
  name: string;
  groupId: string;
  members: Array<dropshipMember>;

  paymentMethodId: string;
}

export async function getDropships({
  page = 1,
  size = 25,
  groupId = "",
  query = {},
  sort = { name: 1 },
}): Promise<Pagination<iDropship>> {
  let url = `/dropship/paginate?page=${page}&size=${size}`;

  if (groupId) {
    url += "&groupId=" + groupId;
  }

  if (query) {
    url += "&query=" + JSON.stringify(query);
  }

  if (sort) {
    url += "&sort=" + JSON.stringify(sort);
  }

  const response = await axios.get(url);
  return response.data;
}

export async function createDropship(payload: any): Promise<Pagination<any>> {
  const response = await axios.post("/dropship", payload);
  return response.data;
}

export async function getDropship(id: string): Promise<iDropship> {
  const response = await axios.get("/dropship/" + id);
  return response.data;
}

export async function patchDropship(payload: any): Promise<iDropship> {
  const response = await axios.patch("/dropship", payload);
  return response.data;
}
