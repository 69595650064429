import { useTranslation } from "react-i18next";
import { useState } from "react";

import Button from "../../../components/dom/Button";
import { sendReport } from "../../../services/reports";
import { formToObject } from "../../../utils/generic";
import { closeModal } from "../../../components/modal/Modal";

interface Props {
  reportId: string;
  onSuccess?: Function;
}

export default function ReportSendModal({ reportId, onSuccess }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const sendSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      data.collectionreportId = reportId;

      if (!data.email) delete data.email;

      await sendReport(data);
      closeModal();
      onSuccess?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <span>{t("reports.send_report")}</span>

      <form onSubmit={sendSubmit} className="modal-form mt-3">
        <label htmlFor="email">{t("reports.email")}</label>
        <input name="email" type="email" />
        <Button
          type="submit"
          text={String(t("reports.send_report"))}
          loading={isLoading}
        />
      </form>
    </div>
  );
}
