import axios from "axios";
import { Pagination } from "../utils/Pagination";

export interface iEmail {
  _id: string;
  _createdAt: string;
  sg_message_id: string;
  payload: {
    to: string;
    from: {
      email: string;
      name: string;
    };
    templateId: string;
    dynamicTemplateData: {
      __LINK__: string;
      __TOKEN__: string;
      __MESSAGE0__: string;
      __MESSAGE1__: string;
      __MESSAGE2__: string;
      __MESSAGE3__: string;
      __MESSAGE4__: string;
      __MESSAGE5__: string;
      __BUTTON__: string;
      __SUBJECT__: string;
    };
    hideWarnings: boolean;
    isMultiple: boolean;
    substitutionWrappers: [string, string];
  };
  sent: boolean;
  dynamicTemplateDataString: string;
}

export async function readMailService(
  apikey: string,
  sg_message_id: string
): Promise<iEmail> {
  const response = await axios.post(
    process.env.REACT_APP_MAIL_URI + "/readMail",
    { sg_message_id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: apikey,
      },
    }
  );

  return response.data;
}

export async function readListMailsSevice(
  apikey: string,
  page?: number,
  size?: number
): Promise<Pagination<iEmail>> {
  const response = await axios.post(
    process.env.REACT_APP_MAIL_URI + "/readListMails",
    { page, size },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: apikey,
      },
    }
  );

  return response.data;
}

export async function searchMailService(
  apikey: string,
  dataForm: {
    toSearch?: string;
    fromSearch?: string;
    subjectSearch?: string;
    textSearch?: string;
    dateSearch?: string;
  },
  page?: number,
  size?: number
): Promise<Pagination<iEmail>> {
  const response = await axios.post(
    process.env.REACT_APP_MAIL_URI + "/searchMail",
    { page, size, dataForm },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: apikey,
      },
    }
  );

  return response.data;
}

export async function reSubmitMailService(
  apikey: string,
  sg_message_id: string
): Promise<iEmail> {
  const response = await axios.post(
    process.env.REACT_APP_MAIL_URI + "/resubmitMail",
    { sg_message_id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: apikey,
      },
    }
  );

  return response.data;
}
