import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import nftSvg from "../../assets/icons/nft.svg";
import censusSvg from "../../assets/icons/census.svg";
import imagesSvg from "../../assets/icons/images.svg";
import usersSvg from "../../assets/icons/users.svg";
import offersSvg from "../../assets/icons/collection.svg";
import presetSvg from "../../assets/icons/preset.svg";
import paymentSvg from "../../assets/icons/payment.svg";
import statisticsSvg from "../../assets/icons/statistics.svg";
import infoSvg from "../../assets/icons/info.svg";
import settingsSvg from "../../assets/icons/settings.svg";
import crightSvg from "../../assets/icons/cright.svg";
import CollectionNfts from "./sub/CollectionNfts";
import { selectCollection } from "../../store/collectionSlice";
import {
  iCollection,
  refreshStoredCollection,
} from "../../services/collection";
import { useSelector } from "react-redux";
import CollectionMembers from "./sub/CollectionMembers";
import { iGroup, refreshStoredGroup } from "../../services/groups";
import CollectionCensus from "./sub/CollectionCensus";
import CollectionInfo from "./sub/CollectionInfo";
import CollectionAccess from "./sub/CollectionAccess";
import CollectionImages from "./sub/CollectionImages";
import CreateNft from "../../components/createnft/CreateNft";
import Loader from "../../components/dom/Loader";
import CollectionPayment from "./sub/CollectionPayment";
import { selectGroup } from "../../store/groupSlice";
import CollectionOffers from "./sub/CollectionOffers";

import chevronBack from "../../assets/icons/double-left-chevron.svg";
import CollectionEvents from "./sub/CollectionEvents";
import { selectUser } from "../../store/userSlice";
import CreateNftByType from "../../components/createnft/CreateNftByType";
import CollectionCensusByType from "./sub/CollectionCensusByType";

function Redirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("nfts");
  }, []);
  return <></>;
}

export interface CollectionRouterProps {
  collection: iCollection;
  group: iGroup;
  onRefresh: Function;
}

function CollectionRouter({
  collection,
  group,
  onRefresh,
}: CollectionRouterProps) {
  return (
    <Routes>
      <Route path="/nfts" element={<CollectionNfts />} />
      <Route path="/nfts/edit/" element={<CreateNft />} />
      <Route path="/nfts/:nftType/edit" element={<CreateNftByType />} />
      <Route path="/nfts/edit/:nftId" element={<CreateNft />} />
      <Route path="/members" element={<CollectionMembers />} />
      <Route
        path="/census"
        element={
          <CollectionCensusByType
            collection={collection}
            group={group}
            onRefresh={onRefresh}
          />
        }
      />
      <Route path="/images" element={<CollectionImages />} />
      <Route path="/payment" element={<CollectionPayment />} />
      <Route path="/info" element={<CollectionInfo />} />
      <Route path="/access" element={<CollectionAccess />} />
      <Route path="/offers" element={<CollectionOffers />} />
      <Route
        path="/events"
        element={
          <CollectionEvents
            collection={collection}
            group={group}
            onRefresh={onRefresh}
          />
        }
      />
      <Route path="/" element={<Redirect />} />
    </Routes>
  );
}

interface iMenuItem {
  name: string;
  icon?: any;
  separator?: boolean;
  uri?: string;
  selected?: boolean;
  primary?: boolean;
  disabled?: boolean;
}

export default function Collection() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  let menu: Array<iMenuItem> = [
    {
      name: "collection.nfts",
      uri: "nfts",
      icon: nftSvg,
    },
    {
      name: "collection.offers",
      uri: "offers",
      icon: offersSvg,
      separator: true,
    },
    {
      name: "collection.census",
      uri: "census",
      icon: censusSvg,
    },
    {
      name: "collection.images",
      uri: "images",
      icon: imagesSvg,
    },
    {
      name: "collection.members",
      uri: "members",
      icon: usersSvg,
      separator: true,
    },
    {
      name: "collection.events",
      uri: "events",
      icon: presetSvg,
    },
    {
      name: "collection.payments",
      uri: "payment",
      icon: paymentSvg,
    },
    {
      name: "collection.statistics",
      uri: "statistics",
      icon: statisticsSvg,
      separator: true,
      disabled: true,
    },
    {
      name: "collection.info",
      uri: "info",
      icon: infoSvg,
    },
    {
      name: "collection.access",
      uri: "access",
      icon: settingsSvg,
    },
  ];

  if (
    user.email === "dataentry@takyon.io" ||
    user.email === "dataentry2@takyon.io"
  )
    menu = [
      {
        name: "collection.census",
        uri: "census",
        icon: censusSvg,
      },
      {
        name: "collection.images",
        uri: "images",
        icon: imagesSvg,
      },
    ];

  const [menuitems, setMenuitems] = useState<Array<iMenuItem>>([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const collection = useSelector(selectCollection);
  const group = useSelector(selectGroup);

  useEffect(() => {
    if (
      user.email === "dataentry@takyon.io" ||
      user.email === "dataentry2@takyon.io"
    )
      if (!menu.find((m) => pathname.includes(String(m.uri))))
        navigate(String(menu[0].uri));
  }, [user, pathname]);

  const loadCollection = async (id: string) => {
    setIsLoading(true);
    try {
      const c = await refreshStoredCollection(id);
      await refreshStoredGroup(c.groupId);
    } catch (error) {
      console.log(error);

      navigate("/"); // does not have permission to see
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!id) return;
    loadCollection(id);

    setMenuitems(() => {
      return menu.map((i: iMenuItem) => {
        if (pathname.includes(String(i.uri))) i.selected = true;
        return i;
      });
    });
    // eslint-disable-next-line
  }, [pathname]);

  if (!collection._id || isLoading)
    return (
      <section className="d-flex justify-content-center">
        <Loader />
      </section>
    );

  return (
    <main id="collection-page">
      <div className="side">
        <div
          className="back"
          onClick={() => {
            navigate(`/group/${group._id}/collection`);
          }}
        >
          <img className="back-icon" src={chevronBack} alt="chevron back" />
          <small className="back-text">{t("collection.back")}</small>
        </div>
        <p className="collection-title">{collection.name}</p>
        {menuitems.map((item, key) => {
          return (
            <div key={"menu_item_" + key}>
              <div
                className={`menu-item ${item.selected ? "selected" : ""} ${
                  item.disabled ? "disabled" : ""
                }`}
                onClick={() => {
                  if (item.uri && !item.disabled) navigate(item.uri);
                }}
              >
                <img className="menu-icon" src={item.icon} alt="" />
                <span className="menu-item-text">{t(item.name)}</span>
                <img className="menu-icon-appear" src={crightSvg} alt="" />
              </div>
              {item.separator ? <div className="hr light"></div> : null}
            </div>
          );
        })}
      </div>
      <div className="content">
        <span className="h3">
          {t(String(menuitems.find((e) => e.selected)?.name ?? ""))}
        </span>
        {group._id && collection._id ? (
          <CollectionRouter
            onRefresh={() => loadCollection(collection._id)}
            collection={collection}
            group={group}
          />
        ) : null}
      </div>
    </main>
  );
}
