import { debounce } from "lodash";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  NFT_OWNER_TYPE,
  NFT_SELL_STATUS,
  getNftsPaginated,
  iNft,
  payloadHotelType,
} from "../../services/nft";
import { selectUser } from "../../store/userSlice";
import { PAGE_DIM, datetimeToString } from "../../utils/generic";
import Button from "../../components/dom/Button";
import searchSvg from "../../assets/icons/search.svg";
import viewSvg from "../../assets/icons/view.svg";
import { openModal } from "../../components/modal/Modal";
import NftModal from "../Collection/NftModal";
import Pagination from "../../components/dom/Pagination";
import Loader from "../../components/dom/Loader";

export default function Nfts() {
  const [nfts, setNfts] = useState<Array<iNft<payloadHotelType>>>([]);
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const [textQuery, setTextQuery] = useState<any>({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [mode, setMode] = useState<"all" | "past" | "active" | "sale">(
    "active"
  );

  const [modeQuery, setModeQuery] = useState<any>({
    "payload.checkout": {
      $gt: new Date(),
    },
  });

  const loadNfts = async () => {
    setIsLoading(true);
    try {
      const data = await getNftsPaginated({
        page,
        size,
        query: {
          ...textQuery,
          ...modeQuery,
        },
        sort: { _createdAt: -1 },
      });

      setTotal(data.total || 0);
      setNfts(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user?.isAdmin) return;

    loadNfts();
    // eslint-disable-next-line
  }, [page, size, modeQuery, user, textQuery]);

  const searchCallbackDebounced = useRef(debounce(setTextQuery, 1000));

  const isQueryGood = (query: string) => {
    return /\S/.test(query) && query.length >= 1;
  };

  const searchInput = (text: string) => {
    if (!text || !isQueryGood(text)) {
      setTextQuery({});
      loadNfts();
      return;
    }

    const textQ = {
      $or: [
        { _id: { $regex: text, $options: "i" } },
        { owner: { $regex: text, $options: "i" } },
        { "payload.reference": { $regex: text, $options: "i" } },
        { "payload.room": { $regex: text, $options: "i" } },
        { "payload.hotel": { $regex: text, $options: "i" } },
      ],
    };

    // setTextQuery(textQ);
    searchCallbackDebounced.current(textQ);
  };

  return (
    <section id="collection-page">
      <div className="container">
        <div className="content">
          <div className="d-flex gap-2 mt-2">
            <Button
              disabled={isLoading || mode === "active"}
              light={mode !== "active"}
              small
              text={String(t("nft.active"))}
              onClick={() => {
                setMode("active");
                const modeQ = {
                  "payload.checkout": {
                    $gt: new Date(),
                  },
                };
                setModeQuery(modeQ);
              }}
            />
            <Button
              disabled={isLoading || mode === "sale"}
              light={mode !== "sale"}
              small
              text={String(t("nft.sale"))}
              onClick={() => {
                setMode("sale");
                const modeQ = {
                  sellStatus: NFT_SELL_STATUS.FOR_SALE,
                };
                setModeQuery(modeQ);
              }}
            />
            <Button
              disabled={isLoading || mode === "past"}
              light={mode !== "past"}
              small
              text={String(t("nft.past"))}
              onClick={() => {
                setMode("past");
                const modeQ = {
                  "payload.checkout": {
                    $lt: new Date(),
                  },
                };
                setModeQuery(modeQ);
              }}
            />
            <Button
              disabled={isLoading || mode === "all"}
              light={mode !== "all"}
              small
              text={String(t("nft.all"))}
              onClick={() => {
                setMode("all");
                setModeQuery({});
              }}
            />
          </div>
          <div className="search-nft">
            <input
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                searchInput(e.target.value);
              }}
              className="w100"
              type="text"
              placeholder={String(t("nft.search_placeholder"))}
            />
            <img src={searchSvg} alt="search icon" className="icon" />
          </div>

          {isLoading ? (
            <div className="container">
              <div className="content">
                <Loader />
              </div>
            </div>
          ) : null}

          {!isLoading ? (
            <>
              <table className="mt-3">
                <thead>
                  <tr>
                    <th>{t("nft.details")}</th>
                    <th>#</th>
                    <th>{t("nft.booking_ref")}</th>
                    <th>{t("nft.hotel")}</th>
                    <th>{t("nft.owner")}</th>
                    <th>{t("nft.guests")}</th>
                    <th>
                      {t("nft.checkin")} - {t("nft.checkout")}
                    </th>
                    <th>{t("nft.original_price")}</th>
                    <th>{t("nft.current_price")}</th>
                    <th>{t("nft.sale")}</th>
                    {/* {canEditNft() || canSaleNft() ? (
                    <th>{t("nft.actions")}</th>
                ) : null} */}
                  </tr>
                </thead>
                <tbody>
                  {nfts.map((nft) => (
                    <tr key={"nft_" + nft._id}>
                      <td>
                        <img
                          className="view-icon"
                          onClick={() => {
                            openModal(<NftModal nftId={nft._id!} />);
                          }}
                          src={viewSvg}
                          alt="eye icon"
                        />
                      </td>
                      <td>
                        {String(nft._id).substring(String(nft._id).length - 6)}
                      </td>
                      <td>{nft.payload?.reference}</td>
                      <td>{nft._collectionCensus?.name}</td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {nft.ownerType === NFT_OWNER_TYPE.RESOLD ? "🟡" : ""}{" "}
                        <a href={`mailto:${nft.owner}`}>{nft.owner}</a>
                      </td>
                      <td>
                        {nft.payload?.rooms?.reduce((total, room) => {
                          return (
                            total +
                            (room.guestsAdults ?? 0) +
                            (room.guestsKids ?? 0)
                          );
                        }, 0)}
                      </td>
                      <td>
                        {datetimeToString(
                          nft.payload?.checkin,
                          nft._collectionCensus?.location.gmt,
                          true
                        )}{" "}
                        -{" "}
                        {datetimeToString(
                          nft.payload?.checkout,
                          nft._collectionCensus?.location.gmt,
                          true
                        )}
                      </td>
                      <td>{Number(nft.originalPrice).toFixed(2)}€</td>
                      <td>
                        {nft.currentPrice
                          ? `${Number(nft.currentPrice).toFixed(2)}€`
                          : null}
                      </td>
                      <td>
                        {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE
                          ? "🟢"
                          : ""}
                      </td>
                      {/* {canEditNft() || canSaleNft() || canDeleteNft() ? (
                      <td>
                      <DropdownMenu
                      position="down-left"
                      small
                      hovered
                      options={[
                          {
                              disabled: !canEditNft(),
                              text: t("nft.edit"),
                              action: () => navigate("edit/" + nft._id),
                            },
                            {
                                disabled:
                                new Date(nft.lockDate as any) < new Date() ||
                                !canSaleNft(),
                                text: t("nft.buyback"),
                                action: () => openModal(<BuybackModal nft={nft} />),
                            },
                            {
                                disabled: !canDeleteNft(),
                                text: t("nft.delete"),
                                action: () =>
                                openModal(deleteNftModal(String(nft._id))),
                                red: true,
                            },
                        ]}
                        />
                        </td>
                    ) : null} */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                page={page}
                size={size}
                total={total}
                onSizeChange={setSize}
                onPageChange={setPage}
              />
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
}
