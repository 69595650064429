import { FormEvent, useEffect, useRef, useState } from "react";

import {
  createPartner,
  deletePartner,
  getPartnersPaginated,
  iPartner,
} from "../../services/partners";
import {
  PAGE_DIM,
  compressImage,
  datetimeToString,
  scrollToTopList,
  starsOptions,
} from "../../utils/generic";
import { closeModal, openModal } from "../../components/modal/Modal";
import Button from "../../components/dom/Button";
import MapsAutocomplete from "../../components/mapsautocomplete/MapsAutocomplete";
import CustomDatePicker from "../../components/dom/CustomDatePicker";
import Loader from "../../components/dom/Loader";
import Pagination from "../../components/dom/Pagination";
import { useTranslation } from "react-i18next";
import Select from "../../components/dom/Select";
import Checkbox from "../../components/dom/Checkbox";

interface DeletePartnerProps {
  _partner: iPartner;
  onSuccess: Function;
}

function DeletePartner({ _partner, onSuccess }: DeletePartnerProps) {
  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    setIsLoading(true);
    try {
      await deletePartner({ _id: _partner._id });

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="d-flex flex-column align-items-center p-3 pt-0">
      <p>Are you sure you want to delete {_partner.name}?</p>

      <div className="d-flex gap-3">
        <Button
          loading={isLoading}
          text="Cancel"
          light
          onClick={() => closeModal()}
        />
        <Button
          loading={isLoading}
          text="Delete"
          error
          onClick={() => confirm()}
        />
      </div>
    </div>
  );
}

interface CreatePartnerProps {
  _partner?: iPartner;
  onSuccess: Function;
}
function CreatePartner({ _partner, onSuccess }: CreatePartnerProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [partner, setPartner] = useState<iPartner>({} as any);

  useEffect(() => {
    if (_partner) setPartner(_partner);
    // eslint-disable-next-line
  }, []);

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await createPartner(partner);
      await onSuccess({});
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const logoUploadInput = async (e: any) => {
    const image = Array.from(e.target.files)[0] as any;

    const readAsync = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onloadend = async () => {
        if (reader.result) {
          const compressedImage = await compressImage(String(reader.result));
          resolve(compressedImage);
        }
      };
    });

    setPartner({ ...partner, logo: readAsync as string });
  };

  const imageUploadInput = async (e: any) => {
    const image = Array.from(e.target.files)[0] as any;

    const readAsync = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onloadend = async () => {
        if (reader.result) {
          const compressedImage = await compressImage(String(reader.result));
          resolve(compressedImage);
        }
      };
    });

    setPartner({ ...partner, image: readAsync as string });
  };

  return (
    <form onSubmit={submit} className="p-3">
      <div className="d-flex w100 gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">Name</label>
          <input
            disabled={isLoading}
            type="text"
            name="name"
            defaultValue={partner?.name}
            onChange={(e) => setPartner({ ...partner, name: e.target.value })}
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">Stars</label>
          <Select
            disabled={isLoading}
            onChange={(e) => setPartner({ ...partner, stars: e.target.value })}
            value={partner?.stars}
            name="stars"
            options={starsOptions.map((s) => {
              return {
                value: s.value,
                text: s.text,
                key: s.value,
              };
            })}
          />
        </div>
      </div>
      <div className="d-flex w100 gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">Website</label>
          <input
            disabled={isLoading}
            type="text"
            name="website"
            defaultValue={partner?.website}
            onChange={(e) =>
              setPartner({ ...partner, website: e.target.value })
            }
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">Rooms Amount</label>
          <input
            disabled={isLoading}
            type="number"
            name="roomsAmount"
            defaultValue={partner?.roomsAmount}
            onChange={(e) =>
              setPartner({
                ...partner,
                roomsAmount: parseInt(e.target.value, 10),
              })
            }
          />
        </div>
      </div>

      <label htmlFor="">Location</label>
      <MapsAutocomplete
        onSelect={(e: any) => setPartner({ ...partner, location: e })}
        defaultValue={partner.location}
      />
      <div className="d-flex gap-2 w100">
        <div className="d-flex flex-column w100">
          <label htmlFor="">Live Date</label>
          <CustomDatePicker
            name="liveDate"
            defaultValue={partner?.liveDate}
            onSelect={(e: any) => setPartner({ ...partner, liveDate: e })}
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">Sign Date</label>
          <CustomDatePicker
            name="signDate"
            defaultValue={partner?.signDate}
            onSelect={(e: any) => setPartner({ ...partner, signDate: e })}
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">Payment Start Date</label>
          <CustomDatePicker
            name="paymentStartDate"
            defaultValue={partner?.paymentStartDate}
            onSelect={(e: any) =>
              setPartner({ ...partner, paymentStartDate: e })
            }
          />
        </div>
      </div>
      <div className="d-flex w100 gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">Logo</label>
          <input
            disabled={isLoading}
            onChange={logoUploadInput}
            type="file"
            id="form-partner-logo"
            accept="image/png, image/jpeg"
            multiple
            hidden
          />
          {partner.logo ? (
            <img
              src={partner.logo}
              style={{ width: "50px", maxHeight: "50px" }}
              alt="partner logo"
            />
          ) : null}
          <div>
            <Button
              small
              text={"Upload Logo"}
              onClick={() => {
                document.getElementById("form-partner-logo")?.click();
              }}
            />
            <Button
              small
              error
              text={"Remove Logo"}
              onClick={() => {
                setPartner({ ...partner, logo: "" });
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">Image</label>
          <input
            disabled={isLoading}
            onChange={imageUploadInput}
            type="file"
            id="form-partner-image"
            accept="image/png, image/jpeg"
            multiple
            hidden
          />
          {partner.image ? (
            <img
              src={partner.image}
              style={{ width: "50px", maxHeight: "50px" }}
              alt="partner"
            />
          ) : null}
          <div>
            <Button
              small
              text={"Upload Image"}
              onClick={() => {
                document.getElementById("form-partner-image")?.click();
              }}
            />
            <Button
              small
              error
              text={"Remove Image"}
              onClick={() => {
                setPartner({ ...partner, image: "" });
              }}
            />
          </div>
        </div>
      </div>

      <br />
      <div className="d-flex w100 gap-2 mb-3">
        <div className="d-flex flex-column w100">
          <label htmlFor="">Group</label>
          <Select
            name="group"
            options={[
              { value: "", text: "" },
              { value: "BWH", text: "BWH" },
            ]}
            disabled={isLoading}
            defaultValue={partner?.group}
            onChange={(e) => setPartner({ ...partner, group: e.target.value })}
          />
        </div>
      </div>

      <div className="d-flex w100 gap-2">
        <div className="d-flex flex-column w100">
          <label htmlFor="">Group ID</label>
          <input
            disabled={isLoading}
            type="text"
            name="groupId"
            defaultValue={partner?.groupId}
            onChange={(e) =>
              setPartner({ ...partner, groupId: e.target.value })
            }
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">Collection ID</label>
          <input
            disabled={isLoading}
            type="text"
            name="collectionId"
            defaultValue={partner?.collectionId}
            onChange={(e) =>
              setPartner({ ...partner, collectionId: e.target.value })
            }
          />
        </div>
      </div>

      <div className="d-flex gap-2 w100">
        <div className="d-flex flex-column w100">
          <label htmlFor="">Accommodation Type</label>
          <Select
            name="accommodationType"
            options={[
              { value: "", text: "" },

              { value: "hotel", text: "Hotel" },
              { value: "extra-hotel", text: "Extra Hotel" },
            ]}
            disabled={isLoading}
            defaultValue={partner?.accommodationType}
            onChange={(e) =>
              setPartner({
                ...partner,
                accommodationType: e.target.value as any,
              })
            }
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">Revenue Strategy</label>
          <Select
            name="revenueStrategy"
            options={[
              { value: "", text: "" },

              { value: "only-not-ref", text: "Only Not Ref" },
              { value: "additional-not-ref", text: "Additional Not Ref" },
            ]}
            disabled={isLoading}
            defaultValue={partner?.revenueStrategy}
            onChange={(e) =>
              setPartner({ ...partner, revenueStrategy: e.target.value as any })
            }
          />
        </div>
        <div className="d-flex flex-column w100">
          <label htmlFor="">Destination Type</label>
          <Select
            name="destinationType"
            options={[
              { value: "", text: "" },

              { value: "city", text: "City" },
              { value: "lake", text: "Lake" },
              { value: "mountain", text: "Mountain" },
              { value: "sea", text: "Sea" },
              { value: "wellness", text: "Wellness" },
            ]}
            disabled={isLoading}
            defaultValue={partner?.destinationType}
            onChange={(e) =>
              setPartner({ ...partner, destinationType: e.target.value as any })
            }
          />
        </div>
      </div>

      <div className="d-flex gap-2 w100 mt-4">
        <div className="d-flex gap-2 align-items-center w100">
          <label htmlFor="">API Integration</label>
          <Checkbox
            disabled={isLoading}
            checked={partner?.apiIntegration}
            onChange={(e: any) => setPartner({ ...partner, apiIntegration: e })}
          />
        </div>
        <div className="d-flex gap-2 align-items-center w100">
          <label htmlFor="">Is Open</label>
          <Checkbox
            disabled={isLoading}
            checked={
              typeof partner.isOpen === "boolean" ? partner?.isOpen : true
            }
            onChange={(e: any) => setPartner({ ...partner, isOpen: e })}
          />
        </div>
      </div>

      <div className="mt-3 d-flex flex-column w100">
        <label htmlFor="">Booking Engine</label>
        <Select
          name="bookingEngine"
          options={[
            { value: "", text: "" },

            ...[
              { text: "SiteMinder", value: "SiteMinder" },
              { text: "BookAssist", value: "BookAssist" },
              { text: "Avvio", value: "Avvio" },
              { text: "D-edge", value: "D-edge" },
              { text: "RoomCloud", value: "RoomCloud" },
              { text: "Vertical Booking", value: "Vertical Booking" },
              { text: "Profitroom", value: "Profitroom" },
              { text: "Hotelrunner", value: "Hotelrunner" },
              { text: "Guestcentric", value: "Guestcentric" },
              { text: "Cedyn", value: "Cedyn" },
              { text: "Simplotel", value: "Simplotel" },
              { text: "Net Affinity", value: "Net Affinity" },
              { text: "Synxis", value: "Synxis" },
              { text: "BookingExpert", value: "BookingExpert" },
              { text: "Ermes Hotels", value: "Ermes Hotels" },
              { text: "TravelClick", value: "TravelClick" },
              { text: "Blastness", value: "Blastness" },
              { text: "Simplebooking", value: "Simplebooking" },
              { text: "Hotel Cinque Stelle", value: "Hotel Cinque Stelle" },
              { text: "Bnext", value: "Bnext" },
              { text: "CultBooking", value: "CultBooking" },
              { text: "Ericsoft", value: "Ericsoft" },
              { text: "BeMyGuest", value: "BeMyGuest" },
              { text: "Octorate", value: "Octorate" },
              { text: "Reservation in Cloud", value: "Reservation in Cloud" },
              { text: "Hotel in Cloud", value: "Hotel in Cloud" },
              { text: "AmberGris", value: "AmberGris" },
              { text: "Dhynet", value: "Dhynet" },
              { text: "Witbooking", value: "Witbooking" },
              { text: "Slope", value: "Slope" },
              { text: "Passpartout", value: "Passpartout" },
              { text: "MyGuestCare", value: "MyGuestCare" },
              { text: "Zak/ WoDoo", value: "Zak/ WoDoo" },
              { text: "WebHotelier", value: "WebHotelier" },
              { text: "Iperbooking", value: "Iperbooking" },
              { text: "RateTiger", value: "RateTiger" },
              { text: "MyForecast", value: "MyForecast" },
              { text: "Bedzzle", value: "Bedzzle" },
              { text: "Parity Rate", value: "Parity Rate" },
              { text: "Cloudbeds", value: "Cloudbeds" },
              { text: "Mews", value: "Mews" },
              { text: "EasyConsulting", value: "EasyConsulting" },
              { text: "WuBook", value: "WuBook" },
              { text: "Beddy", value: "Beddy" },
              { text: "Nozio", value: "Nozio" },
              { text: "Sysdat", value: "Sysdat" },
              { text: "Kross Travel", value: "Kross Travel" },
              { text: "Booking Designer", value: "Booking Designer" },
              { text: "Roiback", value: "Roiback" },
              { text: "Bed-And-Breakfast", value: "Bed-And-Breakfast" },
              { text: "Amenitiz", value: "Amenitiz" },
              { text: "Scidoo", value: "Scidoo" },
              { text: "InReception", value: "InReception" },
              { text: "Mirai", value: "Mirai" },
              { text: "iHotelier", value: "iHotelier" },
              { text: "Hotetec", value: "Hotetec" },
              { text: "Neobookings", value: "Neobookings" },
              { text: "Paraty Tech", value: "Paraty Tech" },
              { text: "Hotusa", value: "Hotusa" },
              { text: "HyperGuest", value: "HyperGuest" },
              { text: "ProfitRoom", value: "ProfitRoom" },
              { text: "RateGain", value: "RateGain" },
              { text: "Quovai", value: "Quovai" },
            ].sort((a, b) => (a.text > b.text ? 1 : -1)),
          ]}
          disabled={isLoading}
          defaultValue={partner?.bookingEngine}
          onChange={(e) =>
            setPartner({ ...partner, bookingEngine: e.target.value as any })
          }
        />
      </div>
      <br />
      <Button loading={isLoading} type="submit" text={"SAVE"} />
    </form>
  );
}

export default function Partners() {
  const { t } = useTranslation();
  const [partners, setPartners] = useState<iPartner[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState<number>(PAGE_DIM);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [sort] = useState({ liveDate: -1 });

  const [mode, setMode] = useState<"all" | "live" | "offline">("all");

  const elementRef = useRef<HTMLTableElement>(null);

  const loadPartners = async () => {
    setIsLoading(true);
    try {
      const params: any = {
        size,
        page,
        mode,
        sort,
      };

      if (query) params.query = { name: { $regex: query, $options: "i" } };

      const data = await getPartnersPaginated(params);

      setTotal(data.total || 0);

      setPartners(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  useEffect(() => {
    loadPartners();
    // eslint-disable-next-line
  }, [page, size, mode]);

  if (isLoading) return <Loader />;

  return (
    <main>
      <div className="container">
        <div className="content">
          <br />
          <Button
            onClick={() =>
              openModal(
                <CreatePartner onSuccess={() => loadPartners()} />,
                true
              )
            }
            text={"Create Partner"}
          />
          <hr />
          <form onSubmit={loadPartners}>
            <div className="d-flex gap-2">
              <input
                type="text"
                className="w100 m-0"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <div>
                <Button text="search" type="submit" />
              </div>
            </div>
          </form>
          <small>total: {total}</small>
          <div className="d-flex gap-2 mb-2">
            <Button
              text="all"
              light={mode !== "all"}
              disabled={mode === "all"}
              small
              onClick={() => {
                setMode("all");
              }}
            />
            <Button
              text="live"
              light={mode !== "live"}
              disabled={mode === "live"}
              small
              onClick={() => {
                setMode("live");
              }}
            />
            <Button
              text="offline"
              light={mode !== "offline"}
              disabled={mode === "offline"}
              small
              onClick={() => {
                setMode("offline");
              }}
            />
          </div>
          {partners.length ? (
            <>
              <table ref={elementRef}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Stars</th>
                    <th>Logo</th>
                    <th>Image</th>
                    <th>Live Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {partners.map((partner, key) => {
                    return (
                      <tr key={"partner_" + key}>
                        <td>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={partner.website}
                          >
                            {partner.name}
                          </a>
                        </td>
                        <td>{partner.location?.label}</td>
                        <td>{partner.stars}</td>
                        <td>
                          {partner.logo ? (
                            <img
                              style={{ width: "50px", maxHeight: "50px" }}
                              src={partner.logo}
                              alt="takyon partner logo"
                            />
                          ) : null}
                        </td>
                        <td>
                          {partner.image ? (
                            <img
                              style={{ width: "50px", maxHeight: "50px" }}
                              src={partner.image}
                              alt="takyon partner"
                            />
                          ) : null}
                        </td>

                        <td>{datetimeToString(partner.liveDate)}</td>

                        <td>
                          <Button
                            onClick={() =>
                              openModal(
                                <CreatePartner
                                  onSuccess={loadPartners}
                                  _partner={partner}
                                />,
                                true
                              )
                            }
                            small
                            text={"Edit"}
                          />

                          <Button
                            onClick={() =>
                              openModal(
                                <DeletePartner
                                  onSuccess={loadPartners}
                                  _partner={partner}
                                />
                              )
                            }
                            small
                            error
                            text={"Delete"}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                page={page}
                size={size}
                total={total}
                onSizeChange={onSizeChange}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <p>{t("pagination.no_show")}</p>
          )}
        </div>
      </div>
    </main>
  );
}
