import { isLoggedIn } from "../../services/auth";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/userSlice";
import Logo from "../logo/Logo";
import DropdownMenu from "../dom/DropdownMenu";
import userSvg from "../../assets/icons/user.svg";
import UserDropdown from "./UserDropdown";
import { useNavigate } from "react-router-dom";

export default function Nav() {
  const isLogged = isLoggedIn();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  return (
    <nav id="nav">
      <div className="cursor-pointer" onClick={() => navigate("/")}>
        <Logo />
      </div>
      {isLogged && user.emailVerified ? (
        <div className="right-section">
          <DropdownMenu
            position="down-left"
            customContent={<UserDropdown />}
            icon={userSvg}
          />
        </div>
      ) : null}
    </nav>
  );
}
