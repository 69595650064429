import { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  getAddressComponents,
  getGmt,
  getPlaceCoordinates,
} from "../../utils/generic";

interface Props {
  defaultValue?: any;
  onSelect?: Function;
}

export default function MapsAutocomplete({ onSelect, defaultValue }: Props) {
  const [value, setValue] = useState<any>();
  const [init, setInit] = useState(true);

  const getAddressComp = async (e: any) => {
    let data: any = {};
    let coords: any = {};
    let gmt: any;

    try {
      data = await getAddressComponents(e.value.place_id);
    } catch (error) {
      console.log(error);
    }

    try {
      coords = await getPlaceCoordinates(e.value.place_id);
    } catch (error) {
      console.log(error);
    }

    try {
      gmt = await getGmt(e.value.place_id);
    } catch (error) {
      console.log(error);
    }
    try {
      coords = await getPlaceCoordinates(e.value.place_id);
    } catch (error) {
      console.log(error);
    }

    onSelect?.({
      ...e,
      address_components: { ...data },
      coordinates: { ...coords },
      gmt,
    });
  };

  useEffect(() => {
    setValue(defaultValue);
  });

  if (init)
    // dummy, just to visualize
    return (
      <div onClick={() => setInit(false)} id="maps-autocomplete">
        <GooglePlacesAutocomplete
          selectProps={{
            value,
            onChange: (e: any) => {},
          }}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        />
      </div>
    );

  return (
    <div id="maps-autocomplete">
      <GooglePlacesAutocomplete
        selectProps={{
          onChange: (e: any) => {
            setValue(e);
            getAddressComp(e);
          },
        }}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      />
    </div>
  );
}
