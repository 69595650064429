import { useEffect, useState } from "react";
import Button from "../../components/dom/Button";
import { askGpt, editAiConfig, getAiConfig, runAiJob } from "../../services/ai";

export default function Ai() {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState("");

  const [ask, setAsk] = useState("");
  const [res, setRes] = useState("");

  const askAi = async () => {
    setRes("loading");
    setIsLoading(true);
    try {
      const res = await askGpt(ask);
      setRes(res);
    } catch (error) {
      console.log(error);
      setRes("error");
    }
    setIsLoading(false);
  };

  const load = async () => {
    setIsLoading(true);
    try {
      const data = await getAiConfig();
      setPrompt(data.prompt);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const save = async () => {
    setIsLoading(true);
    try {
      await editAiConfig(prompt);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    load();
  };

  const start = async () => {
    setIsLoading(true);
    try {
      const res = await runAiJob();
      setData(res);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <div className="container">
        <div className="content">
          <br />
          <h4>Gennaro</h4>
          <br />
          <h5>Instructions:</h5>

          <ol>
            <li>
              Go in the "Developer" section and create the Alias. The alias
              could be:
              <ul>
                <li>The email of the sender, or part of it.</li>
                <li>The email of the receiver, or part of it.</li>
                <li>An unique code included in each email.</li>
              </ul>
            </li>
            <li>Add the "Automated Creation" tag to booking emails.</li>
            <li>Click "START"</li>
            <li>
              Wait for the process to finish (check the logs in the Telegram
              group).
            </li>
          </ol>
          <ul>
            <li>
              Successfully created booking emails will be tagged with the
              "Automated Success" tag.
            </li>
            <li>
              Errored booking emails will be tagged with the "Automated Error"
              tag.
            </li>
          </ul>

          <br />
          <Button loading={isLoading} text="Start" onClick={start} />
          {JSON.stringify(data)}
          <br />
          <br />
          <hr />
          <br />
          <h5>AI prompt:</h5>
          <textarea
            rows={100}
            style={{
              width: "100%",
              maxHeight: "100000px",
              height: "800px",
            }}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          ></textarea>
          <Button loading={isLoading} text="save" onClick={() => save()} />
        </div>
        <br />
        <br />
        <hr />
        <br />
        <h5>Ask Gennaro</h5>
        <textarea
          style={{ height: "50vh", maxHeight: "unset" }}
          className="w100"
          value={ask}
          onChange={(e) => setAsk(e.target.value)}
        ></textarea>
        <Button loading={isLoading} onClick={askAi} text="Ask!" />
        <br />
        <p>Gennaro says:</p>
        <textarea
          style={{ height: "100vh", maxHeight: "unset" }}
          className="w100"
          value={res}
        ></textarea>
      </div>
    </>
  );
}
