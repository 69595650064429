import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./services/redux";
import "./styles/styles.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { initializeAxios } from "./services/axios";
import { initi18next } from "./services/i18next";
import { initializeLoggedUser } from "./services/auth";

import { initializeTagManager } from "./tagmanager";

initializeAxios();
initializeLoggedUser();
initi18next();

if (process.env.REACT_APP_ENV === "production") initializeTagManager();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <GoogleOAuthProvider
        clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}
      >
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
