import "./App.css";
import Router, { ScrollToTop } from "./Router";
import Footer from "./components/footer/Footer";
import Nav from "./components/nav/Nav";
import Modal from "./components/modal/Modal";
import CreateCollection from "./components/createcollection/CreateCollection";

function App() {
  return (
    <>
      <Nav />
      <Router />
      <Footer />
      {/* <CookiesBanner /> */}
      <Modal />
      <CreateCollection />
      {/* <CreateNft /> */}
      <ScrollToTop />
    </>
  );
}

export default App;
