import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import DropshipNfts from "./sub/DropshipNfts";
import { useEffect, useState } from "react";
import { getDropship, iDropship } from "../../services/dropship";
import Loader from "../../components/dom/Loader";
import { useSelector } from "react-redux";
import { selectGroup } from "../../store/groupSlice";
import { useTranslation } from "react-i18next";
import chevronBack from "../../assets/icons/double-left-chevron.svg";
import nftSvg from "../../assets/icons/nft.svg";
import crightSvg from "../../assets/icons/cright.svg";
import { refreshStoredGroup } from "../../services/groups";
import offersSvg from "../../assets/icons/collection.svg";
import usersSvg from "../../assets/icons/users.svg";
import paymentSvg from "../../assets/icons/payment.svg";
import settingsSvg from "../../assets/icons/settings.svg";
import DropshipPayments from "./sub/DropshipPayments";

function Redirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("nfts");
  }, []);
  return <></>;
}

export interface DropshipProps {
  dropship: iDropship;
  onRefresh?: Function;
}

function DropshipRouter({ dropship, onRefresh }: DropshipProps) {
  return (
    <Routes>
      <Route
        path="/nfts"
        element={<DropshipNfts onRefresh={onRefresh} dropship={dropship} />}
      />
      <Route
        path="/payment"
        element={<DropshipPayments onRefresh={onRefresh} dropship={dropship} />}
      />
      <Route path="/" element={<Redirect />} />
    </Routes>
  );
}

interface iMenuItem {
  name: string;
  icon?: any;
  separator?: boolean;
  uri?: string;
  selected?: boolean;
  primary?: boolean;
  disabled?: boolean;
}

export default function Dropship() {
  const [isLoading, setIsLoading] = useState(false);
  const [dropship, setDropship] = useState<iDropship>();
  const { id } = useParams();
  const navigate = useNavigate();
  const group = useSelector(selectGroup);
  const { t } = useTranslation();

  const menu: Array<iMenuItem> = [
    {
      name: "dropship.nfts",
      uri: "nfts",
      icon: nftSvg,
    },
    {
      name: "dropship.offers",
      uri: "offers",
      icon: offersSvg,
      separator: true,
      disabled: true,
    },
    {
      name: "dropship.members",
      uri: "members",
      icon: usersSvg,
      separator: true,
      disabled: true,
    },
    {
      name: "dropship.payments",
      uri: "payment",
      icon: paymentSvg,
    },
    {
      name: "dropship.access",
      uri: "access",
      icon: settingsSvg,
      disabled: true,
    },
  ];

  const loadDropship = async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      const data = await getDropship(id);
      await refreshStoredGroup(data.groupId);
      setDropship(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadDropship();
  }, [id]);

  if (isLoading) return <Loader />;

  return (
    <main id="dropship-page">
      <div className="side">
        <div
          className="back"
          onClick={() => {
            navigate(`/group/${group._id}/dropship`);
          }}
        >
          <img className="back-icon" src={chevronBack} alt="chevron back" />
          <small className="back-text">{t("dropship.back")}</small>
        </div>
        <p className="dropship-title">{dropship?.name}</p>
        {menu.map((item, key) => {
          return (
            <div key={"menu_item_" + key}>
              <div
                className={`menu-item ${item.selected ? "selected" : ""} ${
                  item.disabled ? "disabled" : ""
                }`}
                onClick={() => {
                  if (item.uri && !item.disabled) navigate(item.uri);
                }}
              >
                <img className="menu-icon" src={item.icon} alt="" />
                <span className="menu-item-text">{t(item.name)}</span>
                <img className="menu-icon-appear" src={crightSvg} alt="" />
              </div>
              {item.separator ? <div className="hr light"></div> : null}
            </div>
          );
        })}
      </div>
      <div className="content">
        <span className="h3">
          {t(String(menu.find((e) => e.selected)?.name ?? ""))}
        </span>
        {group._id && dropship ? (
          <DropshipRouter onRefresh={loadDropship} dropship={dropship} />
        ) : null}
      </div>
    </main>
  );
}
