import axios from "axios";

export interface iReport {
  _id: string;

  sent: boolean;
  sentAt: Date;

  collectionId: string;
  startDate: Date;
  endDate: Date;
  startDateOld: Date;
  endDateOld: Date;

  nftsAmount: number;
  nftsAmountOld: number;

  volume: number;
  volumeOld: number;

  numberOfResale: number;
  numberOfResaleOld: number;

  numberOfSale: number;
  numberOfSaleOld: number;

  traffic: number;
  trafficOld: number;

  averageBookingWindow: number;
  averageLenghtOfStay: number;

  nftsExport: Object[];
}

export async function getReports(collectionId: string) {
  const response = await axios.get(
    `/collectionreport?collectionId=${collectionId}`
  );
  return response.data;
}

export async function editReport(payload: any) {
  const response = await axios.patch("/collectionreport", payload);
  return response.data;
}

export async function createReport(payload: any) {
  const response = await axios.post("/collectionreport", payload);
  return response.data;
}

export async function createAllReport(payload: any) {
  const response = await axios.post("/collectionreport/all", payload);
  return response.data;
}

export async function sendReport(payload: any) {
  const response = await axios.post("/collectionreport/send", payload);
  return response.data;
}
