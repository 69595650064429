import { isDate, range } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Button from "./Button";
import Select from "./Select";
import { isValidDate } from "../../utils/generic";

interface Props {
  name: string;
  required?: boolean;
  defaultValue?: any;
  id?: string;
  onSelect?(date: string | undefined): any;
  minDate?: string;
  showTimeSelect?: boolean; // New parameter to control time selection
}

export default function CustomDatePicker(props: Props) {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date | null>();
  const [rawDate, setRawDate] = useState<string | null>();

  const years = range(new Date().getFullYear() + 5, 1900, -1);
  const months = [
    t("date.january"),
    t("date.february"),
    t("date.march"),
    t("date.april"),
    t("date.may"),
    t("date.june"),
    t("date.july"),
    t("date.august"),
    t("date.september"),
    t("date.october"),
    t("date.november"),
    t("date.december"),
  ];

  useEffect(() => {
    if (props.defaultValue) {
      const date = new Date(props.defaultValue);
      if (isValidDate(date)) {
        setStartDate(date);
        setRawDate(date.toISOString());
      }
    }
  }, [props]);

  const change = (date: Date | null) => {
    setStartDate(date);
    setRawDate(date?.toISOString());
    props.onSelect?.(date?.toISOString());
  };

  return (
    <>
      <input
        id={props.id ?? ""}
        hidden
        name={props.name}
        type="text"
        value={rawDate || ""}
        readOnly
        required={props.required}
      />
      <DatePicker
        required={props.required}
        onInputClick={() => {
          change(null);
        }}
        renderCustomHeader={({
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          date,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              small
              type="button"
              text="<"
              onClick={() => {
                decreaseMonth();
              }}
              disabled={prevMonthButtonDisabled}
            />

            <Select
              value={new Date(date).getFullYear()}
              small
              onChange={(e: any) => changeYear(Number(e.target.value))}
              options={years.map((y) => {
                return { value: y, text: y };
              })}
            />

            <Select
              value={months[new Date(date).getMonth()]}
              small
              onChange={(e: any) => changeMonth(months.indexOf(e.target.value))}
              options={months.map((m) => {
                return { value: m, text: m };
              })}
            />

            <Button
              small
              type="button"
              text=">"
              onClick={() => {
                increaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
            />
          </div>
        )}
        selected={startDate}
        onChange={change}
        minDate={props.minDate ? new Date(props.minDate) : null}
        showTimeSelect={props.showTimeSelect} // Enable time selection if specified
        dateFormat={props.showTimeSelect ? "Pp" : "P"} // Adjust format based on time selection
      />
    </>
  );
}
