import { openModal } from "../../components/modal/Modal";
import { useTranslation } from "react-i18next";

import { useEffect, useMemo, useRef, useState } from "react";
import { PAGE_DIM, scrollToTopList } from "../../utils/generic";
import {
  getCategoriesPaginated,
  iSupportFaq,
} from "../../services/supportfaqs";
import Loader from "../../components/dom/Loader";
import CreateCategoryModal from "./Sub/CategoryModalCreate";
import DeleteCategoryModal from "./Sub/CategoryModalDelete";
import Button from "../../components/dom/Button";
import Faqs from "./Sub/Faqs";

export default function SupportFaqs() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState<number>(PAGE_DIM);
  const elementRef = useRef<HTMLDivElement>(null);

  const [faqsCategories, setfaqsCategories] = useState<iSupportFaq[]>([]);
  const [isDetailsShowed, setIsDetailsShowed] = useState<
    iSupportFaq | undefined
  >();

  const loadSupportFaqs = async () => {
    setIsLoading(true);
    try {
      const data = await getCategoriesPaginated({ page, size });
      setfaqsCategories(data.data.reverse());

      setTotal(data.total || 0);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  useEffect(() => {
    loadSupportFaqs();
    // eslint-disable-next-line
  }, []);

  const catsByLanguage = useMemo(() => {
    const groupedCats: { [key: string]: iSupportFaq[] } = {};
    faqsCategories.forEach((cat) => {
      const language = cat.lang;
      if (!groupedCats[language]) {
        groupedCats[language] = [];
      }
      groupedCats[language].push(cat);
    });

    // Object.keys(groupedCats).forEach((language) => {
    //   groupedCats[language].reverse();
    // });

    return groupedCats;
  }, [faqsCategories]);

  if (isLoading) return <Loader />;

  if (isDetailsShowed)
    return (
      <Faqs
        // onSuccess={loadFaqs}
        onExit={() => setIsDetailsShowed(undefined)}
        category={isDetailsShowed}
      />
    );

  return (
    <main>
      <div className="container">
        <div className="content">
          <section id="support-section">
            <br />
            <Button
              onClick={() =>
                openModal(
                  <CreateCategoryModal onSuccess={() => loadSupportFaqs()} />
                )
              }
              text={String(t("support.create_category"))}
              className="create-btn"
            />
            <hr />

            <span className="title">{String(t("support.category_title"))}</span>

            {Object.keys(catsByLanguage).map((language) => (
              <div key={Math.random()} className="group-lang">
                <h4>{language}</h4>
                <div className="support-table">
                  {catsByLanguage[language].map((category: iSupportFaq) => (
                    <div
                      key={Math.random()}
                      onClick={() => {
                        setIsDetailsShowed(category);
                      }}
                      className="support-item"
                    >
                      <img
                        src={category.icons[0]}
                        alt="icon"
                        className="support-icon"
                      />

                      <div className="cat-name">{category.name}</div>

                      <div className="btns-container">
                        <Button
                          onClick={(e: any) => {
                            e.stopPropagation();
                            openModal(
                              <CreateCategoryModal
                                category={category}
                                onSuccess={loadSupportFaqs}
                              />
                            );
                          }}
                          text={String(t("support.edit_category"))}
                          small
                        />

                        <Button
                          onClick={(e: any) => {
                            e.stopPropagation();
                            openModal(
                              <DeleteCategoryModal
                                category={category}
                                onSuccess={loadSupportFaqs}
                              />
                            );
                          }}
                          text={String(t("support.delete_category"))}
                          error
                          small
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {faqsCategories.length === 0 && (
              <div>{String(t("support.no_cats"))}</div>
            )}
          </section>
        </div>
      </div>
    </main>
  );
}
