import { t } from "i18next";
import { useParams } from "react-router-dom";
import CreateNft from "./CreateNft";
import UpsertExperience from "./UpsertExperience";

export default function CreateNftByType() {
    const { nftType } = useParams();

    // Switch between different forms based on nftType
    let formComponent;
    switch (nftType) {
        case 'experience':
            formComponent = <UpsertExperience />;
            break;
        default:
            formComponent = <CreateNft />;
    }


    return  ( 
        <section>
        <span className="title">{t(`nft.${nftType}`)}</span>
        {formComponent}
        </section>
    );
}