import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { openModal } from "../../../components/modal/Modal";
import { setIsLoading } from "../../../store/appSlice";
import {
  getFaqsInCategory,
  iFaq,
  iSupportFaq,
} from "../../../services/supportfaqs";
import FaqModalDelete from "./FaqModalDelete";
import FaqModalEdit from "./FaqModalEdit";
import FaqModalCreate from "./FaqModalCreate";
import Button from "../../../components/dom/Button";

import trashImg from "../../../assets/icons/trash.svg";

interface CategoryFaqsProps {
  onExit: Function;
  category: iSupportFaq;
}

export default function Faqs({ onExit, category }: CategoryFaqsProps) {
  const { t } = useTranslation();

  const [faqs, setFaqs] = useState<iFaq[]>([]);

  const loadFaqs = async () => {
    setIsLoading(true);
    try {
      const data = await getFaqsInCategory(category._id);
      setFaqs(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadFaqs();
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      <div className="container">
        <div className="content">
          <section id="support-section">
            <br />
            <div>
              <Button
                onClick={() => onExit?.()}
                small
                text={String(t("payment.back"))}
              />
            </div>
            <br />
            <Button
              onClick={(e: any) => {
                e.stopPropagation();
                openModal(
                  <FaqModalCreate category={category} refresh={loadFaqs} />
                );
              }}
              className="create-btn"
              text={String(t("support.create_faq"))}
            />
            <hr />

            <span className="title">
              {category.name} {`( ${category.lang} )`}
            </span>
            <span></span>

            <div className="support-table">
              {faqs.map((faq: iFaq) => (
                <div
                  key={Math.random()}
                  className="support-item"
                  onClick={() =>
                    openModal(
                      <FaqModalEdit
                        faq={faq}
                        category={category}
                        refresh={loadFaqs}
                      />
                    )
                  }
                >
                  <div className="left">
                    <div>{faq.question}</div>
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                  </div>

                  <div className="rigth">
                    <img
                      onClick={(e: any) => {
                        e.stopPropagation();
                        openModal(
                          <FaqModalDelete
                            faq={faq}
                            category={category}
                            refresh={loadFaqs}
                          />
                        );
                      }}
                      className="p-1"
                      src={trashImg}
                      alt="trash icon"
                    />
                  </div>
                </div>
              ))}
            </div>

            {faqs.length === 0 && <div>{String(t("support.no_faqs"))}</div>}
          </section>
        </div>
      </div>
    </main>
  );
}
