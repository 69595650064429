import { FormEvent, useEffect, useState } from "react";
import { LANG } from "../../../services/i18next";

import { createCategory } from "../../../services/supportfaqs";
import { compressImage, formToObject } from "../../../utils/generic";
import { closeModal } from "../../../components/modal/Modal";
import { useTranslation } from "react-i18next";
import Button from "../../../components/dom/Button";
import Select from "../../../components/dom/Select";

import closeIcon from "../../../assets/icons/close.svg";

interface CategoryModalCreateProps {
  onSuccess: Function;
  category?: any;
}

export default function CategoryModalCreate({
  onSuccess,
  category,
}: CategoryModalCreateProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const [images, setImages] = useState<string[]>([]);

  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const createCategorySubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    setIsLoading(true);

    try {
      const data = formToObject(e.target);
      category?._id && (data._id = category._id);
      category?.faqs ? (data.faqs = category.faqs) : (data.faqs = []);

      images.length > 0 ? (data.icons = images) : (data.icons = []);

      await createCategory(data);
      closeModal();
      onSuccess();
    } catch (error: any) {
      const errorMessage = String(error.response).toLowerCase();
      console.log(errorMessage);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (category && category.icons && category.icons.length > 0) {
      setImages(category.icons);
    }
  }, [category]);

  const imageChangeHandler = async (e: any) => {
    const imageFiles = Array.from(e.target.files);

    setIsLoading(true);

    const readAsyncPromises = imageFiles.map(async (image: any) => {
      return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage as string);
          }
        };
      });
    });

    const newImages = await Promise.all(readAsyncPromises);

    if (clickedIndex !== null) {
      const updatedImages = [...images];
      updatedImages[clickedIndex] = newImages[0];
      setImages(updatedImages);

      setClickedIndex(null);
    } else {
      if (images.length + newImages.length <= 2) {
        setImages([...images, ...newImages]);
      } else {
        setError("You can only upload 2 images");
      }
    }

    setIsLoading(false);
  };

  const handleImageClick = (index: number) => {
    document.getElementById("category-icon")?.click();
    setClickedIndex(index);
  };

  const handleImageDelete = (indexToDelete: number) => {
    const updatedImages = [...images];
    updatedImages.splice(indexToDelete, 1);
    setImages(updatedImages);
    setError("");
  };

  isLoading && <div className="loader"></div>;

  return (
    <div id="faq-modal">
      <form onSubmit={createCategorySubmit} className="modal-form">
        <label htmlFor="language">{t("support.language")}</label>
        <Select
          name="lang"
          options={Object.keys(LANG).map((lang) => {
            return {
              text: lang,
              value: lang,
            };
          })}
          className="lang"
          defaultValue={category?.lang ?? LANG.en}
          id="language"
          required
        />

        <label htmlFor="name">{t("support.cat_name")}</label>
        <input
          type="text"
          id="name"
          name="name"
          defaultValue={category?.name ?? ""}
          required
        />

        <div className="item-content">
          <label htmlFor="category-icon">{t("support.icons")}</label>
          <input
            onChange={imageChangeHandler}
            type="file"
            name="icon"
            id="category-icon"
            accept="image/png, image/jpeg, image/svg+xml"
            hidden
            multiple
          />
          <div className="img-preview-container">
            <Button
              text={String(t("support.upload_icon"))}
              small
              light
              className="choose-btn"
              onClick={() => {
                document.getElementById("category-icon")?.click();
              }}
            />

            {images.map((image, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
                className="image"
                onClick={() => handleImageClick(index)}
              >
                <div
                  className="delete-icon"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleImageDelete(index);
                  }}
                >
                  <img src={closeIcon} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>

        {error && <span className="error">{error}</span>}

        <Button
          className="btn btn-primary"
          text={String(t("support.save"))}
          type="submit"
          loading={isLoading}
        />
      </form>
    </div>
  );
}
