import { useEffect, useState } from "react";
import { CollectionRouterProps } from "../Collection";
import { PAGE_DIM, datetimeToString } from "../../../utils/generic";
import { selectUser } from "../../../store/userSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCollectionEventsPaginated,
  iNftEvent,
} from "../../../services/event";
import Loader from "../../../components/dom/Loader";
import { closeModal, openModal } from "../../../components/modal/Modal";
import NftModal from "../NftModal";
import viewSvg from "../../../assets/icons/view.svg";
import Pagination from "../../../components/dom/Pagination";
import { GROUP_PERMISSIONS } from "../../../services/groups";
import { COLLECTION_PERMISSIONS } from "../../../services/collection";
import {
  NFT_SELL_STATUS,
  getNftsOfCollectionPaginated,
  iNft,
  putNftForSalePending,
} from "../../../services/nft";
import Button from "../../../components/dom/Button";

interface CheckNftForSaleProps {
  nft: iNft;
  onSuccess: Function;
}

function CheckNftForSale({ nft, onSuccess }: CheckNftForSaleProps) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    setIsLoading(true);
    try {
      await putNftForSalePending(nft._id!);

      closeModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <p className="text-center">
        {t("collection.nft_check_put_for_sale_modal")} {nft.payload?.reference}
      </p>
      <br />
      <div className="w100 d-flex justify-content-around">
        <Button
          light
          onClick={closeModal}
          text={String(t("collection.nft_check_put_for_sale_modal_cancel"))}
        />
        <Button
          onClick={confirm}
          loading={isLoading}
          text={String(t("collection.nft_check_put_for_sale_modal_confirm"))}
        />
      </div>
    </div>
  );
}

export default function CollectionEvents({
  collection,
  group,
}: CollectionRouterProps) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const [events, setEvents] = useState<iNftEvent[]>([]);
  const [nftsToCheck, setNftsToCheck] = useState<iNft[]>([]);

  const loadEvents = async () => {
    setIsLoading(true);
    try {
      const pagination = await getCollectionEventsPaginated(collection._id, {
        page,
        size,
      });

      setTotal(pagination.total);
      setEvents(pagination.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadNfts = async () => {
    setIsLoading(true);
    try {
      const nfts = await getNftsOfCollectionPaginated({
        collectionId: collection._id,
        page: 1,
        size: 500,
        query: { sellStatus: NFT_SELL_STATUS.PENDING_SALE },
      });
      setNftsToCheck(nfts.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const canSeeEvents = () => {
    if (user.isAdmin) return true;

    const myGroupPerms = group.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myGroupPerms?.includes(GROUP_PERMISSIONS.admin) ||
      myGroupPerms?.includes(GROUP_PERMISSIONS.collection)
    )
      return true;

    const myCollectionPerms = collection.members?.find(
      (e) => e.email === user.email
    )?.permissions;

    if (
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.admin) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.view_events) ||
      myCollectionPerms?.includes(COLLECTION_PERMISSIONS.sale)
    )
      return true;

    return false;
  };

  useEffect(() => {
    if (canSeeEvents()) {
      loadEvents();
      loadNfts();
    }
  }, [collection]);

  if (isLoading)
    return (
      <div className="container">
        <div className="content">
          <Loader />
        </div>
      </div>
    );

  if (!canSeeEvents()) return <section>{t("collection.no_access")}</section>;

  return (
    <section>
      {nftsToCheck.length ? (
        <>
          <br />
          <p className="info-box p-3">
            [ <u>{t("collection.nft_check_put_for_sale_text_1")}</u> ]
            {t("collection.nft_check_put_for_sale_text_2")}
          </p>
          <table>
            <thead>
              <tr>
                <th>{t("collection.nft_view")}</th>
                <th>#</th>
                <th>{t("collection.nft_ref")}</th>
                <th>{t("collection.nft_price")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {nftsToCheck.map((nft, key) => {
                return (
                  <tr key={"nfttocheck_" + key}>
                    <td>
                      <img
                        className="view-icon"
                        onClick={() => {
                          openModal(<NftModal nftId={nft._id!} />);
                        }}
                        src={viewSvg}
                        alt="eye icon"
                      />
                    </td>
                    <td>
                      {String(nft._id).substring(String(nft._id).length - 6)}
                    </td>
                    <td>{nft.payload?.reference}</td>
                    <td>{Number(nft.currentPrice).toFixed(2)}€</td>
                    <td>
                      <Button
                        onClick={() =>
                          openModal(
                            <CheckNftForSale onSuccess={loadNfts} nft={nft} />
                          )
                        }
                        small
                        text={String(t("collection.nft_check_put_for_sale"))}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : null}
      <br />
      {events.length === 0 && page === 1 ? (
        <>
          <p>{t("pagination.no_show")}</p>
        </>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>{t("collection.events_view")}</th>
                <th>#</th>
                <th>{t("collection.events_ref")}</th>
                <th>{t("collection.events_mode")}</th>
                <th>{t("collection.events_price")}</th>
                <th>{t("collection.events_date")}</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, key) => {
                return (
                  <tr key={"event_" + key}>
                    <td>
                      <img
                        className="view-icon"
                        onClick={() => {
                          openModal(<NftModal nftId={event._nft?._id!} />);
                        }}
                        src={viewSvg}
                        alt="eye icon"
                      />
                    </td>
                    <td>
                      {String(event._nft?._id).substring(
                        String(event._nft?._id).length - 6
                      )}
                    </td>
                    <td>{event._nft?.payload?.reference}</td>
                    <td>{String(event.payload.mode).toUpperCase()}</td>
                    <td>{Number(event.payload.price).toFixed(2)}€</td>
                    <td>{datetimeToString(event._createdAt)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Pagination
            page={page}
            size={size}
            total={total}
            onPageChange={setPage}
            onSizeChange={setSize}
          />
        </>
      )}
    </section>
  );
}
