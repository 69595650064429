import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANG } from "../../../services/i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { formToObject } from "../../../utils/generic";
import { editDocument, iDocument } from "../../../services/documents";
import { closeModal } from "../../../components/modal/Modal";
import Select from "../../../components/dom/Select";
import Button from "../../../components/dom/Button";

interface DocProps {
  onSuccess: Function;
  doc?: iDocument;
  onExit?: Function;
}

export default function CreateDocModal({ onSuccess, doc, onExit }: DocProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");
  const [textValue, setTextValue] = useState(doc ? doc.text : "");

  const createEditSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    setIsLoading(true);

    try {
      const data = formToObject(e.target);
      if (doc?._id) data._id = doc._id;
      data.text = textValue;

      await editDocument(data);
      closeModal();
      onSuccess();
    } catch (error: any) {
      const errorMessage = String(error.response).toLowerCase();
      console.log(errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <div id="faq-modal">
      <div>
        <Button
          onClick={() => onExit?.()}
          small
          text={String(t("payment.back"))}
        />
      </div>
      <form onSubmit={createEditSubmit} className="modal-form">
        <label htmlFor="language">{t("documents.language")}</label>
        <Select
          name="lang"
          options={Object.keys(LANG).map((lang) => {
            return {
              text: lang,
              value: lang,
            };
          })}
          className="lang"
          defaultValue={doc?.lang ?? LANG.en}
          id="language"
          required
        />
        <label htmlFor="type">{t("documents.doc_type")}</label>
        <input type="text"
          id="type"
          name="type"
          defaultValue={doc?.type ?? ""}
          required
        />
        <label htmlFor="name">{t("documents.doc_name")}</label>
        <input
          type="text"
          id="name"
          name="name"
          defaultValue={doc?.name ?? ""}
          required
        />

        <label htmlFor="text">{t("documents.doc_text")}</label>

        <ReactQuill
          theme="snow"
          value={textValue}
          onChange={(val) => setTextValue(val)}
        />

        {error ? <span className="error">{error}</span> : null}

        <Button
          className="btn-edit-doc"
          text={String(t("documents.save"))}
          type="submit"
          loading={isLoading}
        />
      </form>
    </div>
  );
}
