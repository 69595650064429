import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { formToObject } from "../../../utils/generic";
import {
  editFaqInCategory,
  iFaq,
  iSupportFaq,
} from "../../../services/supportfaqs";
import { closeModal } from "../../../components/modal/Modal";
import Button from "../../../components/dom/Button";

interface FaqModalEditProps {
  refresh: Function;
  category: iSupportFaq;
  faq: iFaq;
}

export default function FaqModalEdit({
  refresh,
  category,
  faq,
}: FaqModalEditProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const editFaqSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    setIsLoading(true);

    try {
      const data = formToObject(e.target);
      faq?._faqId && (data._faqId = faq._faqId);
      faq.language && (data.language = faq.language);

      await editFaqInCategory({ _id: category._id, faq: data });
      closeModal();
      refresh();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div id="faq-modal">
      <span className="title">{t("support.edit_faq")}</span>

      <form className="modal-form" onSubmit={editFaqSubmit}>
        <label htmlFor="question">{t("support.question")}</label>
        <textarea
          id="question"
          name="question"
          defaultValue={faq.question}
          required
        />

        <label htmlFor="answer">{t("support.answer")}</label>
        <textarea
          id="answer"
          name="answer"
          defaultValue={faq.answer}
          required
        />
        <Button
          className="btn btn-primary"
          text={String(t("support.save"))}
          type="submit"
          loading={isLoading}
        />
      </form>
    </div>
  );
}
