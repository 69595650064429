import axios from "axios";
import { Pagination } from "../utils/Pagination";
import { iNft } from "./nft";

export enum NFT_EVENT_TYPE {
  CREATE = "CREATE",
  GOT_FROM_EMAIL = "GOT_FROM_EMAIL",
  EDIT = "EDIT",
  PUT_FOR_SALE = "PUT_FOR_SALE",
  REMOVE_FROM_SALE = "REMOVE_FROM_SALE",
  MARKET_PRICE = "MARKET_PRICE",
  SOLD = "SOLD",
  BID = "BID",
  REDEEM = "REDEEM",
  CHANGE_GUESTS = "CHANGE_GUESTS",
  LOCK = "LOCK",
  TRANSFER = "TRANSFER",
  DELETE = "DELETE",
  UNLOCK = "UNLOCK",
  CHANGE_PRICE = "CHANGE_PRICE",
}

export interface iNftEvent {
  _id: string;

  nftId: string;
  collectionId: string;
  type: NFT_EVENT_TYPE;
  payload: any;

  _createdAt: any;

  _nft: iNft;
}

export async function getCollectionEventsPaginated(
  collectionId: string,
  params: {
    page: number;
    size: number;
    query?: any;
    sort?: any;
  }
): Promise<Pagination<iNftEvent>> {
  const response = await axios.get(`/nftevent/collection/${collectionId}`, {
    params,
  });
  return response.data;
}
