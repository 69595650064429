import { useTranslation } from "react-i18next";
import {
  NFT_OWNER_TYPE,
  NFT_SELL_STATUS,
  getNft,
  iNft,
  payloadHotelType,
} from "../../services/nft";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/userSlice";
import { datetimeToString } from "../../utils/generic";
import { useEffect, useState } from "react";
import Loader from "../../components/dom/Loader";
import { selectCollection } from "../../store/collectionSlice";
import DetailView from "../../components/dom/DetailView";

interface Props {
  nftId: string;
}

export default function NftModal({ nftId }: Props) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [nft, setNft] = useState<iNft<payloadHotelType>>();
  const [isLoading, setIsLoading] = useState(false);

  const collection = useSelector(selectCollection);

  const loadNft = async () => {
    setIsLoading(true);
    try {
      const data = await getNft(nftId);
      setNft(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadNft();
    // eslint-disable-next-line
  }, []);

  if (isLoading) return <Loader />;
  if (!nft) return <></>;

  return (
    <section id="nft-detail-modal">
      <a
        target={"_blank"}
        rel="noreferrer"
        href={`${process.env.REACT_APP_FRONTEND_URI}/tak/${nft._id}`}
      >
        {t("nft.view_exchange")} 🔗
      </a>
      <p className="title">{t("nft.info")}</p>
      <table className="light">
        <thead>
          <tr>
            <th>{t("nft.id")}</th>
            <th>{t("nft.created_at")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{String(nft._id).substring(String(nft._id).length - 6)}</td>
            <td>
              {datetimeToString(
                nft._createdAt,
                collection.census?.location?.gmt,
                true,
                true
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {user.isAdmin ? (
        <>
          <p>
            {t("nft.id_full")}: {nft._id}
          </p>
          <p>
            {t("nft.short_id")}: {nft.shortId}
          </p>
        </>
      ) : null}
      {user.isAdmin ? (
        <p>
          {t("nft.solidity_id")}: {nft.solidityId}
        </p>
      ) : null}
      {user.isAdmin ? (
        <p>
          {t("nft.lockdate")}: {nft.lockDate} (
          {collection.census?.location?.gmt}) -{" "}
          {datetimeToString(
            nft.lockDate,
            collection.census?.location?.gmt,
            true,
            true
          )}
        </p>
      ) : null}

      <p className="title">{t("nft.census")}</p>

      {nft._collectionCensus && <DetailView data={nft._collectionCensus} />}

      <table className="light">
        <thead>
          <tr>
            <th>{t("nft.hotel")}</th>
            <th>{t("nft.website")}</th>
            <th>{t("nft.location")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {nft._collectionCensus?.name} ({} {t("nft.stars")})
            </td>
            <td>
              <a
                href={nft._collectionCensus?.website}
                target="_blank"
                rel="noreferrer"
              >
                {nft._collectionCensus?.website}
              </a>
            </td>
            <td>{nft._collectionCensus?.location?.label}</td>
          </tr>
        </tbody>
      </table>

      {nft._collectionCensus?.note ? (
        <>
          <p>
            <u>{t("nft.note")}</u>: {nft.payload?.note}
          </p>
          <br />
        </>
      ) : null}

      <p className="title">{t("nft.booking")}</p>
      <table className="light">
        <thead>
          <tr>
            <th>{t("nft.reference")}</th>
            <th>{t("nft.checkin")}</th>
            <th>{t("nft.checkout")}</th>
            <th>{t("nft.board")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{nft.payload?.reference}</td>
            <td>
              {datetimeToString(
                nft.payload?.checkin,
                collection.census?.location?.gmt
              )}
            </td>
            <td>
              {datetimeToString(
                nft.payload?.checkout,
                collection.census?.location?.gmt
              )}
            </td>
            <td>{nft.payload?.board}</td>
          </tr>
        </tbody>
      </table>

      {nft.payload?.extra ? (
        <>
          <p>
            <u>{t("nft.extra")}</u>: {nft.payload?.extra}
          </p>
          <br />
        </>
      ) : null}

      <p className="title">{t("nft.guests")}</p>
      <p>
        <u>{t("nft.owner")}</u>: <a href={`mailto:${nft.owner}`}>{nft.owner}</a>
      </p>
      {nft._owner?.firstName || nft._owner?.secondName ? (
        <p>
          <u>{t("nft.owner_name")}</u>: {nft._owner?.firstName}{" "}
          {nft._owner?.secondName}
        </p>
      ) : null}
      {nft._owner?.phone ? (
        <p>
          <u>{t("nft.owner_phone")}</u>: {nft._owner.phonePrefix}{" "}
          {nft._owner?.phone}
        </p>
      ) : null}
      {nft.guests?.map((guest, key) => {
        return (
          <p key={"guest_" + key}>
            - {guest.firstName} {guest.secondName}{" "}
            <a href={`mailto:${guest.email}`}>{guest.email}</a>
          </p>
        );
      })}
      <p className="title">{t("nft.rooms")}</p>
      {nft.payload?.rooms?.map((room, key) => {
        return (
          <p key={"room_" + key}>
            - {room.name}: {room.guestsAdults ?? 0} {t("nft.guest_adults")}{" "}
            {room.guestsKids ?? 0} {t("nft.guest_kids")} / ({room.amenities})
          </p>
        );
      })}

      <p className="title">{t("nft.images")}</p>
      {!nft.images || nft.images.length === 0 ? (
        <>{t("nft.no_images")}</>
      ) : null}
      <div className="images-container d-flex flex-wrap">
        {nft.images?.map((img, key) => {
          return (
            <div
              key={"img_" + key}
              style={{
                background: `url(${img})`,
                height: "100px",
                width: "100px",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            ></div>
          );
        })}
      </div>

      <br />

      <p className="title">{t("nft.status")}</p>
      <table className="light">
        <thead>
          <tr>
            <th>{t("nft.status")}</th>
            <th>{t("nft.owner_type")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE
                ? "🟢 " + t("nft.for_sale")
                : "⚪ " + t("nft.not_for_sale")}
            </td>
            <td>
              {nft.ownerType === NFT_OWNER_TYPE.ORIGINAL
                ? "⚪ " + t("nft.owner_original")
                : "🟡 " + t("nft.owner_resold")}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}
