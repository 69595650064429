import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { refreshStoredGroup } from "../../services/groups";
import { selectGroup } from "../../store/groupSlice";
import { useTranslation } from "react-i18next";
import GroupCollections from "./sub/GroupCollections";
import collectionSvg from "../../assets/icons/collection.svg";
import usersSvg from "../../assets/icons/users.svg";
import settingsSvg from "../../assets/icons/settings.svg";
import GroupInfo from "./sub/GroupInfo";
import GroupMembers from "./sub/GroupMembers";
import GroupAccess from "./sub/GroupAccess";
import GroupDropship from "./sub/GroupDropship";
import Loader from "../../components/dom/Loader";

function Redirect() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("collection");
  }, []);
  return <></>;
}

function GroupRouter() {
  return (
    <Routes>
      <Route path="/collection" element={<GroupCollections />} />
      {/* <Route path="/info" element={<GroupInfo />} />
      <Route path="/access" element={<GroupAccess />} /> */}
      <Route
        path="/settings"
        element={
          <>
            <GroupInfo />
            <hr />
            <GroupAccess />
          </>
        }
      />
      <Route path="/member" element={<GroupMembers />} />
      <Route path="/dropship" element={<GroupDropship />} />
      <Route path="/" element={<Redirect />} />
    </Routes>
  );
}

interface iMenuItem {
  name: string;
  icon?: any;
  separator?: boolean;
  uri?: string;
  selected?: boolean;
}

export default function Group() {
  const { t } = useTranslation();

  const menu: Array<iMenuItem> = [
    {
      name: t("group.collections"),
      uri: "collection",
      icon: collectionSvg,
    },
    // {
    //   name: t("group.info"),
    //   uri: "info",
    //   icon: infoSvg,
    // },
    // {
    //   name: t("group.access"),
    //   uri: "access",
    //   icon: settingsSvg,
    // },
    // {
    //   name: t("group.dropship"),
    //   uri: "dropship",
    //   icon: collectionSvg,
    // },
    {
      name: t("group.members"),
      uri: "member",
      icon: usersSvg,
    },
    {
      name: t("group.settings"),
      uri: "settings",
      icon: settingsSvg,
    },
  ];

  const group = useSelector(selectGroup);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [menuitems, setMenuitems] = useState<Array<iMenuItem>>([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const loadGroup = async (id: string) => {
    setIsLoading(true);
    try {
      await refreshStoredGroup(id);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!id) return;
    loadGroup(id);

    setMenuitems(() => {
      return menu.map((i: iMenuItem) => {
        if (pathname.includes(String(i.uri))) i.selected = true;
        return i;
      });
    });

    // eslint-disable-next-line
  }, [pathname]);

  if (!group._id || isLoading)
    return (
      <div className="container">
        <div className="content">
          <Loader />
        </div>
      </div>
    );

  return (
    <main id="group-page">
      <div className="head-container">
        <div className="container">
          <div className="head">
            {/* <img src={groupSvg} alt="group logo" className="group-logo" /> */}
            <span className="title">{group.name}</span>
          </div>
          {!group.verified ? (
            <>
              <div className="not-verified">
                {t("group.not_verified")}{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${t("group.not_verified_1")}`}
                >
                  {t("group.not_verified_1")}
                </a>
              </div>
            </>
          ) : null}
          <div className="head-menu">
            {menuitems.map((item, key) => {
              return (
                <div
                  onClick={() => {
                    if (item.uri) navigate(item.uri);
                  }}
                  className={`menu-item ${item.selected ? "selected" : ""}`}
                  key={key}
                >
                  <div className="icon">
                    <img src={item.icon} alt="menu item icon" />
                  </div>
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container">
        <GroupRouter />
      </div>
    </main>
  );
}
