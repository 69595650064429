import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { selectCollection } from "../../store/collectionSlice";
import { selectUser } from "../../store/userSlice";
import CustomDatePicker from "../dom/CustomDatePicker";
import {
  createNft,
  getNft,
  iNft,
  patchNft,
  payloadExperienceType,
} from "../../services/nft";
import Select from "../dom/Select";
import { compressImage, dateToGMT } from "../../utils/generic";
import Button from "../dom/Button";
import { refreshStoredCollection } from "../../services/collection";

import { closeModal } from "../modal/Modal";

import { LANG } from "../../services/i18next";
import MapsAutocomplete from "../mapsautocomplete/MapsAutocomplete";

export default function UpsertExperience({ nftId }: any) {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const voidNft = {
    payload: {
      type: "experience",
    },
    guests: [{}],
    images: [],
  };
  const [nft, setNft] = useState<iNft<payloadExperienceType>>(voidNft);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (nftId) {
      loadNft(nftId);
    }
  }, []);

  const imagesUploadInput = (e: any) => {
    const images = Array.from(e.target.files);

    images.forEach(async (image: any) => {
      const readAsync = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage);
          }
        };
      });

      setNft((nft) => {
        if (nft.images!.length >= 10) return { ...nft };
        nft.images!.push(readAsync as string);
        return { ...nft };
      });
    });
  };

  const loadNft = async (id: string) => {
    setIsLoading(true);
    try {
      let data = voidNft;
      const myNft = await getNft(id);
      setNft({ ...data, ...myNft });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const [step, setStep] = useState(0);

  const collection = useSelector(selectCollection);

  const saveNftClick = async () => {
    setIsLoading(true);
    try {
      if (isEditMode) await patchNft(nft);
      else await createNft({ ...nft, collectionId: collection._id });
      await refreshStoredCollection(collection._id);
      navigate("/collection/" + collection._id);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const step1 = (
    <section>
      <span className="title">{t("nft.booking_info")}</span>
      <p>{t("nft.booking_info_text")}</p>

      <div className="form-container">
        {user?.isAdmin && (
          <div className="form-group">
            <label htmlFor="nft-form-createdAt">{t("nft.createdAt")}</label>
            <CustomDatePicker
              onSelect={(e: string) =>
                setNft((nft) => {
                  nft._createdAt = e;
                  return { ...nft };
                })
              }
              id="nft-form-createdAt"
              name="_createdAt"
              defaultValue={nft._createdAt}
            />
          </div>
        )}

        <br />
        <br />
        <hr />
        <br />
        <br />

        <div className="form-group">
          <label htmlFor="nft-form-reference">{t("nft.reference")}</label>
          <input
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.reference = e.target.value;
                return { ...nft };
              })
            }
            type="text"
            id="nft-form-reference"
            defaultValue={nft.payload?.reference}
            name="reference"
          />
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-category">{t("nft.category")}</label>
          <input
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.category = e.target.value;
                return { ...nft };
              })
            }
            type="text"
            id="nft-form-category"
            defaultValue={nft.payload?.category}
            name="category"
          />
        </div>

        <div>
          <label htmlFor="">{t("collection.census_location")}</label>
          <MapsAutocomplete
            onSelect={(e: any) => {
              const nftCopy = structuredClone(nft);

              if (!nftCopy.payload) nftCopy.payload = {};

              nftCopy.payload.location = e;
              setNft(nftCopy);

              setNft(nftCopy);
            }}
            defaultValue={nft.payload?.location}
          />
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-duration">{t("nft.duration")}</label>
          <input
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.duration = Number(e.target.value);
                return { ...nft };
              })
            }
            type="number"
            id="nft-form-duration"
            defaultValue={nft.payload?.duration}
            name="duration"
          />
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-participants">{t("nft.participants")}</label>
          <input
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.participants = Number(e.target.value);
                return { ...nft };
              })
            }
            type="number"
            id="nft-form-participants"
            defaultValue={nft.payload?.participants}
            name="participants"
          />
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-price">{t("nft.price")} (€)</label>
          <input
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.originalPrice = Number(e.target.value);
                return { ...nft };
              })
            }
            type="number"
            min={0}
            step={0.01}
            id="nft-form-price"
            value={nft.originalPrice ?? 0}
            name="price"
            onClick={(e) => (e.target as any).select()}
          />
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-checkin">
            {t("nft.date")} ({t("nft.required")})
          </label>
          <CustomDatePicker
            showTimeSelect
            defaultValue={nft.payload?.date}
            onSelect={(e) => {
              setNft((nft) => {
                if (e) {
                  nft.payload!.date = dateToGMT(
                    e,
                    collection.census?.location?.gmt || 0
                  ).toISOString();
                  // const dayAfter = new Date(e);
                  // dayAfter.setDate(dayAfter.getDate() + 1);
                  // nft.payload!.date = dayAfter.toISOString();
                }
                return { ...nft };
              });
            }}
            id="nft-form-checkin"
            name="checkin"
          />
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-lockdate">
            {t("nft.lockdate")} ({t("nft.required")})
          </label>
          <CustomDatePicker
            defaultValue={nft.lockDate}
            onSelect={(e) => {
              setNft((nft) => {
                if (e) {
                  nft.lockDate = dateToGMT(
                    e,
                    collection.census?.location?.gmt || 0
                  ).toISOString();
                }
                return { ...nft };
              });
            }}
            id="nft-form-lockdate"
            name="lockdate"
          />
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-description">{t("nft.description")}</label>
          <textarea
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.description = e.target.value;
                return { ...nft };
              })
            }
            cols={50}
            id="nft-form-description"
            defaultValue={nft.payload?.description}
            name="description"
          ></textarea>
        </div>

        <br />
        <br />
        <hr />
        <br />
        <br />

        <h3>Steps</h3>

        {nft.payload?.tour?.map((tour, key) => (
          <div key={"tour_" + key}>
            <hr />
            <p>
              <strong>STEP {tour.step}</strong>
            </p>

            <div className="form-group">
              <label htmlFor="nft-form-name">{t("nft.name")}</label>
              <input
                disabled={isLoading}
                onChange={(e) =>
                  setNft((nft) => {
                    nft.payload!.tour![key]!.name = e.target.value;
                    return { ...nft };
                  })
                }
                type="text"
                id="nft-form-name"
                defaultValue={nft.payload!.tour![key]!.name}
                name="name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="nft-form-location">{t("nft.location")}</label>
              <input
                disabled={isLoading}
                onChange={(e) =>
                  setNft((nft) => {
                    nft.payload!.tour![key]!.location!.label = e.target.value;
                    return { ...nft };
                  })
                }
                type="text"
                id="nft-form-location"
                defaultValue={nft.payload!.tour![key]!.location?.label}
                name="location"
              />
            </div>

            <div className="form-group">
              <label htmlFor="nft-form-date">{t("nft.date")}</label>
              <CustomDatePicker
                showTimeSelect
                defaultValue={nft.payload!.tour![key].date}
                onSelect={(e) => {
                  setNft((nft) => {
                    if (e) {
                      nft.payload!.tour![key].date = dateToGMT(
                        e,
                        collection.census?.location?.gmt || 0
                      ).toISOString();
                    }
                    return { ...nft };
                  });
                }}
                id="nft-form-date"
                name="date"
              />
            </div>
          </div>
        ))}

        <Button
          type="button"
          text="add step"
          onClick={() => {
            const nftCopy = structuredClone(nft);
            if (!nftCopy.payload?.tour) nftCopy.payload!.tour = [];
            nftCopy.payload?.tour.push({
              step: nftCopy.payload.tour.length + 1,
              name: "",
              location: { label: "" },
              date: "",
            });
            setNft(nftCopy);
          }}
        />

        <br />
        <br />
        <hr />
        <br />
        <br />

        {/* <div className="form-group">
          <label htmlFor="nft-form-hostName">{t("nft.hostName")}</label>
          <input
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.hostName = e.target.value;
                return { ...nft };
              })
            }
            type="text"
            id="nft-form-hostName"
            defaultValue={nft.payload?.hostName}
            name="hostName"
          />
        </div> */}

        {/* <div className="form-group">
          <label htmlFor="nft-form-hostDescription">
            {t("nft.hostDescription")}
          </label>
          <textarea
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.hostDescription = e.target.value;
                return { ...nft };
              })
            }
            cols={50}
            id="nft-form-hostDescription"
            defaultValue={nft.payload?.hostDescription}
            name="hostDescription"
          ></textarea>
        </div> */}

        <div className="form-group">
          <label htmlFor="nft-form-extra">{t("nft.extra")}</label>
          <textarea
            disabled={isLoading}
            onChange={(e) =>
              setNft((nft) => {
                nft.payload!.extra = e.target.value;
                return { ...nft };
              })
            }
            cols={50}
            id="nft-form-extra"
            defaultValue={nft.payload?.extra}
            name="extra"
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-owner">
            {t("nft.owner")} ({t("nft.required")})
          </label>
          <input
            disabled={isLoading}
            defaultValue={nft.owner}
            placeholder="jack.huston@gmail.com"
            type="email"
            onChange={(e) =>
              setNft((nft) => {
                nft.owner = e.target.value;
                return { ...nft };
              })
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="nft-form-lang">{t("nft.lang")}</label>
          <Select
            onChange={(e) => {
              setNft((nft) => {
                nft.lang = e.target.value;
                return { ...nft };
              });
            }}
            options={Object.keys(LANG).map((lang) => {
              return {
                text: lang,
                value: lang,
              };
            })}
          />
        </div>
      </div>

      <br />
      <br />
      <hr />
      <br />
      <br />
      <input
        disabled={isLoading}
        onChange={imagesUploadInput}
        type="file"
        id="form-images"
        accept="image/png, image/jpeg"
        multiple
        hidden
      />
      <Button
        metadata={`${nft.images!.length}/10`}
        text={String(t("collection.upload_images"))}
        onClick={() => {
          document.getElementById("form-images")?.click();
        }}
      />
      <div id="collection-images">
        <div className="img-preview-container mt-2">
          {nft.images!.map((i, key) => {
            return (
              <div
                key={"img_" + Math.random().toString()}
                className="img-preview"
                onClick={() => {
                  setNft((nft) => {
                    const selected = nft.images![key];
                    nft.images!.splice(key, 1);
                    nft.images! = [selected, ...nft.images!];
                    return { ...nft };
                  });
                }}
              >
                <input
                  disabled={isLoading}
                  readOnly
                  type="text"
                  hidden
                  value={i}
                  name="images[]"
                />
                <div
                  onClick={() => {
                    setNft((nft) => {
                      nft.images!.splice(key, 1);
                      return { ...nft };
                    });
                  }}
                  className="delete cursor-pointer"
                >
                  <span className="m-0">x</span>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${i})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                  className="image"
                ></div>
              </div>
            );
          })}
        </div>
      </div>

      <br />
      <br />
      <hr />
      <br />
      <br />

      <div className="buttons">
        <Button
          loading={isLoading}
          text={String(t("nft.save"))}
          onClick={() => saveNftClick()}
        />
      </div>
    </section>
  );

  return (
    <section className="p-3">
      <form>
        <div className="steps">{step === 0 ? step1 : null}</div>
      </form>
    </section>
  );
}
