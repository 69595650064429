import React from "react";
import { hotelCensus } from "../../services/collection";

interface DetailViewProps {
  data: hotelCensus;
}

/*
 * Component to display a key-value pair in a row
 */
const DetailView = ({ data }: DetailViewProps) => {
  console.log("data", data);

  return (
    <>
      <h3>Collection Census</h3>
      <div style={styles.container}>
        {Object.entries(data)
          .filter(([key, value], index) => typeof value === "string")
          .map(([key, value], index) => (
            <div style={styles.row} key={index}>
              <div style={styles.key}>{key}</div>
              <div style={styles.value}>{value}</div>
            </div>
          ))}
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  key: {
    fontWeight: "bold",
  },
  value: {
    // Add any additional styles for the value
  },
};

export default DetailView;
