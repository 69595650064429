import { FormEvent, useState } from "react";
import Button from "../dom/Button";
import Checkbox from "../dom/Checkbox";
import { formToObject } from "../../utils/generic";
import { patchCollection, refreshStoredCollection } from "../../services/collection";
import { useTranslation } from "react-i18next";

export default function CollectionSettings({
    collection
  }: any) {

    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const editCollectionFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        try {
          const data = formToObject(e.target);
    
          await patchCollection(data);
    
          await refreshStoredCollection(collection._id);
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
    };

    return (
        <>
          <form onSubmit={editCollectionFormSubmit}>
            <div className="d-flex gap-2">
              <Checkbox
                checked={collection.checkBeforeSale}
                name="checkBeforeSale"
              />
              {t("collection.check_sale")}
            </div>
            <div className="d-flex gap-2">
              <Checkbox checked={collection.isActive} name="isActive" />
              {t("collection.is_active")}
            </div>
            <input type="hidden" value={collection._id} name="collectionId" />
            <div>
              <Button
                type="submit"
                loading={isLoading}
                text={String(t("collection.save"))}
              />
            </div>
          </form>
        </>
    );
}