import axios from "axios";

export interface iAiConfig {
  prompt: string;
}

export async function runAiJob() {
  const response = await axios.post("/ai/run-ai-job");
  return response.data;
}

export async function getAiConfig() {
  const response = await axios.get("/ai");
  return response.data;
}

export async function askGpt(prompt: any) {
  const response = await axios.post("/ai/ask", { prompt });
  return response.data;
}

export async function editAiConfig(prompt: any) {
  const response = await axios.post("/ai", { prompt });
  return response.data;
}
