import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { closeModal, openModal } from "../../../components/modal/Modal";
import {
  createApiKey,
  deleteApiKey,
  getMyApiKeys,
  iApiKey,
} from "../../../services/apikey";
import {
  PAGE_DIM,
  datetimeToString,
  formToObject,
  scrollToTopList,
} from "../../../utils/generic";
import {
  editCollectionAlias,
  getCollectionAliases,
  deleteCollectionAlias,
  iCollectionAlias,
} from "../../../services/alias";
import {
  getCollections,
  iCollection,
  loadCollectionProps,
} from "../../../services/collection";
import {
  Webhook,
  iWebhookLog,
  editWebhook,
  getWebhook,
  getWebhookLogsPaginated,
  sendAgainWebhookLog,
  setWebhookLogAsSuccess,
} from "../../../services/webhook";
import Button from "../../../components/dom/Button";
import Loader from "../../../components/dom/Loader";
import Pagination from "../../../components/dom/Pagination";

import trashSvg from "../../../assets/icons/trash.svg";
import penSvg from "../../../assets/icons/pen.svg";
import cleftSvg from "../../../assets/icons/cleft.svg";
import crightSvg from "../../../assets/icons/cright.svg";
import Checkbox from "../../../components/dom/Checkbox";

interface LogRequestResponseProps {
  webhookLog: iWebhookLog;
}

export function LogRequestResponse({ webhookLog }: LogRequestResponseProps) {
  const { t } = useTranslation();
  const [mode, setMode] = useState(false);

  return (
    <section className="p-3">
      <div className="d-flex justify-content-between gap-2">
        <Button
          text={String(t("dev.webhook_mode_request"))}
          onClick={() => setMode(false)}
          small
          disabled={!mode}
          className="w100"
        />
        <Button
          text={String(t("dev.webhook_mode_response"))}
          onClick={() => setMode(true)}
          small
          disabled={mode}
          className="w100"
        />
      </div>

      <br />

      {!mode ? (
        <pre>{JSON.stringify(webhookLog.request, null, 2)}</pre>
      ) : (
        <pre>{JSON.stringify(webhookLog.responses, null, 2)}</pre>
      )}
    </section>
  );
}

interface WebhookLogsProps {
  onExit: Function;
}

export function WebhookLogs({ onExit }: WebhookLogsProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<iWebhookLog[]>([]);
  const [page, setPage] = useState(1);

  const loadLogs = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await getWebhookLogsPaginated(page);
      setLogs(data.data || []);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const setLogAsSuccessClick = async (logId: string) => {
    setIsLoading(true);
    try {
      await setWebhookLogAsSuccess(logId);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadLogs(page);
  };

  const resendLogClick = async (logId: string) => {
    setIsLoading(true);
    try {
      await sendAgainWebhookLog(logId);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadLogs(page);
  };

  useEffect(() => {
    loadLogs(page);
  }, [page]);

  return (
    <>
      <div>
        <Button onClick={() => onExit?.()} small text={String(t("dev.back"))} />
      </div>
      <br />

      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <table>
            <thead>
              <tr>
                <th>{t("dev.webhook_date")}</th>
                <th>{t("dev.webhook_attempts")}</th>
                <th>{t("dev.webhook_success")}</th>
                <th>{t("dev.webhook_event")}</th>
                <th>{t("dev.webhook_actions")}</th>
                <th>{t("dev.webhook_view")}</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, key) => {
                return (
                  <tr key={"log_" + key}>
                    <td>{datetimeToString(log.date, undefined, true, true)}</td>
                    <td>{log.attempts}</td>
                    <td>{log.success ? t("dev.yes") : t("dev.no")}</td>
                    <td>{log.request.event}</td>
                    <td>
                      <Button
                        onClick={() => resendLogClick(log._id)}
                        small
                        text={String(t("dev.webhook_again"))}
                        className="w100"
                      />
                      <Button
                        disabled={log.success}
                        onClick={() => setLogAsSuccessClick(log._id)}
                        small
                        text={String(t("dev.webhook_set_success"))}
                        className="w100"
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() =>
                          openModal(<LogRequestResponse webhookLog={log} />)
                        }
                        small
                        text={String(t("dev.view"))}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {logs.length === 0 ? <span>{t("dev.no_results")}</span> : null}

          <div className="d-flex justify-content-end">
            {logs.length >= PAGE_DIM || page > 1 ? (
              <div className="page">
                <img
                  onClick={() => {
                    if (page <= 1) return;
                    setPage(page - 1);
                  }}
                  src={cleftSvg}
                  alt="chevron icon"
                  className={`chevron ${page > 1 ? "" : "disabled"}`}
                />
                <span>
                  {t("nft.page")} {page}
                </span>
                <img
                  onClick={() => {
                    if (logs.length < PAGE_DIM) return;
                    setPage(page + 1);
                  }}
                  src={crightSvg}
                  alt="chevron icon"
                  className={`chevron ${
                    logs.length >= PAGE_DIM ? "" : "disabled"
                  }`}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

export default function DeveloperSettings() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCollection, setIsLoadingCollection] = useState(false);
  const [isLoadingAlias, setIsLoadingAlias] = useState(false);
  const [apiKeys, setApiKeys] = useState<Array<iApiKey>>([]);
  const [aliases, setAliases] = useState<Array<iCollectionAlias>>([]);
  const [collections, setCollections] = useState<Array<iCollection>>([]);
  const [collectionFilter, setCollectionFilter] = useState("");
  const [webhook, setWebhook] = useState<Webhook>();
  const [isWHButtonDisabled, setIsWHButtonDisabled] = useState(true);
  const [isWebhookLogs, setIsWebhookLogs] = useState(false);

  const elementRef = useRef<HTMLTableElement>(null);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(500);

  const loadApiKeys = async () => {
    setIsLoading(true);
    try {
      const data = await getMyApiKeys();
      setApiKeys(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadWebhook = async () => {
    setIsLoading(true);
    try {
      const data = await getWebhook();
      setWebhook(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadAliases = async () => {
    setIsLoadingAlias(true);
    try {
      const data = await getCollectionAliases();
      setAliases(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingAlias(false);
  };

  const loadCollections = async ({ page = 1, size }: loadCollectionProps) => {
    setIsLoadingCollection(true);
    try {
      const data = await getCollections({ page, size });

      setTotal(data.total || 0);

      setCollections(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingCollection(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  useEffect(() => {
    loadApiKeys();
    loadAliases();
    loadWebhook();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadCollections({ page: page, size: size });
  }, [page, size]);

  const webhookFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const payload = await formToObject(e.target);
      const webhook = await editWebhook(payload);
      setWebhook(webhook);
      setIsWHButtonDisabled(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const createApiKeySubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await createApiKey(data);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadApiKeys();
  };

  const createApiKeyModal = (
    <section id="apikey-create-modal">
      <form onSubmit={createApiKeySubmit}>
        <div className="input">
          <label htmlFor="apikey-form-name">{t("dev.apikey_name")}</label>
          <input type="text" required id="apikey-form-name" name="name" />
        </div>
        <div className="button">
          <Button type="submit" text={String(t("dev.create_apikey"))} />
        </div>
      </form>
    </section>
  );

  const deleteApiKeySubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      await deleteApiKey(data);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    await loadApiKeys();
  };

  const editCollectionAliasSubmit = async (
    e: FormEvent<HTMLFormElement>,
    aliasId?: string
  ) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = formToObject(e.target);
      closeModal();
      if (data.alias) await editCollectionAlias(data);
      else await deleteCollectionAlias({ aliasId });
      await loadAliases();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const editCollectionAliasModal = (
    collection: iCollection,
    alias?: iCollectionAlias
  ) => (
    <section id="collection-alias-modal">
      <span className="title">
        {t("dev.collection_alias_modal_text")} {collection.name}
      </span>
      <form onSubmit={(e) => editCollectionAliasSubmit(e, alias?._id)}>
        <input type="text" name="alias" defaultValue={alias?.alias} />
        <input
          type="text"
          required
          name="collectionId"
          hidden
          defaultValue={collection._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("dev.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            text={String(t("dev.save"))}
          />
        </div>
      </form>
    </section>
  );

  const deleteApiKeyConfirmModal = (apikey: iApiKey) => (
    <section id="delete-apikey-modal">
      <span className="title">
        {t("group.delete_group_confirm")} {apikey.name}
      </span>
      <form onSubmit={deleteApiKeySubmit}>
        <input
          type="text"
          required
          hidden
          name="apikeyId"
          defaultValue={apikey._id}
        />
        <div className="buttons">
          <Button
            onClick={() => closeModal()}
            className="cancel"
            light
            text={String(t("dev.cancel"))}
          />
          <Button
            type="submit"
            className="confirm"
            error
            text={String(t("dev.confirm_delete_button"))}
          />
        </div>
      </form>
    </section>
  );

  if (isWebhookLogs)
    return <WebhookLogs onExit={() => setIsWebhookLogs(false)} />;

  return (
    <section>
      <Button
        className="ml-auto"
        loading={isLoading}
        onClick={() => openModal(createApiKeyModal)}
        text={String(t("dev.create_apikey"))}
      />
      <span className="title">{t("dev.api_key_list")}</span>
      <div className="apikey-list">
        {apiKeys.map((apikey, key) => {
          return (
            <div className="apikey" key={key}>
              <img
                onClick={() => openModal(deleteApiKeyConfirmModal(apikey))}
                src={trashSvg}
                alt="trash icon"
                className="apikey-delete"
              />
              <span className="title">{apikey.name}</span>
              <span>{apikey.token}</span>
            </div>
          );
        })}
      </div>
      <div className="hr"></div>
      <span className="title">{t("dev.webhook")}</span>
      <form onSubmit={webhookFormSubmit}>
        <label htmlFor="">{t("dev.webhook_label")}</label>
        <input
          defaultValue={webhook?.url}
          disabled={isLoading}
          type="text"
          name="url"
          onChange={() => setIsWHButtonDisabled(false)}
        />
        <label htmlFor="">{t("dev.webhook_key")}</label>
        <input type="text" readOnly defaultValue={webhook?.key} />

        <div className="d-flex gap-2 align-items-center">
          <Checkbox
            error
            name="unsafe"
            checked={webhook?.unsafe}
            onChange={() => setIsWHButtonDisabled(false)}
          />
          <label htmlFor="">{t("dev.webhook_unsafe")}</label>
        </div>
        <br />
        <div>
          <Button
            disabled={isWHButtonDisabled}
            light={isWHButtonDisabled}
            loading={isLoading}
            text={String(t("dev.save"))}
            type="submit"
          />
        </div>
      </form>
      <br />
      <p>
        {t("dev.webhook_failed")}: {webhook?.amountFailed}
      </p>
      <div>
        <Button
          text={String(t("dev.view_logs"))}
          small
          onClick={() => setIsWebhookLogs(true)}
        />
      </div>
      <div className="hr"></div>
      {isLoadingAlias || isLoadingCollection ? (
        <Loader />
      ) : (
        <>
          {collections.length ? (
            <>
              <span className="title">{t("dev.collections")}</span>
              <p>{t("dev.collections_text")}</p>
              <input
                onChange={(e) => setCollectionFilter(String(e.target.value))}
                className="w100"
                type="text"
                placeholder="Search"
              />
              <table ref={elementRef} className="collection-dev-table mt-3">
                <thead>
                  <tr>
                    <th>{t("dev.collection_name")}</th>
                    <th>{t("dev.collection_id")}</th>
                    <th>{t("dev.collection_alias")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {collections
                    .filter((c) =>
                      c.name.toLowerCase().includes(collectionFilter)
                    )
                    .map((collection, key) => {
                      return (
                        <tr key={"collection_" + key}>
                          <td>{collection.name}</td>
                          <td>{collection._id}</td>
                          <td>
                            {
                              aliases.find(
                                (a) => a.collectionId === collection._id
                              )?.alias
                            }
                          </td>
                          <td>
                            <img
                              onClick={() => {
                                openModal(
                                  editCollectionAliasModal(
                                    collection,
                                    aliases.find(
                                      (a) => a.collectionId === collection._id
                                    )
                                  )
                                );
                              }}
                              className="icon"
                              src={penSvg}
                              alt="pen edit icon"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                page={page}
                size={size}
                total={total}
                onSizeChange={onSizeChange}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <p>{t("pagination.no_show")}</p>
          )}
        </>
      )}
    </section>
  );
}
