import { useTranslation } from "react-i18next";
import googleSvg from "../../assets/icons/google.svg";

import { useGoogleLogin } from "@react-oauth/google";
import { login } from "../../services/auth";

export default function GoogleAuth() {
  const { t } = useTranslation();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await login(
        {
          googleToken: tokenResponse.access_token,
          redirect: process.env.REACT_APP_DASHBOARD_URI,
        },
        true
      );
    },
  });

  return (
    <section className="google-auth">
      <div onClick={() => googleLogin()} className="auth-content">
        <img src={googleSvg} alt="google icon" className="icon" />
        <span className="text">{t("auth.login_google")}</span>
      </div>
    </section>
  );
}
