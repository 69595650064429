import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { GROUP_PERMISSIONS, iGroup } from "../../../services/groups";
import { selectGroup } from "../../../store/groupSlice";
import { selectUser } from "../../../store/userSlice";
import { PAGE_DIM, scrollToTopList } from "../../../utils/generic";
import Loader from "../../../components/dom/Loader";
import Pagination from "../../../components/dom/Pagination";
import Button from "../../../components/dom/Button";
import {
  createDropship,
  getDropships,
  iDropship,
} from "../../../services/dropship";
import { closeModal, openModal } from "../../../components/modal/Modal";
import { useNavigate } from "react-router-dom";

interface CreateDropshipModalProps {
  group: iGroup;
  onSuccess: Function;
}

function CreateDropshipModal({ group, onSuccess }: CreateDropshipModalProps) {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const create = async () => {
    setIsLoading(true);
    try {
      await createDropship({ name, groupId: group._id });

      onSuccess();
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <form action="">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          onClick={create}
          disabled={!name}
          loading={isLoading}
          text={String("dropship.create")}
        />
      </form>
    </div>
  );
}

export default function GroupDropship() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const group = useSelector(selectGroup);

  const [dropships, setDropships] = useState<iDropship[]>([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const elementRef = useRef<HTMLDivElement>(null);

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  const loadDropships = async () => {
    setIsLoading(true);
    try {
      const data = await getDropships({ page, size, groupId: group._id });

      setDropships(data.data);
      setTotal(Number(data.total));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadDropships();
    // eslint-disable-next-line
  }, [page, size, group]);

  const canCreate = (group: iGroup): boolean => {
    if (user.isAdmin) return true;

    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (
      myPerms.includes(GROUP_PERMISSIONS.admin) ||
      myPerms.includes(GROUP_PERMISSIONS.dropship)
    )
      return true;

    return false;
  };

  return (
    <section className="group-sub-page">
      <div className="group-collection-header">
        {/* <span className="title">{t("group.collection")}</span> */}
        {canCreate(group) ? (
          <Button
            onClick={() =>
              openModal(
                <CreateDropshipModal onSuccess={loadDropships} group={group} />
              )
            }
            text={String(t("group.create_dropship"))}
          />
        ) : null}
      </div>

      <br />

      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dropships.map((dropship, key) => {
              return (
                <tr key={"dropship_" + key}>
                  <td>{dropship.name}</td>
                  <td>
                    <Button
                      onClick={() => navigate(`/dropship/${dropship._id}`)}
                      small
                      text={String(t("dropship.view"))}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <Pagination
        page={page}
        size={size}
        total={total}
        onSizeChange={onSizeChange}
        onPageChange={onPageChange}
      />
    </section>
  );
}
